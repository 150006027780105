export enum University {
  Enap = 'enap',
  UdeM = 'udem',
  Hec = 'hec',
  UdeS = 'udes',
  Uqac = 'uqac',
  Uqam = 'uqam',
  Uqar = 'uqar',
  Uqtr = 'uqtr',
  Uqat = 'uqat',
  Uqo = 'uqo',
  Ul = 'ul',
  McGill = 'mcgill',
  Other = 'other',
}

export enum UniversitySemester {
  Summer = 'summer',
  Fall = 'fall',
  Winter = 'winter',
}
