import './PeriodAmountsForm.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Form,
  FormActionButton,
  FormActionButtonType,
  FormAmountField,
  FormContext,
} from '@novo-electronique/react-forms';
import * as Yup from 'yup';

import { amountPerCredit } from '@common/constants/global';
import { IPeriodAmount } from '@common/models/period-amount';
import { formatPeriod, getPeriodType } from '@common/utils/submission-period';
import { selectCurrentPeriod } from '@modules/app/redux/selectors';

interface IProps {
  periodAmounts: IPeriodAmount[];
  onSubmit: (periodAmount: IPeriodAmount[]) => Promise<void>;
}

function PeriodAmountsForm({ periodAmounts, onSubmit }: IProps) {
  const { t } = useTranslation();
  const currentPeriod = useSelector(selectCurrentPeriod);

  const periodAmountMap = periodAmounts.reduce<{ [periodId: string]: number }>(
    (current, { periodId, amount }) => ({ ...current, [periodId]: amount }),
    {},
  );

  const periodAmountMapSchema = periodAmounts.reduce<{ [periodId: string]: Yup.NumberSchema<number> }>(
    (current, { periodId }) => ({ ...current, [periodId]: Yup.number().min(0).max(amountPerCredit) }),
    {},
  );

  const validationSchema = Yup.object().shape(periodAmountMapSchema);

  const onSubmitHandler = (data: typeof periodAmountMap): Promise<void> => {
    const formattedData = Object.entries(data).map(([periodId, amount]) => ({ periodId, amount }));
    return onSubmit(formattedData);
  };

  return (
    <FormContext
      initialValues={periodAmountMap}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
      enableReinitialize
    >
      <Form>
        <table className="cpdPeriodAmountsTable">
          <thead className="cpdPeriodAmountsTable__head">
            <tr>
              <th className="cpdPeriodAmountsTable__head__cell">{t('periodAmountAdminPage.period')}</th>
              <th className="cpdPeriodAmountsTable__head__cell">
                {t('periodAmountAdminPage.amountPerCredit')}
              </th>
              <th className="cpdPeriodAmountsTable__head__cell"></th>
            </tr>
          </thead>
          <tbody className="cpdPeriodAmountsTable__body">
            {periodAmounts.map(({ periodId }) => (
              <tr key={periodId} className="cpdPeriodAmountsTableRow">
                <td className="cpdPeriodAmountsTableRow__cell">{formatPeriod(periodId)}</td>
                <td className="cpdPeriodAmountsTableRow__cell">
                  <FormAmountField min={0} max={amountPerCredit} name={periodId} />
                </td>
                <td className="cpdPeriodAmountsTableRow__cell">
                  <span className={`cpdTag cpdTag--period--${getPeriodType(periodId, currentPeriod)}`}>
                    {t('periodAmountAdminPage.periodType', {
                      context: getPeriodType(periodId, currentPeriod),
                    })}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="cpdPeriodAmountsButtons">
          <FormActionButton type={FormActionButtonType.Submit} small>
            {t('general.forms.save')}
          </FormActionButton>
          <FormActionButton type={FormActionButtonType.Reset} small>
            {t('general.forms.cancel')}
          </FormActionButton>
        </div>
      </Form>
    </FormContext>
  );
}

export default PeriodAmountsForm;
