import React from 'react';

// TODO: Add associations routes when components will be implemented.
/*import { AdminRoutePage } from '@constants/routes';
import AssociationsAdminPage from '@modules/associations/AssociationsAdminPage';
import LocalSectionsAdminPage from '@modules/associations/LocalSectionsAdminPage';
import ServiceCentersAdminPage from '@modules/associations/ServiceCentersAdminPage';
import { RouteConfig, RouteType } from '@modules/shared/route';

export default [
  { path: AdminRoutePage.Associations, type: RouteType.Protected, component: <AssociationsAdminPage /> },
  { path: AdminRoutePage.LocalSections, type: RouteType.Protected, component: <LocalSectionsAdminPage /> },
  { path: AdminRoutePage.ServiceCenters, type: RouteType.Protected, component: <ServiceCentersAdminPage /> },
] as RouteConfig[];*/

export default [];
