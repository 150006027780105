import './FormPrint.scss';

import React, { ReactNode, ReactNodeArray } from 'react';

import DocumentTitle from '@modules/shared/components/DocumentTitle';

interface IProps {
  title?: string;
  children: ReactNode | ReactNodeArray;
}

function FormPrint({ title, children }: IProps) {
  return (
    <div className="cpdFormPrint">
      {title && <DocumentTitle>{` – ${title}`}</DocumentTitle>}
      {children}
    </div>
  );
}

export default FormPrint;
