import firebase from 'firebase';
import { i18n } from 'i18next';

import { configureFirebase } from '@config/firebase';
import { configureI18n } from '@config/i18n';
import { configureYup } from '@config/yup';
import initialRootState from '@src/redux/root.state';
import { configureStore } from '@src/redux/store';

interface AppContext {
  store: any;
  i18n: i18n;
  firebase: firebase.app.App;
}

export default function boostrap(): AppContext {
  const store = configureStore(initialRootState);
  const i18n = configureI18n();
  const firebase = configureFirebase();
  configureYup();

  return {
    store,
    i18n,
    firebase,
  };
}
