import React from 'react';
import { renderToString } from 'react-dom/server';
import { ColDef, ValueFormatterParams } from '@ag-grid-community/core';
import classNames from 'classnames';
import i18n from 'i18next';

import { DataType } from '@common/constants/data-type';
import { Mask } from '@common/constants/mask';
import { formatCurrency } from '@common/utils/currency';
import { formatDate } from '@common/utils/date';
import { formatPhoneNumber } from '@common/utils/formatter';
import { formatPeriod, getPeriods } from '@common/utils/submission-period';
import IValueProvider, {
  createEnumValueProvider,
  enumAllowedTypes,
  enumDataTypes,
  submissionStatusValueProvider,
  submissionTypeValueProvider,
} from '@common/value-provider';
import SubmissionStatusTag from '@modules/submissions/components/SubmissionStatusTag';

const emptyCharacter = '—';
const booleanValueFormatter = (params: ValueFormatterParams) => {
  return params.value === '1' || params.value?.toString() === 'true'
    ? i18n.t('general.boolean')
    : i18n.t('general.boolean', { context: 'false' });
};
const lockedValueFormatter = (params: ValueFormatterParams) => {
  return params.value === '1' || params.value?.toString() === 'true'
    ? i18n.t('submissionAdminPage.lockedState')
    : i18n.t('submissionAdminPage.lockedState', { context: 'unlocked' });
};
const enumValueComparator = (valueProvider: IValueProvider) => (a, b) =>
  valueProvider.label(a).localeCompare(valueProvider.label(b));

const enumColumns = enumAllowedTypes.reduce((obj, type) => {
  const valueProvider = createEnumValueProvider(type);

  obj[type] = {
    filter: 'agSetColumnFilter',
    valueFormatter: ({ value }: ValueFormatterParams) => valueProvider.label(value) || emptyCharacter,
    filterParams: {
      values: valueProvider.values(),
      valueFormatter: ({ value }: ValueFormatterParams) => valueProvider.label(value),
      comparator: enumValueComparator(valueProvider),
    },
  };
  return obj;
}, {} as { [key in enumDataTypes]: ColDef });

export const columnTypes: { [key in DataType]: ColDef } = {
  ...enumColumns,

  [DataType.Text]: {
    filter: 'agTextColumnFilter',
    valueFormatter: ({ value }: ValueFormatterParams) => value || emptyCharacter,
  },
  [DataType.Number]: {
    filter: 'agNumberColumnFilter',
    valueFormatter: ({ value }: ValueFormatterParams) =>
      value !== null && isFinite(value) ? value : emptyCharacter,
  },
  [DataType.Boolean]: {
    filter: 'agSetColumnFilter',
    cellRenderer: (params: any) => {
      if (params.value === undefined) {
        return '';
      }

      const className = classNames('cpdTag', {
        'cpdTag--dark': params.value,
        'cpdTag--darker': !params.value,
      });
      return `<span class="${className}">${i18n.t(params.key || 'general.boolean', {
        context: !params.value && 'false',
      })}</span>`;
    },

    valueFormatter: booleanValueFormatter,
    filterParams: {
      valueFormatter: booleanValueFormatter,
      values: [0, 1],
    },
  },
  [DataType.LockedState]: {
    filter: 'agSetColumnFilter',
    cellStyle: { textAlign: 'center' },
    cellRenderer: (params: any) => {
      const className = classNames('material-icons cpdIcon--lock cpdIcon', {
        'cpdIcon--locked': params.value,
        'cpdIcon--unlocked': !params.value,
      });

      return `<span class="${className}"></span>`;
    },
    valueFormatter: lockedValueFormatter,
    filterParams: {
      valueFormatter: lockedValueFormatter,
      values: [0, 1],
    },
  },
  [DataType.SubmissionType]: {
    filter: 'agSetColumnFilter',
    valueFormatter: ({ value }: ValueFormatterParams) => value.toUpperCase() || emptyCharacter,
    filterParams: {
      values: submissionTypeValueProvider.values(),
    },
  },
  [DataType.SubmissionPeriod]: {
    filter: 'agSetColumnFilter',
    valueFormatter: ({ value }: ValueFormatterParams) => (value && formatPeriod(value)) || emptyCharacter,
    filterParams: {
      values: getPeriods().map((period) => period.id),
      valueFormatter: ({ value }: ValueFormatterParams) => formatPeriod(value),
    },
  },
  [DataType.SubmissionStatus]: {
    filter: 'agSetColumnFilter',
    valueFormatter: ({ value }: ValueFormatterParams) =>
      submissionStatusValueProvider.label(value) || emptyCharacter,
    filterParams: {
      values: submissionStatusValueProvider.values(),
      valueFormatter: ({ value }: ValueFormatterParams) => submissionStatusValueProvider.label(value),
      comparator: enumValueComparator(submissionStatusValueProvider),
    },
    cellRenderer: ({ value }: ValueFormatterParams) => {
      if (!value) {
        return emptyCharacter;
      }
      return renderToString(<SubmissionStatusTag fullWidth>{value}</SubmissionStatusTag>);
    },
  },

  [DataType.Amount]: {
    filter: 'agNumberColumnFilter',
    cellStyle: { textAlign: 'right' },
    valueFormatter: ({ value }: ValueFormatterParams) => {
      if (value === null || value === undefined) {
        return emptyCharacter;
      }
      return formatCurrency(value);
    },
  },
  [DataType.PhoneNumber]: {
    filter: 'agTextColumnFilter',
    valueFormatter: ({ value }: ValueFormatterParams) => {
      if (!value) {
        return emptyCharacter;
      }
      return formatPhoneNumber(value.toString());
    },

    floatingFilterComponent: 'maskFloatingFilter',
    floatingFilterComponentParams: {
      mask: Mask.PhoneNumber,
      normalize: (d) => d.replace(/\D/gm, ''),
    },
  },
  [DataType.Date]: {
    filter: 'agDateColumnFilter',
    valueFormatter: ({ value, colDef }: ValueFormatterParams) => {
      if (!value) {
        return emptyCharacter;
      }
      return formatDate(value, colDef.cellRendererParams?.format);
    },
  },

  [DataType.ServiceCenter]: {
    filter: 'agSetColumnFilter',
    valueFormatter: (params: ValueFormatterParams) => {
      if (!params || !params.value) {
        return emptyCharacter;
      }
      return params.context.serviceCenterValueProvider.label(params.value);
    },
    filterParams: {
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params || !params.value) return;
        return params.context.serviceCenterValueProvider.label(params.value);
      },
    },
  },

  [DataType.LocalSection]: {
    filter: 'agSetColumnFilter',
    valueFormatter: (params: ValueFormatterParams) => {
      if (!params || !params.value) {
        return emptyCharacter;
      }
      return params.context.localSectionValueProvider.label(params.value);
    },
    filterParams: {
      comparator: (a, b) => a.localeCompare(b),
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params || !params.value) return;
        return params.context.localSectionValueProvider.label(params.value);
      },
    },
  },

  [DataType.Association]: {
    filter: 'agSetColumnFilter',
    valueFormatter: (params: ValueFormatterParams) => {
      if (!params || !params.value) {
        return emptyCharacter;
      }
      return params.context.associationValueProvider.label(params.value).split(' ')[0];
    },
    filterParams: {
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params || !params.value) return;
        return params.context.associationValueProvider.label(params.value);
      },
    },
  },
};
