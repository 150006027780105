export enum Province {
  Bc = 'bc',
  Ab = 'ab',
  Sk = 'sk',
  Mb = 'mb',
  On = 'on',
  Qc = 'qc',
  Ns = 'ns',
  Nb = 'nb',
  Pe = 'pe',
  Nl = 'nl',
  Nt = 'nt',
  Nu = 'nu',
  Yt = 'yt',
}
