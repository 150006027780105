import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DataTable, useDataTable } from '@novo-electronique/react-data-table';

import { SubmissionStatus } from '@common/constants/submission-status';
import { ISubmission } from '@common/models/submission';
import { selectCache, selectCurrentPeriod } from '@modules/app/redux/selectors';
import { submissionColumnDefinition } from '@modules/data-table/column-definitions';
import { IDataTableCustomContext } from '@modules/data-table/data-table-custom-context';
import { DataTableSource } from '@modules/data-table/data-table-source';
import GridToolbar, { GridToolbarActionType } from '@modules/shared/components/GridToolbar';
import Initializing from '@modules/shared/components/Initializing';
import { AdminLayout } from '@modules/shared/components/Layout';
import SubmissionEditionPanel from './components/SubmissionEditionPanel';
import SubmissionFormModal from './components/SubmissionFormModal';
import { createSubmission, updateSubmission } from './service';

const initializationKeyName = '__cpdAdminSubmissionsInitialized';

function SubmissionsAdminPage() {
  const { t } = useTranslation();
  const cache = useSelector(selectCache);
  const period = useSelector(selectCurrentPeriod);

  const [submissionToEdit, setSubmissionToEdit] = useState(undefined);
  const [isNewSubmissionModalOpen, setIsNewSubmissionModalOpen] = useState(false);

  const context: IDataTableCustomContext = {
    associationValueProvider: cache.association.valueProvider,
    localSectionValueProvider: cache.localSection.valueProvider,
    serviceCenterValueProvider: cache.serviceCenter.valueProvider,
  };

  const { isReady, gridApi, gridProperties, exportToCsv, exportToXlsx, refresh, resetFilters } = useDataTable<
    ISubmission,
    IDataTableCustomContext
  >(
    DataTableSource.Submission,
    submissionColumnDefinition(cache),
    (data: ISubmission) => setSubmissionToEdit(data),
    {
      context,
    },
  );

  useEffect(() => {
    if (isReady && !localStorage.getItem(initializationKeyName)) {
      applyPeriodFilter();
      applyStatusFilter();
      localStorage.setItem(initializationKeyName, true.toString());
    }
  }, [isReady]);

  const applyPeriodFilter = () => {
    const filter = gridApi.getFilterInstance('periodId');

    filter.setModel({ values: [period.id] });
    gridApi.onFilterChanged();
  };

  const applyStatusFilter = () => {
    const filter = gridApi.getFilterInstance('status');
    const values = Object.values(SubmissionStatus).filter(
      (status) => status !== SubmissionStatus.SubmissionCancelled,
    );

    filter.setModel({ values });
    gridApi.onFilterChanged();
  };

  const refreshGrid = () => refresh({ purge: true });

  const onNewSubmissionSubmit = (newSubmission: ISubmission): Promise<void> => {
    return createSubmission(newSubmission).then(() => {
      setIsNewSubmissionModalOpen(false);
      refreshGrid();
    });
  };

  const onSubmit = (submission: ISubmission, shouldNotifyMember: boolean) => {
    return updateSubmission(submission, shouldNotifyMember)
      .then(() => {
        setSubmissionToEdit(null);
        refreshGrid();
      })
      .catch((e) => {
        console.error(e);
        toast.error(t('general.error'));
      });
  };

  const onCancel = () => {
    setSubmissionToEdit(null);
  };

  return (
    <AdminLayout>
      <header className="cpdAdminLayout__content__header">
        <h1 className="cpdAdminLayout__content__header__title">{t('submissionAdminPage.title')}</h1>
        <GridToolbar
          actions={{
            [GridToolbarActionType.Add]: {
              label: t('submissionAdminPage.newSubmission'),
              action: () => setIsNewSubmissionModalOpen(true),
            },
            [GridToolbarActionType.Export]: [
              { action: exportToCsv, label: 'CSV' },
              { action: exportToXlsx, label: 'XLSX' },
            ],
            [GridToolbarActionType.FilterOff]: () => {
              resetFilters();
              applyPeriodFilter();
              applyStatusFilter();
            },
            [GridToolbarActionType.Refresh]: refreshGrid,
          }}
        />
      </header>

      <div className="cpdAdminLayout__content__grid">
        {!cache.isLoaded && <Initializing />}
        {cache.isLoaded && <DataTable {...gridProperties} />}
        {cache.isLoaded && submissionToEdit && (
          <SubmissionEditionPanel
            submission={submissionToEdit}
            submitHandler={onSubmit}
            cancelHandler={onCancel}
          />
        )}
      </div>

      {isNewSubmissionModalOpen && (
        <SubmissionFormModal
          onSubmit={onNewSubmissionSubmit}
          onClose={() => setIsNewSubmissionModalOpen(false)}
        />
      )}
    </AdminLayout>
  );
}

export default SubmissionsAdminPage;
