import './GridToolbar.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonSize, ButtonStyle } from '@novo-electronique/react-button';

import DropdownButton from '../DropdownButton';

export enum GridToolbarActionType {
  Add = 'add',
  Export = 'export',
  FilterOff = 'filterOff',
  Refresh = 'refresh',
}

interface IToolBarAction {
  action: () => void;
  label: string;
}

interface IProps {
  actions?: Partial<{ [type in GridToolbarActionType]: (() => void) | IToolBarAction | IToolBarAction[] }>;
}

function GridToolbar({ actions = {} }: IProps) {
  const { t } = useTranslation();

  return (
    <div className="cpdGridToolbar" role="toolbar">
      {Object.keys(actions).map((type) => {
        const toolbarAction: (() => void) | IToolBarAction | IToolBarAction[] = actions[type];

        if (Array.isArray(toolbarAction)) {
          return (
            <DropdownButton
              key={type}
              size={ButtonSize.Small}
              style={ButtonStyle.Action}
              options={toolbarAction}
            >
              <span className={`material-icons cpdIcon cpdIcon--${type}`} />
              {t(`general.admin.${type}`)}
            </DropdownButton>
          );
        }
        return (
          <Button
            key={type}
            size={ButtonSize.Small}
            style={ButtonStyle.Action}
            onClick={typeof toolbarAction === 'object' ? toolbarAction.action : toolbarAction}
          >
            <span className={`material-icons cpdIcon cpdIcon--${type}`} />
            {typeof toolbarAction === 'object' ? toolbarAction.label : t(`general.admin.${type}`)}
          </Button>
        );
      })}
    </div>
  );
}

export default GridToolbar;
