import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DisplayStyles,
  FormAmountField,
  FormCheckboxField,
  FormDataContext,
  FormDateField,
  FormFields,
  FormFieldSpacer,
  FormFileField,
  FormNumberField,
  FormOptionsField,
  FormTextAreaField,
  FormTextField,
} from '@novo-electronique/react-forms';

import { ActivityType } from '@common/constants/activity-type';
import { acceptedFormats } from '@common/constants/global';
import { SubmissionType } from '@common/constants/submission-type';
import { IActivitySubmission } from '@common/models/submission';
import { activityTypeValueProvider } from '@common/value-provider';
import { removeFile, uploadFile } from '@modules/app/service';
import { valueProviderToOptions } from '@modules/shared/components/Form';
import FilesListField from '@modules/shared/components/Form/FilesListField';
import ActivityTableFees from './ActivityTableFees/ActivityTableFees';

interface IProps {
  submissionType: SubmissionType.Pca | SubmissionType.Pp;
  editMode?: boolean;
}

function ActivityClaimSection({ submissionType, editMode = false }: IProps) {
  const { t } = useTranslation();
  const {
    version,
    claim: { hasChanges, activityType, participantsCount },
  } = useContext(FormDataContext).values as IActivitySubmission;

  const options = [true, false].map((value) => ({
    label: t('general.boolean', { context: value.toString() }),
    value: value.toString(),
  }));

  return (
    <>
      <FormFields title={t('submissionPage.projectGeneralInfo')}>
        <FormDateField name="claim.completionDate" label={t('submission.completionDate')} />
        <FormFieldSpacer width={50} />
        <FormOptionsField
          name="claim.activityType"
          label={t('submission.type')}
          options={valueProviderToOptions(activityTypeValueProvider)}
          type="radio"
          displayStyle={DisplayStyles.Inline}
          width={50}
        />
        {activityType === ActivityType.Presential && (
          <FormTextField
            name="claim.activityLocation"
            label={t('submission.activityLocation', { context: submissionType })}
          />
        )}
        {activityType !== ActivityType.Presential && <FormFieldSpacer width={50} />}
        <FormNumberField
          name="claim.participantsCount"
          label={t('claim.participantsCount', { version })}
          min={version > 1 && submissionType === SubmissionType.Pca ? 2 : 1}
          tooltip={!editMode && t('submission.participantsCountTooltip')}
        />
        {version === 1 && (
          <FormTextAreaField
            width={100}
            name="claim.participantsList"
            label={t('submission.participantsList')}
            description={t('submission.participantsListPrecision')}
          />
        )}
        {version > 1 && participantsCount > 1 && (
          <div className="cpdHiddenForPrint">
            {!editMode && (
              <FormFileField
                name="claim.participantsListFile"
                label={t('submission.participantsList')}
                tooltip={t('submission.participantsListTooltip')}
                upload={uploadFile}
                remove={removeFile}
                accept={acceptedFormats}
                maxFiles={1}
                width={100}
              />
            )}
            {editMode && (
              <FilesListField
                name="claim.participantsListFile"
                label={t('submission.participantsList')}
                width={100}
              />
            )}
          </div>
        )}
        <FormOptionsField
          name="claim.hasChanges"
          label={t('claim.hasChanges')}
          options={options}
          type="radio"
          displayStyle={DisplayStyles.Inline}
          width={100}
        />
        {hasChanges?.toString() === true.toString() && (
          <FormTextAreaField
            width={100}
            name="claim.changesDescription"
            label={t('claim.changesDescription')}
          />
        )}
      </FormFields>
      {version > 1 && !editMode && (
        <FormFields title={t('claim.amountApproved')}>
          <div className="cpdFormSingleField">
            <FormAmountField
              name="amountRequested"
              label={t('claim.amountApproved', { context: 'long' })}
              tooltip={t('submission.amountRequestedTooltip', { version })}
              disabled
            />
          </div>
        </FormFields>
      )}
      <FormFields title={t('claim.actualEligibleCost')} required>
        <p className="novoFields__text">{t('claim.ackParticipationDescription')}</p>
        <ActivityTableFees />
        {!editMode && (
          <FormCheckboxField name="claim.ackParticipation" label={t('claim.ackParticipation')} width={100} />
        )}
      </FormFields>
    </>
  );
}

export default ActivityClaimSection;
