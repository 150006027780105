import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DisplayStyles,
  FieldEvent,
  FormDataContext,
  FormFields,
  FormFieldSpacer,
  FormNumberField,
  FormOptionsField,
  FormSelectField,
  FormSelectFieldOptions,
  FormTextField,
} from '@novo-electronique/react-forms';

import { amountPerCredit } from '@common/constants/global';
import { SubmissionType } from '@common/constants/submission-type';
import { ICoursesSubmission } from '@common/models/submission';
import { computeAmountPerCredit } from '@common/utils/credits';
import { formatCurrency } from '@common/utils/currency';
import {
  programStatusValueProvider,
  universitySemesterValueProvider,
  universityValueProvider,
} from '@common/value-provider';
import { valueProviderToOptions } from '@modules/shared/components/Form';

interface IProps {
  submissionType: SubmissionType.If | SubmissionType.Cu;
  editMode?: boolean;
}

function CoursesClaimSection({ submissionType, editMode = false }: IProps) {
  const { t } = useTranslation();
  const { creditsCount } = useContext(FormDataContext).values as ICoursesSubmission;

  const onCreditsCountChange: FieldEvent<number> = (value, setFieldValue) =>
    setFieldValue('claim.amountRequested', computeAmountPerCredit(submissionType, value));

  return (
    <React.Fragment>
      <FormFields title={t('submissionPage.projectGeneralInfo')}>
        <FormOptionsField
          name="claim.semesters"
          label={t('submission.semesters')}
          options={valueProviderToOptions(universitySemesterValueProvider)}
          type="checkbox"
          displayStyle={DisplayStyles.Inline}
        />
        <FormFieldSpacer width={50} />
        <FormSelectField name="claim.university" label={t('submission.universityName')}>
          <FormSelectFieldOptions
            options={valueProviderToOptions(universityValueProvider)}
            keepOriginalOrder
          />
        </FormSelectField>
        <FormTextField
          name="claim.programTitle"
          label={t('submission.programTitle', { context: submissionType })}
        />

        <FormNumberField
          name="claim.creditsCount"
          label={t('claim.creditsCount')}
          description={!editMode && t('submission.creditsCountPrecision')}
          onChange={onCreditsCountChange}
          min={1}
          max={creditsCount}
          width={50}
        />
        <FormTextField
          disabled={true}
          name="claim.amountRequested"
          label={t('submission.amountRequested', { context: submissionType, amount: amountPerCredit })}
          description={!editMode && t('submission.amountRequestedPrecision')}
          formatter={(value) => (isFinite(+value) ? formatCurrency(+value) : '')}
          width={50}
        />
      </FormFields>
      <FormFields title={t('claim.programStatus')} required>
        <div className="cpdFormSingleField">
          <FormOptionsField
            name="claim.programStatus"
            options={valueProviderToOptions(programStatusValueProvider)}
            type="radio"
            displayStyle={DisplayStyles.Inline}
            width={100}
          />
        </div>
      </FormFields>
    </React.Fragment>
  );
}

export default CoursesClaimSection;
