export function flat<T>(arrays: T[][]): T[] {
  return [].concat(...arrays);
}

export function chunk<T>(arrays: T[], chunkSize: number): T[][] {
  if (chunkSize <= 0) {
    throw new RangeError('Invalid chunk size specified');
  }
  return Array(Math.ceil(arrays.length / chunkSize))
    .fill(undefined)
    .map((_, i) => arrays.slice(i * chunkSize, i * chunkSize + chunkSize));
}

export function merge<T extends { id?: string }>(list1: T[], list2: T[]): T[] {
  const map = {};
  [...list1, ...list2].forEach((element) => {
    if (!map[element.id]) {
      map[element.id] = element;
    }
  });
  return Object.values(map);
}

export function range(length: number): number[] {
  return Array.from({ length }, (x, i) => i);
}
