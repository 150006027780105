import './Panel.scss';

import React, { ReactNode, ReactNodeArray } from 'react';

interface IProps {
  title: string;
  children: ReactNode | ReactNodeArray;
}

function Panel({ title, children }: IProps) {
  return (
    <div className="cpdPanel">
      <h2 className="cpdPanel__title">{title}</h2>
      <div className="cpdPanel__content">{children}</div>
    </div>
  );
}

export default Panel;
