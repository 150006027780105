import { ActivityDuration } from '@common/constants/activity-duration';
import { Month } from '../constants/month';
import { SubmissionStatus } from '../constants/submission-status';
import { SubmissionType } from '../constants/submission-type';
import { PeriodDate } from '../models/submission-period';
import { PeriodYear } from './period-year';

export const timeZone = 'America/New_York';

export const acceptedFormats = ['.pdf', '.docx', '.pptx', '.xlsx', '.csv', '.txt', '.jpg', '.png'];
export const acceptedChequeFormats = ['.pdf', '.jpg', '.png'];

export const allowedTypesForModifications = [SubmissionType.Pca, SubmissionType.Pp];
export const submissionAllowedStatuses = [
  SubmissionStatus.NewSubmission,
  SubmissionStatus.SubmissionWaitingForDocuments,
];
export const claimAllowedStatuses = [
  SubmissionStatus.SubmissionAccepted,
  SubmissionStatus.ClaimMade,
  SubmissionStatus.ClaimWaitingForDocuments,
];

export const firstSubmissionYear = 2021;
export const firstYearVersion2 = 2024;

export const submissionStartPeriodDate: PeriodDate = { day: 1, month: Month.July, year: PeriodYear.Year1 };

export const maxFilesCountToUpload = 10;

// CU / IF
export const amountPerCredit = 80;
export const maxIfCreditsAllowed = 30;
export const maxIfCreditsAllowedPerYear = 30;
export const maxCuCreditsAllowed = 90;
export const maxCuCreditsAllowedPerYear = 38;

// PCA / PP
export const activityAllowedAmount: {
  [SubmissionType.Pp]: Partial<{ [duration in ActivityDuration]: number }>;
  [SubmissionType.Pca]: Partial<{ [duration in ActivityDuration]: number }>;
} = {
  [SubmissionType.Pp]: {
    [ActivityDuration.ThreeHoursOrLess]: 50,
    [ActivityDuration.MoreThanThreeOrLessThanSixHours]: 100,
  },
  [SubmissionType.Pca]: {
    [ActivityDuration.ThreeHoursOrLess]: 55,
    [ActivityDuration.MoreThanThreeOrLessThanSixHours]: 95,
    [ActivityDuration.MoreThan1Day]: 105,
  },
};
export const encountersAllowedNumberMap: Partial<{ [duration in ActivityDuration]: number }> = {
  [ActivityDuration.ThreeHoursOrLess]: 12,
  [ActivityDuration.MoreThanThreeOrLessThanSixHours]: 6,
};

export const submissionActivityDates: [PeriodDate, PeriodDate] = [
  { day: 15, month: Month.August, year: PeriodYear.Year1 },
  { day: 31, month: Month.October, year: PeriodYear.Year1 },
];
export const ifActivityExtensionDates: [PeriodDate, PeriodDate] = [
  { day: 1, month: Month.November, year: PeriodYear.Year1 },
  { day: 15, month: Month.February, year: PeriodYear.Year2 },
];
export const claimActivityDates: [PeriodDate, PeriodDate] = [
  { day: 1, month: Month.November, year: PeriodYear.Year1 },
  { day: 30, month: Month.June, year: PeriodYear.Year2 },
];
export const claimDocumentsExtensionActivityDates: [PeriodDate, PeriodDate] = [
  { day: 1, month: Month.July, year: PeriodYear.Year2 },
  { day: 31, month: Month.August, year: PeriodYear.Year2 },
];
export const submissionDocumentsExtensionActivityDates: [PeriodDate, PeriodDate] = [
  { day: 1, month: Month.November, year: PeriodYear.Year1 },
  { day: 15, month: Month.February, year: PeriodYear.Year2 },
];
export const newPrincipalSubmissionDate: [PeriodDate, PeriodDate] = [
  { day: 15, month: Month.September, year: PeriodYear.Year1 },
  { day: 15, month: Month.February, year: PeriodYear.Year2 },
];
