import './AdminHeader.scss';

import React from 'react';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Logo } from '@assets/horizontal-logo.svg';
import { AdminRouteSection } from '@constants/routes';
import AdminMenu from '@modules/app/components/AdminMenu';
import CurrentUserMenu from '@modules/app/components/CurrentUserMenu';
import AssociationsMenu from '@modules/associations/components/AssociationsMenu';
import SettingsMenu from '@modules/settings/components/SettingsMenu';
import { sectionFromPathname } from '@modules/shared/url';

function AdminHeader() {
  const location = useLocation();

  const renderSubmenu = () => {
    const section = sectionFromPathname(location.pathname);
    switch (section) {
      case AdminRouteSection.Associations:
        return <AssociationsMenu />;
      case AdminRouteSection.Settings:
        return <SettingsMenu />;
      default:
        return null;
    }
  };

  return (
    <header className="cpdAdminHeader">
      <section className="cpdAdminHeader__section">
        <Logo className="cpdAdminHeader__section__logo" />
        <nav className="cpdAdminHeader__section__navigation">
          <AdminMenu />
        </nav>
        <div className="cpdAdminHeader__section__currentUser">
          <CurrentUserMenu />
        </div>
      </section>
      <section className="cpdAdminHeader__section cpdAdminHeader__section--secondary">
        <nav className="cpdAdminHeader__section__navigation">{renderSubmenu()}</nav>
      </section>
    </header>
  );
}

export default AdminHeader;
