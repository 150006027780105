import i18n from 'i18next';

import { IEntityValueLabels } from './entity-value-labels';
import IValueProvider from './value-provider';

export default class EnumValueProvider implements IValueProvider {
  protected valueLabels: IEntityValueLabels;
  private language: string;

  constructor(
    private readonly enumeration,
    private readonly localizationKey: string,
    private readonly excludedValues = [],
  ) {
    this.language = i18n.language;
  }

  private populateValueLabels = () => {
    if (this.valueLabels && this.language === i18n.language) {
      return;
    }

    this.language = i18n.language;
    this.valueLabels = enumToValueLabelMap(this.enumeration, this.localizationKey);
  };

  public label(value: string): string {
    this.populateValueLabels();
    return this.valueLabels[value] || value;
  }

  public values(): string[] {
    return Object.values(this.enumeration).filter(
      (value) => !this.excludedValues.includes(value),
    ) as string[];
  }
}

export const enumToValueLabelMap = (
  enumeration: any,
  localizationKey: string,
  excludedValues = [],
): { [key: string]: string } => {
  return Object.keys(enumeration).reduce((acc: IEntityValueLabels, key) => {
    const value = enumeration[key];

    if (!excludedValues.includes(value)) {
      acc[value] = i18n.t(localizationKey, { context: value.toString() });
    }
    return acc;
  }, {});
};
