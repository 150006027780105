import { SettingsActions, SettingsActionsTypes } from './action';
import { initialSettingState, ISettingsState } from './state';

const appReducer = (state: ISettingsState = initialSettingState, action: SettingsActions): ISettingsState => {
  switch (action.type) {
    case SettingsActionsTypes.SetPeriodAmount: {
      return {
        ...state,
        periodAmounts: action.payload,
      };
    }
    default:
      return state;
  }
};

export default appReducer;
