import './Initializing.scss';

import React from 'react';

import { ReactComponent as LoadingImage } from '@assets/loading.svg';

export default function Initializing() {
  return (
    <div className="cpdInitializing">
      <LoadingImage className="cpdInitializing__image" />
    </div>
  );
}
