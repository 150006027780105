import './Tooltip.scss';

import React, { ReactNode, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { nanoid } from 'nanoid';

interface IProps {
  text: string;
  children: ReactNode;
}

function Tooltip({ text, children }: IProps) {
  const idRef = useRef(`tooltip-${nanoid()}`);

  return (
    <div key={text} className="cpdTooltip" data-tip="" data-for={idRef.current}>
      {children}
      <ReactTooltip className="cpdTooltip__tooltip" id={idRef.current} effect="solid" place="bottom">
        {text}
      </ReactTooltip>
    </div>
  );
}

export default Tooltip;
