import { AuthProvider } from '@common/constants/auth-provider';
import { IAuthAccount } from '@common/models/auth-account';

export interface IAuthState {
  identity: IAuthAccount;
}

export const initialAuthState: IAuthState = {
  identity: {
    uid: '',
    email: '',
    provider: '' as AuthProvider,
  },
};
