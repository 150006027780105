import { IAppState, initialAppState } from '@modules/app/redux/state';
import { IAuthState, initialAuthState } from '@modules/auth/redux/state';
import { initialSettingState, ISettingsState } from '@modules/settings/redux/state';

export interface IRootState {
  app: IAppState;
  auth: IAuthState;
  settings: ISettingsState;
}

const initialRootState: IRootState = {
  app: initialAppState,
  auth: initialAuthState,
  settings: initialSettingState,
};

export default initialRootState;
