import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { SubmissionActivity } from '@common/constants/submission-activity';
import { SubmissionStatus } from '@common/constants/submission-status';
import { SubmissionType } from '@common/constants/submission-type';
import { ISubmission } from '@common/models/submission';
import { selectCurrentPeriod } from '@modules/app/redux/selectors';
import Initializing from '@modules/shared/components/Initializing';
import { SiteLayout } from '@modules/shared/components/Layout';
import StatusBox, { Status } from '@modules/shared/components/StatusBox';
import useQuery from '@modules/shared/components/useQuery';
import ClaimForm from './components/ClaimForm';
import SubmissionConfirmation, { ConfirmationContext } from './components/SubmissionConfirmation';
import { createClaim, getSubmission, isClaimAllowedForSubmission, updateClaim } from './service';

enum ClaimPageState {
  Loading,
  Form,
  Confirmation,
  NotRealised,
  Error,
  NotAllowed,
}

function ClaimPage() {
  const { t } = useTranslation();
  const query = useQuery();

  const currentPeriod = useSelector(selectCurrentPeriod);
  const [pageState, setPageState] = useState<ClaimPageState>(ClaimPageState.Loading);
  const [submission, setSubmission] = useState<ISubmission>(null);

  useEffect(() => {
    if (query.has('type') && query.has('id')) {
      getSubmission(query.get('type') as SubmissionType, query.get('id'))
        .then((submission) => {
          if (isClaimAllowedForSubmission(submission)) {
            setSubmission(submission);
            setPageState(ClaimPageState.Form);
          } else if (submission.status === SubmissionStatus.SubmissionNotRealised) {
            setPageState(ClaimPageState.NotRealised);
          } else {
            setPageState(ClaimPageState.NotAllowed);
          }
        })
        .catch((error) => {
          console.error(error);
          setPageState(ClaimPageState.Error);
        });
    } else {
      setPageState(ClaimPageState.Error);
    }
  }, []);

  const onSubmitCreateClaim = ({ claim }: ISubmission): Promise<void> => {
    return (claim.id ? updateClaim(submission, claim) : createClaim(submission, claim))
      .then((submission) => {
        setSubmission(submission);
        setPageState(ClaimPageState.Confirmation);
      })
      .catch((error) => {
        console.error(error);
        toast.error(t('claimPage.submitErrorMessage'));
      });
  };

  return (
    <SiteLayout title={t('claimPage.title')}>
      {pageState === ClaimPageState.Loading && <Initializing />}
      {pageState === ClaimPageState.Form && (
        <ClaimForm submission={submission} onSubmitCreateClaim={onSubmitCreateClaim} />
      )}
      {pageState === ClaimPageState.Confirmation && (
        <SubmissionConfirmation submission={submission} context={ConfirmationContext.Claim} />
      )}
      {pageState === ClaimPageState.NotRealised && (
        <StatusBox
          status={Status.Warning}
          title={t('claimPage.notRealised.title')}
          description={t('claimPage.notRealised.description')}
        />
      )}
      {pageState === ClaimPageState.Error && (
        <StatusBox
          status={Status.Error}
          title={t('claimPage.error.title')}
          description={t('claimPage.error.description')}
        />
      )}
      {pageState === ClaimPageState.NotAllowed && (
        <StatusBox
          status={Status.Warning}
          title={t('claimPage.notAllowed.title')}
          description={t('claimPage.notAllowed.description', {
            date1: currentPeriod.dates[SubmissionActivity.Claim][0],
            date2: currentPeriod.dates[SubmissionActivity.Claim][1],
          })}
        />
      )}
    </SiteLayout>
  );
}

export default ClaimPage;
