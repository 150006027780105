export function getPhoneValidationSchema(Yup, isRequired = true) {
  return Yup.string()
    .trim()
    .matches(/^\d{10}$/, { message: 'phone', excludeEmptyString: !isRequired });
}

export function getEnumArrayValidationSchema(Yup, enumeration: { [key: string]: string }) {
  return Yup.array().test({
    message: 'array-enum',
    test: (values) => {
      const allowedValues = Object.values(enumeration);
      return Array.isArray(values) && values.every((value) => allowedValues.includes(value));
    },
  });
}
