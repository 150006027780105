import {
  amountPerCredit as defaultAmountPerCredit,
  maxCuCreditsAllowed,
  maxCuCreditsAllowedPerYear,
  maxIfCreditsAllowed,
  maxIfCreditsAllowedPerYear,
} from '../constants/global';
import { SubmissionType } from '../constants/submission-type';

export function computeAmountPerCredit(
  type: SubmissionType.If | SubmissionType.Cu,
  creditsCount: number,
  creditsCountAlreadyObtained = 0,
  amountPerCredit = defaultAmountPerCredit,
): number {
  const availableCredits = getAvailableCredits(type, creditsCountAlreadyObtained);
  return Math.min(availableCredits, creditsCount) * amountPerCredit;
}

export function getAllowedCreditsPerYear(
  type: SubmissionType.If | SubmissionType.Cu,
  creditsCountAlreadyObtained = 0,
): number {
  const availableCredits = getAvailableCredits(type, creditsCountAlreadyObtained);
  const maxCreditsAllowedPerYear =
    type === SubmissionType.If ? maxIfCreditsAllowedPerYear : maxCuCreditsAllowedPerYear;

  return Math.min(availableCredits, maxCreditsAllowedPerYear);
}

function getAvailableCredits(
  type: SubmissionType.If | SubmissionType.Cu,
  creditsCountAlreadyObtained = 0,
): number {
  const maxCreditsAllowed = type === SubmissionType.If ? maxIfCreditsAllowed : maxCuCreditsAllowed;
  return Math.max(maxCreditsAllowed - (creditsCountAlreadyObtained || 0), 0);
}
