import { IOption } from '@novo-electronique/react-forms';
import { ISelectFieldOption } from '@novo-electronique/react-forms/dist/FormSelectFieldOptions';
import i18next from 'i18next';

import IValueProvider from '@common/value-provider';

export const valueProviderToOptions = (valueProvider: IValueProvider): IOption[] => {
  return valueProvider.values().map((value) => ({
    value,
    key: value,
    label: valueProvider.label(value),
  }));
};

export const appendNoneOption = (options: ISelectFieldOption[], value = 'none'): ISelectFieldOption[] => {
  return [{ label: i18next.t('general.none'), value }, ...options];
};
