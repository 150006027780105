import './DropdownButton.scss';

import React, { MouseEvent, ReactNode, useEffect, useState } from 'react';
import { Button, ButtonSize, ButtonStyle } from '@novo-electronique/react-button';
import classNames from 'classnames';

interface IProps {
  children: ReactNode;
  style?: ButtonStyle;
  size?: ButtonSize;
  options?: {
    label: string;
    action: () => void;
  }[];
}

export default function DropdownButton({ children, size, style, options = [] }: IProps) {
  const [isShowingOptions, setShowingOptions] = useState(false);

  useEffect(() => {
    const closeOptions = () => setShowingOptions(false);
    window.addEventListener('click', closeOptions);
    return () => {
      window.removeEventListener('click', closeOptions);
    };
  }, []);

  const showOptions = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowingOptions(!isShowingOptions);
  };

  const renderOptions = () => {
    const itemsClassName = classNames('cpdDropdownButton__options', {
      [`cpdDropdownButton__options--${size}`]: size,
    });

    return (
      <ul className={itemsClassName}>
        {options.map((option, i) => (
          <li key={i} className="cpdDropdownButton__options__item">
            <button className="cpdDropdownButton__options__item__button" onClick={option.action}>
              {option.label}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="cpdDropdownButton">
      <Button size={size} style={style} onClick={showOptions}>
        {children}
      </Button>
      {isShowingOptions && renderOptions()}
    </div>
  );
}
