import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { AdminRoutePage, SiteRoutePage } from '@constants/routes';
import { selectIsAuthenticationChecked, selectUser } from '@modules/app/redux/selectors';
import { checkIfAuthenticated } from '@modules/auth/service';
import Initializing from '@modules/shared/components/Initializing';
import { useThunkDispatch } from '@src/redux/useThunkDispatch';

interface IProps extends RouteProps {
  defaultRoute: AdminRoutePage | SiteRoutePage;
}

function AppUnauthorizedRoute({ children, defaultRoute, ...rest }: IProps) {
  const isChecked = useSelector(selectIsAuthenticationChecked);
  const user = useSelector(selectUser);
  const dispatch = useThunkDispatch();

  useEffect(() => {
    if (!isChecked) {
      dispatch(checkIfAuthenticated());
    }
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }) => (
        <>
          {!isChecked && <Initializing />}
          {isChecked &&
            (!user ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: defaultRoute,
                  state: { from: location },
                }}
              />
            ))}
        </>
      )}
    />
  );
}

export default AppUnauthorizedRoute;
