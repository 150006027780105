import { ThunkDispatch } from 'redux-thunk';

import { amountPerCredit } from '@common/constants/global';
import { IPeriodAmount } from '@common/models/period-amount';
import { IUser } from '@common/models/user';
import apiClient from '@modules/shared/api-client';
import { IRootState } from '@src/redux/root.state';
import { setPeriodAmounts } from './redux/action';
import { selectPeriodAmounts } from './redux/selectors';

export function saveUser(user: IUser): Promise<IUser> {
  return apiClient.post('/user', user);
}

export function getAllPeriodAmount(): Promise<IPeriodAmount[]> {
  return apiClient.get('/period/amount');
}

export function savePeriodAmount(periodAmounts: IPeriodAmount[]) {
  return async (dispatch: ThunkDispatch<{}, {}, any>): Promise<void> => {
    await apiClient.post('/period/amount', periodAmounts);
    await dispatch(refreshPeriodAmounts(true));
  };
}

export function refreshPeriodAmounts(isForcing = false) {
  return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => IRootState): Promise<void> => {
    const currentPeriodAmounts = selectPeriodAmounts(getState());

    if (isForcing || !currentPeriodAmounts) {
      const periods = await getAllPeriodAmount();
      dispatch(setPeriodAmounts(periods));
    }
  };
}

export function getCurrentAmount(periodAmounts: IPeriodAmount[], periodId: string): number {
  const currentAmount = periodAmounts.find((periodAmount) => periodAmount.periodId === periodId)?.amount;

  return currentAmount !== undefined ? currentAmount : amountPerCredit;
}
