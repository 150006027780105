import React, { useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Form,
  FormActionButton,
  FormActionButtonType,
  FormCheckboxField,
  FormContext,
  FormFields,
  FormFileField,
  FormTextAreaField,
} from '@novo-electronique/react-forms';
import * as Yup from 'yup';

import { acceptedFormats, maxFilesCountToUpload } from '@common/constants/global';
import { SubmissionType } from '@common/constants/submission-type';
import { ISubmission } from '@common/models/submission';
import {
  getInitialValuesFromType as getClaimInitialValues,
  getValidationSchemaFromType,
} from '@common/utils/claim';
import { getInitialValuesFromType as getSubmissionInitialValues } from '@common/utils/submission';
import { getPeriod } from '@common/utils/submission-period';
import { removeFile, uploadFile } from '@modules/app/service';
import FormPrint from '@modules/shared/components/FormPrint';
import StatusBox, { Status } from '@modules/shared/components/StatusBox';
import ActivityClaimSection from '@modules/submissions/components/ActivityClaimSection';
import ClaimGeneralInformationSection from '@modules/submissions/components/ClaimGeneralInformationSection';
import CoursesClaimSection from '@modules/submissions/components/CoursesClaimSection';
import PaymentInfo from '@modules/submissions/components/PaymentInfo';
import SubmissionTypeField from '@modules/submissions/components/SubmissionTypeField';

interface IProps {
  submission: ISubmission;
  onSubmitCreateClaim: (submission: ISubmission) => Promise<void>;
}

function ClaimForm({ submission, onSubmitCreateClaim }: IProps) {
  const { t } = useTranslation();

  const validationSchemaRef = useRef(
    Yup.object({
      claim: getValidationSchemaFromType(Yup, submission),
    }),
  );
  const initialValues = useMemo<ISubmission>(
    () => ({
      ...getSubmissionInitialValues(submission.type, submission),
      claim: getClaimInitialValues(submission.type, submission),
    }),
    [submission],
  );

  const period = getPeriod(submission.periodId);
  const isCourseClaim = submission.type === SubmissionType.If || submission.type === SubmissionType.Cu;

  return (
    <FormContext
      initialValues={initialValues}
      validationSchema={validationSchemaRef.current}
      onSubmit={onSubmitCreateClaim}
    >
      <FormPrint title={initialValues.code}>
        <Form>
          {isCourseClaim && (
            <StatusBox
              status={Status.Warning}
              description={
                <Trans
                  i18nKey={t('claimPage.deadlineWarning', { date: period.dates.claim[1] })}
                  components={{ b: <strong /> }}
                />
              }
            />
          )}

          <ClaimGeneralInformationSection />
          <div className="cpdHiddenForPrint">
            <FormFields title={t('submissionPage.submissionType')} readonly={true}>
              <SubmissionTypeField name="type" type={submission.type} width={100} />
            </FormFields>
          </div>

          {isCourseClaim && (
            <CoursesClaimSection submissionType={submission.type as SubmissionType.If | SubmissionType.Cu} />
          )}
          {!isCourseClaim && (
            <ActivityClaimSection
              submissionType={submission.type as SubmissionType.Pca | SubmissionType.Pp}
            />
          )}

          <div className="cpdHiddenForPrint">
            <FormFields title={t('claimPage.supportingDocumentsSection')} required>
              {isCourseClaim && (
                <p className="cpdFieldTextPrecision">
                  {t('claimPage.supportingDocumentPrecision', {
                    date: period.dates['claim-extension'][1],
                  })}
                </p>
              )}
              <FormFileField
                name="claim.supportingDocuments"
                label={t('claim.supportingDocuments')}
                tooltip={
                  submission.version > 1 &&
                  (submission.type === SubmissionType.Pca || submission.type === SubmissionType.Pp) &&
                  t('claim.supportingDocumentsTooltip')
                }
                description={t('claim.supportingDocumentsDescription', { context: submission.type })}
                upload={uploadFile}
                remove={removeFile}
                accept={acceptedFormats}
                maxFiles={maxFilesCountToUpload}
                width={100}
              />
            </FormFields>
          </div>

          {isCourseClaim && (
            <FormFields title={t('claimPage.ackParticipationSection')} required>
              <div className="cpdFormSingleField">
                <FormCheckboxField
                  name="claim.ackParticipation"
                  label={t('claim.ackParticipation', { context: submission.type })}
                  width={100}
                />
              </div>
            </FormFields>
          )}
          <PaymentInfo />
          <FormFields title={t('submissionPage.message')}>
            <FormTextAreaField name="claim.message" label={t('submission.message')} width={100} />
          </FormFields>
          <FormActionButton type={FormActionButtonType.Submit}>{t('submissionPage.submit')}</FormActionButton>
        </Form>
      </FormPrint>
    </FormContext>
  );
}

export default ClaimForm;
