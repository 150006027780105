export enum SubmissionActivity {
  Submission = 'submission',
  SubmissionDocumentsExtension = 'submission-extension',
  Claim = 'claim',
  ClaimDocumentsExtension = 'claim-extension',
}

export enum SubmissionActivityStatus {
  Forthcoming = 'forthcoming',
  Ongoing = 'ongoing',
  Past = 'past',
}

export const allActivities = Object.values(SubmissionActivity);
