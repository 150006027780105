import { ColDef } from '@ag-grid-community/core';
import i18n from 'i18next';

import { DataType } from '@common/constants/data-type';
import { ICache } from '@modules/app/redux/state';

export const submissionColumnDefinition = ({
  association,
  localSection,
  serviceCenter,
}: ICache): ColDef[] => [
  {
    field: 'code',
    headerName: i18n.t('submission.code'),
    type: DataType.Text,
  },
  {
    field: 'firstName',
    headerName: i18n.t('submission.firstName'),
    type: DataType.Text,
  },
  {
    field: 'lastName',
    headerName: i18n.t('submission.lastName'),
    type: DataType.Text,
  },
  {
    field: 'type',
    headerName: i18n.t('submission.typeHeader'),
    type: DataType.SubmissionType,
    minWidth: 90,
    initialWidth: 90,
    flex: undefined,
  },
  {
    field: 'role',
    headerName: i18n.t('submission.role'),
    type: DataType.Role,
    hide: true,
  },
  {
    field: 'associationId',
    headerName: 'Association',
    type: DataType.Association,
    filterParams: {
      comparator: (a, b) =>
        association.valueProvider?.label(a).localeCompare(association.valueProvider.label(b)),
      values: association.valueProvider?.values(),
    },
  },
  {
    field: 'sectionId',
    headerName: 'Section',
    type: DataType.LocalSection,
    filterParams: {
      comparator: (a, b) =>
        localSection.valueProvider?.label(a).localeCompare(localSection.valueProvider.label(b)),
      values: localSection.valueProvider?.values(),
    },
  },
  {
    field: 'serviceCenterId',
    headerName: i18n.t('submission.serviceCenter'),
    type: DataType.ServiceCenter,
    filterParams: {
      comparator: (a, b) =>
        serviceCenter.valueProvider?.label(a).localeCompare(serviceCenter.valueProvider.label(b)),
      values: serviceCenter.valueProvider?.values(),
    },
  },
  {
    field: 'phoneNumber',
    headerName: i18n.t('submission.phoneNumber'),
    type: DataType.PhoneNumber,
    hide: true,
  },
  {
    field: 'phoneNumberExt',
    headerName: i18n.t('submission.phoneNumberExt'),
    type: DataType.Text,
    hide: true,
  },
  {
    field: 'email',
    headerName: i18n.t('submission.email'),
    type: DataType.Text,
    hide: true,
  },
  {
    field: 'language',
    headerName: i18n.t('submission.language'),
    type: DataType.Language,
    hide: true,
  },
  {
    field: 'theme',
    headerName: i18n.t('submission.theme'),
    type: DataType.Text,
    hide: true,
  },
  {
    field: 'completionDate',
    headerName: i18n.t('submission.completionDate'),
    type: DataType.Date,
  },
  {
    field: 'amountRequested',
    headerName: i18n.t('submission.amountRequested', { context: 'short' }),
    type: DataType.Amount,
  },
  {
    field: 'amountApproved',
    headerName: i18n.t('submission.amountApproved'),
    type: DataType.Amount,
    hide: true,
  },
  {
    field: 'isAmountModified',
    headerName: i18n.t('submission.isAmountModified'),
    type: DataType.Boolean,
    hide: true,
  },
  {
    field: 'priorityOrder',
    headerName: i18n.t('submission.priorityOrder', { context: 'short' }),
    type: DataType.Number,
    hide: true,
    initialWidth: 100,
    flex: undefined,
  },
  {
    field: 'periodId',
    headerName: i18n.t('submission.periodId'),
    type: DataType.SubmissionPeriod,
  },
  {
    field: 'status',
    headerName: i18n.t('submission.status'),
    type: DataType.SubmissionStatus,
    initialWidth: 180,
    flex: undefined,
  },
  {
    field: 'created',
    headerName: i18n.t('submission.created'),
    type: DataType.Date,
    hide: true,
    cellRendererParams: {
      format: 'yyyy-MM-dd (HH:mm)',
    },
  },
  {
    field: 'updated',
    headerName: i18n.t('submission.updated'),
    type: DataType.Date,
    hide: true,
    cellRendererParams: {
      format: 'yyyy-MM-dd (HH:mm)',
    },
  },
  {
    field: 'locked',
    headerName: i18n.t('submission.locked'),
    type: DataType.LockedState,
    hide: true,
    initialWidth: 100,
    flex: undefined,
  },
  {
    field: 'notes',
    headerName: i18n.t('submission.notes'),
    type: DataType.Text,
    hide: true,
    initialWidth: 250,
    flex: undefined,
  },
];

export const userColumnDefinition = (): ColDef[] => [
  {
    field: 'firstName',
    headerName: i18n.t('user.firstName'),
    type: DataType.Text,
  },
  {
    field: 'lastName',
    headerName: i18n.t('user.lastName'),
    type: DataType.Text,
  },
  {
    field: 'email',
    headerName: i18n.t('user.email'),
    type: DataType.Text,
  },
  {
    field: 'enabled',
    headerName: i18n.t('user.enabled'),
    type: DataType.Boolean,
    cellRendererParams: {
      key: 'usersAdminPage.activeStatus',
    },
  },
];
