import errorCodes from '@common/constants/error-codes';

export default class Exception {
  constructor(public code: string, public message: string = '', public payload?: any, public stack?: string) {
    this.stack = this.stack || Exception.stack();
  }

  static new(err: Error | Exception) {
    if (err instanceof Error) {
      return this.fromError(err);
    }
    return { ...err };
  }

  static fromError(err: Error) {
    const code = err.name !== 'Error' ? err.name : errorCodes.UNEXPECTED_ERROR_OCCURRED;
    const exception = new Exception(code, err.message, null);
    exception.stack = err.stack;
    return exception;
  }

  private static stack() {
    let message = null;
    new Error().stack
      .split('\n')
      .splice(1)
      .forEach((line) => {
        const match = /at new ([^(]+).*exceptions.js/g.exec(line);
        if (match) {
          message = match[1] + '\n';
        } else if (message && line.indexOf('node_module') === -1) {
          message += line;
        }
      });
    return message;
  }
}

export class InvalidDataException extends Exception {
  constructor(reason: string) {
    super(errorCodes.INVALID_DATA, reason);
  }
}
