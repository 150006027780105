import './SubmissionTypeField.scss';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldEvent, FieldWidth, useFormField } from '@novo-electronique/react-forms';
import classNames from 'classnames';

import { Language } from '@common/constants/language';
import { SubmissionType } from '@common/constants/submission-type';
import { submissionTypeValueProvider } from '@common/value-provider';
import DetailsModal from '../DetailsModal';

interface IProps {
  type: SubmissionType;
  name?: string;
  onChange?: FieldEvent<SubmissionType>;
  width?: FieldWidth;
  disabled?: boolean;
}

function SubmissionTypeField({ type, onChange, name = 'type', width = 25, disabled }: IProps) {
  const { t, i18n } = useTranslation();
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const {
    id,
    value,
    fieldProps,
    inputProps,
    disabled: fieldDisabled,
  } = useFormField<SubmissionType>(name, width, {
    onChange,
  });

  const fieldId = `${id}_${type || ''}`;
  const scope = `submissionType.${type}`;
  const activities = t(`${scope}.activities`, { returnObjects: true }) as string[];

  const isSelected = value === type;
  const isDisabled = fieldDisabled || disabled;
  const labelClassName = classNames('cpdSubmissionType', {
    'cpdSubmissionType--selected': isSelected,
    'cpdSubmissionType--disabled': isDisabled,
  });
  const descriptionClassName = classNames('cpdSubmissionType__content__description', {
    'cpdSubmissionType__content__description--emphasis':
      type === SubmissionType.If || type === SubmissionType.Cu,
  });

  const fileLink =
    i18n.language === Language.Fr
      ? 'https://fqde.qc.ca/wp-content/uploads/2022/09/Document-info-CPD-Version-francaise.pdf'
      : 'https://fqde.qc.ca/wp-content/uploads/2022/09/Document-info-CPD-Version-anglaise.pdf';

  return (
    <div {...fieldProps}>
      <input
        id={fieldId}
        type="radio"
        {...inputProps}
        value={type}
        style={{ display: 'none' }}
        disabled={isDisabled}
      />
      <label className={labelClassName} htmlFor={fieldId}>
        <article className="cpdSubmissionType__content">
          <span className="cpdSubmissionType__content__selection-mark material-icons">check_circle</span>
          <h3 className="cpdSubmissionType__content__title">{submissionTypeValueProvider.label(type)}</h3>
          <p className={descriptionClassName}>{t(`${scope}.description`)}</p>
          {activities.length > 0 && (
            <ul className="cpdSubmissionType__content__activities">
              {activities.map((activity, i) => (
                <li key={i} className="cpdSubmissionType__content__activities__item">
                  {activity}
                </li>
              ))}
            </ul>
          )}
        </article>
        <div className="cpdSubmissionType__footer">
          <p className="cpdSubmissionType__footer__type">{t(`${scope}.type`)}</p>
          <button
            className="cpdSubmissionType__footer__link"
            type="button"
            onClick={() => setModalVisible(true)}
          >
            <span className="cpdSubmissionType__footer__link__icon material-icons">info</span>
            <span className="cpdSubmissionType__footer__link__text">{t('submissionType.details')}</span>
          </button>
        </div>
      </label>
      {isModalVisible && (
        <DetailsModal
          title={`${submissionTypeValueProvider.label(type)} – ${t('submissionType.eligibleActivities')}`}
          details={{
            i18nKey: `${scope}.modal`,
            costLink: fileLink,
          }}
          onClose={() => setModalVisible(false)}
        />
      )}
    </div>
  );
}

export default SubmissionTypeField;
