import { ProgramStatus } from '@common/constants/program-status';
import { SubmissionType } from '@common/constants/submission-type';
import { University, UniversitySemester } from '@common/constants/university';
import { getClaimInitialValues, getClaimSchema, IClaim } from '@common/models/claim/claim';
import { ICoursesSubmission } from '@common/models/submission';
import { computeAmountPerCredit } from '@common/utils/credits';
import { getEnumArrayValidationSchema } from '@common/utils/validation-schema';

export interface ICoursesClaim extends IClaim {
  university: University;
  semesters: UniversitySemester[];
  programTitle: string;
  creditsCount: number;
  creditsCountApproved: number;
  programStatus: ProgramStatus;
  submission?: ICoursesSubmission;
}

export const getCourseClaimSchema = (Yup, submission: ICoursesSubmission, includeAllProperties: boolean) =>
  Yup.object().shape({
    ...getClaimSchema(Yup).fields,
    university: Yup.string().required(),
    semesters: getEnumArrayValidationSchema(Yup, UniversitySemester).min(1).required(),
    programTitle: Yup.string().max(150).required(),
    creditsCount: Yup.number().min(1).max(submission.creditsCount).required(),
    creditsCountApproved: Yup.number()
      .min(0)
      .max(Yup.ref('creditsCount'))
      .nullable()
      .meta({ required: true }),
    programStatus: Yup.string().oneOf(Object.values(ProgramStatus)).required(),

    ...(includeAllProperties && {
      amountApproved: Yup.number().min(0).max(Yup.ref('amountRequested')).required(),
    }),
  });

export const getCoursesClaimInitialValues = (submission: ICoursesSubmission): ICoursesClaim => ({
  ...getClaimInitialValues(submission),
  university: submission.claim?.university || submission.university,
  semesters: submission.claim?.semesters || submission.semesters,
  programTitle: submission.claim?.programTitle || submission.programTitle,
  creditsCount: submission.claim?.creditsCount || submission.creditsCount,
  creditsCountApproved: null,
  amountRequested: isFinite(submission.claim?.creditsCount || submission.creditsCount)
    ? computeAmountPerCredit(SubmissionType.Cu, submission.claim?.creditsCount || submission.creditsCount)
    : null,
  programStatus: submission.claim?.programStatus,
  submission,
});
