import { IDataQuery, IDataQueryResponse } from '@novo-electronique/react-data-table';

import apiClient from '@modules/shared/api-client';

export const getData = async (source: string, request: any): Promise<IDataQueryResponse> => {
  return await apiClient.post<IDataQueryResponse>(source, request);
};

export const getCsvFile = async (source: string, dataQuery: IDataQuery): Promise<void> => {
  return await apiClient.download(`${source}/csv`, null, dataQuery);
};

export const getXlsxFile = async (source: string, dataQuery: IDataQuery): Promise<void> => {
  return await apiClient.download(`${source}/xlsx`, null, dataQuery);
};
