import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import env from '@config/env';
import { AdminRoutePage, SiteRoutePage } from '@constants/routes';
import { selectIsAuthenticationChecked, selectUser } from '@modules/app/redux/selectors';
import { checkIfAuthenticated, signOut } from '@modules/auth/service';
import Initializing from '@modules/shared/components/Initializing';
import { IdleTimer } from '@modules/shared/idle-timer';
import { useThunkDispatch } from '@src/redux/useThunkDispatch';

interface IProps extends RouteProps {
  redirectPage: AdminRoutePage | SiteRoutePage;
}

function AppProtectedRoute({ children, redirectPage, ...rest }: IProps) {
  const { t } = useTranslation();
  const isChecked = useSelector(selectIsAuthenticationChecked);
  const user = useSelector(selectUser);
  const history = useHistory();
  const dispatch = useThunkDispatch();

  useEffect(() => {
    if (!isChecked) {
      dispatch(checkIfAuthenticated());
    }

    toast.dismiss();

    const timer = new IdleTimer(env.sessionTimeout, window.sessionStorage);
    timer.run(() =>
      dispatch(signOut()).then(() => {
        history.push(redirectPage);
        toast.info(t('general.inactivityFeedback'), { autoClose: false });
      }),
    );

    return () => timer.dispose();
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }) => (
        <>
          {!isChecked && <Initializing />}
          {isChecked &&
            (user ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: redirectPage,
                  state: { from: location },
                }}
              />
            ))}
        </>
      )}
    />
  );
}

export default AppProtectedRoute;
