import React, { useContext, useEffect, useState } from 'react';
import { Button, ButtonSize, ButtonStyle } from '@novo-electronique/react-button';
import {
  FormAmountField,
  FormDataContext,
  FormDateField,
  FormFields,
  FormTextField,
} from '@novo-electronique/react-forms';
import i18n from 'i18next';

import { SubmissionStatus } from '@common/constants/submission-status';
import { IClaim } from '@common/models/claim';
import { ISubmission } from '@common/models/submission';

interface IProps {
  submission: ISubmission;
  onPaymentChange: (IPayment) => void;
}

function PaymentEdition({ submission, onPaymentChange }: IProps) {
  const {
    formik: { dirty, setFieldValue },
    values,
  } = useContext(FormDataContext);

  const claim = values.claim as IClaim;

  const isSubmissionAccepted = submission.status === SubmissionStatus.ClaimAccepted;
  const paymentIsCreated = Boolean(submission.claim?.payment?.id);
  const [isRenderingForm, setFormRendering] = useState<boolean>(paymentIsCreated);

  useEffect(() => {
    onPaymentChange(claim.payment);
  }, [claim.payment]);

  useEffect(() => {
    if (isRenderingForm !== paymentIsCreated && !dirty) {
      setFormRendering(paymentIsCreated);
    }
  }, [dirty]);

  useEffect(() => {
    if (!paymentIsCreated && isRenderingForm) {
      setFieldValue('claim.payment.amount', claim?.amountApproved || 0);
    }
  }, [claim.amountApproved, isRenderingForm]);

  const beginPayment = () => {
    if (!paymentIsCreated) {
      setFieldValue('claim.payment.confirmationNo', submission.claim.payment?.confirmationNo || '');
      setFieldValue('claim.payment.transactionDate', submission.claim.payment?.transactionDate || new Date());
    }
    setFormRendering(true);
  };

  const deletePayment = () => {
    setFormRendering(false);
    setFieldValue('claim.payment', null);
  };

  return (
    <>
      {!isRenderingForm && (
        <Button
          size={ButtonSize.Small}
          style={ButtonStyle.Normal}
          onClick={beginPayment}
          disabled={!isSubmissionAccepted}
        >
          {i18n.t('payment.transaction.new')}
        </Button>
      )}
      {isRenderingForm && (
        <FormFields title={i18n.t('payment.transaction.title')}>
          <FormAmountField
            name="claim.payment.amount"
            label={i18n.t('payment.transaction.amount')}
            width={33}
            disabled
          />
          <FormTextField
            name="claim.payment.confirmationNo"
            label={i18n.t('payment.transaction.confirmationNo')}
            width={33}
            disabled={paymentIsCreated}
          />
          <FormDateField
            name="claim.payment.transactionDate"
            label={i18n.t('payment.transaction.date')}
            width={33}
            disabled={paymentIsCreated}
          />

          {paymentIsCreated && (
            <div className="cpdFormCancelButton">
              <Button style={ButtonStyle.Danger} size={ButtonSize.Small} onClick={deletePayment}>
                {i18n.t('payment.transaction.delete')}
              </Button>
            </div>
          )}
        </FormFields>
      )}
    </>
  );
}

export default PaymentEdition;
