import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import { AdminRoutePage } from '@constants/routes';
import AppProtectedRoute from '@modules/app/components/AppProtectedRoute';
import AppUnauthorizedRoute from '@modules/app/components/AppUnauthorizedRoute';
import NotFoundPage from '@modules/app/NotFoundPage';
import associationsRoutes from '@modules/associations/routes';
import authRoutes from '@modules/auth/routes';
import settingsRoutes from '@modules/settings/routes';
import { RouteConfig, RouteType } from '@modules/shared/route';
import siteRoutes from '@modules/site/routes';
import submissionRoutes from '@modules/submissions/routes';
import { history } from '@src/redux/store';

function AppRouter() {
  const renderRoutes = (routes: RouteConfig[]) => {
    return routes.map(({ path, component, type = RouteType.Normal }) => {
      switch (type) {
        case RouteType.Protected:
          return (
            <AppProtectedRoute
              key={path}
              path={path as string}
              redirectPage={AdminRoutePage.Authentication}
              exact
            >
              {component}
            </AppProtectedRoute>
          );
        case RouteType.Unauthorized:
          return (
            <AppUnauthorizedRoute
              key={path}
              path={path as string}
              defaultRoute={AdminRoutePage.Submissions}
              exact
            >
              {component}
            </AppUnauthorizedRoute>
          );
        default:
          return (
            <Route key={path} path={path as string} exact>
              {component}
            </Route>
          );
      }
    });
  };

  return (
    <ConnectedRouter history={history}>
      <Switch>
        {renderRoutes(siteRoutes)}
        {renderRoutes(submissionRoutes)}
        {renderRoutes(associationsRoutes)}
        {renderRoutes(settingsRoutes)}
        {renderRoutes(authRoutes)}

        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </ConnectedRouter>
  );
}

export default AppRouter;
