import { maxFilesCountToUpload } from '@common/constants/global';
import { IAttachment } from '@common/models/attachment';
import { IPayment } from '@common/models/payment';
import { ISubmission } from '@common/models/submission';
import { getPaymentInfoInitialValues, getPaymentInfoValidationSchema, IPaymentInfo } from '../payment-info';

export interface IClaim extends IPaymentInfo {
  id?: string;

  amountRequested?: number;
  amountApproved?: number;
  supportingDocuments: Partial<IAttachment>[];

  ackParticipation: boolean;

  message?: string;
  submission?: ISubmission;
  payment?: IPayment;

  created?: Date;

  isAmountModified?: boolean;
}

export const getClaimSchema = (Yup) =>
  Yup.object().shape({
    supportingDocuments: Yup.array().max(maxFilesCountToUpload),
    ackParticipation: Yup.bool().oneOf([true]).required(),
    message: Yup.string(),
    ...getPaymentInfoValidationSchema(Yup).fields,
  });

export const getClaimInitialValues = (submission: ISubmission): IClaim => ({
  id: submission.claim?.id || '',
  supportingDocuments: submission.claim?.supportingDocuments || [],
  ackParticipation: submission.claim?.ackParticipation,
  message: submission.claim?.message || '',
  ...getPaymentInfoInitialValues(submission),
});
