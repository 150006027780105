import { useRef } from 'react';
import ReactDOM from 'react-dom';

interface IProps {
  children: string;
}

function DocumentTitle({ children }: IProps) {
  const titleElement = useRef(document.getElementsByTagName('title')[0]);
  return ReactDOM.createPortal(children, titleElement.current);
}

export default DocumentTitle;
