import { IPeriodAmount } from '@common/models/period-amount';

export enum SettingsActionsTypes {
  SetPeriodAmount = '[APP] Set period amount',
}

interface ISetAmountPerCredits {
  type: typeof SettingsActionsTypes.SetPeriodAmount;
  payload: IPeriodAmount[];
}

export const setPeriodAmounts = (periodAmounts: IPeriodAmount[]): ISetAmountPerCredits => ({
  type: SettingsActionsTypes.SetPeriodAmount,
  payload: periodAmounts,
});

export type SettingsActions = ISetAmountPerCredits;
