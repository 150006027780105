import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { environment, Environments } from '@config/env';
import rootReducer from './root.reducer';
import { IRootState } from './root.state';

export const history = createBrowserHistory();

let store;

const configureStore = (storeState: IRootState): Store<IRootState, any> => {
  if (!store) {
    const appMiddleWares = [];

    let middleware = applyMiddleware(routerMiddleware(history), thunk, ...appMiddleWares);

    if (environment.env === Environments.Development) {
      // redux-devtools-extension:
      middleware = composeWithDevTools(middleware);
    }
    // root reducer with router state
    store = createStore(rootReducer(history), storeState, middleware);
  }
  return store;
};

export { configureStore, store };
