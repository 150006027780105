import Yup from 'yup';

import { ActivityFees } from '@common/constants/activity-fees';
import { SubmissionType } from '@common/constants/submission-type';
import {
  getActivityClaimInitialValues,
  getActivityClaimSchema,
  getCourseClaimSchema,
  getCoursesClaimInitialValues,
  IActivityClaim,
  IClaim,
  ICoursesClaim,
} from '@common/models/claim';
import { IActivitySubmission, ICoursesSubmission, ISubmission } from '@common/models/submission';

export function getValidationSchemaFromType(
  Yup,
  submission: ISubmission,
  includeAllProperties = false,
): Yup.ObjectSchema<any> {
  switch (submission.type) {
    case SubmissionType.Cu:
    case SubmissionType.If:
      return getCourseClaimSchema(Yup, submission as ICoursesSubmission, includeAllProperties);
    case SubmissionType.Pca:
    case SubmissionType.Pp:
      return getActivityClaimSchema(Yup, submission as IActivitySubmission, includeAllProperties);
    default:
      throw new Error('Invalid submission type specified');
  }
}

export function getInitialValuesFromType(type: SubmissionType, currentValues: ISubmission): IClaim {
  switch (type) {
    case SubmissionType.Cu:
    case SubmissionType.If:
      return getCoursesClaimInitialValues(currentValues as ICoursesSubmission);
    case SubmissionType.Pca:
    case SubmissionType.Pp:
      return getActivityClaimInitialValues(currentValues as IActivitySubmission);
    default:
      throw new Error('Invalid submission type specified');
  }
}

export function getActivityClaimModificationsCount(claim: IActivityClaim): number {
  const feesNames = Object.values(ActivityFees);

  return feesNames
    .filter((feesName) => claim[feesName + 'Approved'] !== null)
    .reduce((count, feesName) => count + (+claim[feesName] !== claim[feesName + 'Approved'] ? 1 : 0), 0);
}

export function getCoursesClaimModificationsCount(claim: ICoursesClaim): number {
  return claim.creditsCountApproved !== null && claim.creditsCountApproved !== claim.creditsCount ? 1 : 0;
}

export function getInitialActivityClaimApprovedFees(claim: IActivityClaim): IActivityClaim {
  const feesNames = Object.values(ActivityFees);
  const approvedFees = feesNames
    .filter((feesName) => claim[feesName + 'Approved'] === null)
    .reduce((obj, feesName) => ({ ...obj, [feesName + 'Approved']: claim[feesName] }), {});
  return { ...claim, ...approvedFees };
}
