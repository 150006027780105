const yupLocale = {
  mixed: {
    default: 'default',
    notOneOf: 'notOneOf',
    notType: 'notType',
    oneOf: 'oneOf',
    required: 'required',
  },
  number: {
    min: ({ min }) => generateError({ count: min }, 'number-min'),
    max: ({ max }) => generateError({ count: max }, 'number-max'),
    integer: 'integer',
    lessThan: 'lessThan',
    moreThan: 'moreThan',
    negative: 'negative',
    positive: 'positive',
  },
  string: {
    email: 'email',
    length: 'length',
    lowercase: 'lowercase',
    matches: 'matches',
    max: ({ max }) => generateError({ count: max }, 'string-max'),
    min: ({ min }) => generateError({ count: min }, 'string-min'),
    trim: 'trim',
    uppercase: 'uppercase',
    url: 'url',
    uuid: 'uuid',
  },
  array: {
    max: ({ max }) => generateError({ count: max }, 'array-max'),
    min: ({ min }) => generateError({ count: min }, 'array-min'),
  },
  date: {
    max: 'date-max',
    min: 'date-min',
  },
  object: {
    noUnknown: 'noUnknown',
  },
};

const generateError = (params: any, key: string): { key: string; params: any; scope: string } => ({
  key,
  params,
  scope: 'validation',
});

export default yupLocale;
