import React from 'react';

import { AdminRoutePage, SiteRoutePage } from '@constants/routes';
import { RouteConfig, RouteType } from '@modules/shared/route';
import ClaimPage from '@modules/submissions/ClaimPage';
import SubmissionPage from '@modules/submissions/SubmissionPage';
import SubmissionsAdminPage from '@modules/submissions/SubmissionsAdminPage';
import RecuperationPage from './SubmissionRecuperationPage';

export default [
  { path: SiteRoutePage.SubmissionRecuperation, component: <RecuperationPage /> },
  { path: SiteRoutePage.Submission, component: <SubmissionPage /> },
  { path: SiteRoutePage.Claim, component: <ClaimPage /> },
  { path: AdminRoutePage.Submissions, type: RouteType.Protected, component: <SubmissionsAdminPage /> },
] as RouteConfig[];
