import './AdminLayout.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AdminHeader from '@modules/app/components/AdminHeader';
import { selectVersion } from '@modules/app/redux/selectors';
import DocumentTitle from '@modules/shared/components/DocumentTitle';

interface IProps {
  children: React.ReactNode | React.ReactNodeArray;
}

function AdminLayout({ children }: IProps) {
  const { t } = useTranslation();
  const version = useSelector(selectVersion);

  return (
    <article className="cpdAdminLayout">
      <DocumentTitle>{t('general.title')}</DocumentTitle>
      <AdminHeader />
      <main className="cpdAdminLayout__content">{children}</main>
      <p className="cpdAdminLayout__version">{`v${version}`}</p>
    </article>
  );
}

export default AdminLayout;
