import download from 'downloadjs';

import Exception, { InvalidDataException } from '@common/models/exceptions';
import { ensureSuccessResponse } from '@common/utils/http-response';
import env from '@config/env';
import { getAuthToken } from '@modules/auth/service';

const maxRetryCount = 1;
const retryDelay = 5000;
const expectedFormat = 'application/json';

class ApiClient {
  private readonly endpoint = env.apiEndpoint;
  private readonly headers: IHeader;

  constructor() {
    this.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    };
  }

  public async get<T>(path = '', headers = {} as IHeader): Promise<T> {
    return this.req('GET', path, headers);
  }

  public async post<T>(path = '', data = {}, headers = {} as IHeader): Promise<T> {
    return this.req('POST', path, headers, data);
  }

  public async put<T>(path = '', data = {}, headers = {} as IHeader): Promise<T> {
    return this.req('PUT', path, headers, data);
  }

  public async delete<T>(path = '', headers = {} as IHeader): Promise<T> {
    return this.req('DELETE', path, headers);
  }

  public async download(path = '', fileName?: string, data = {}, headers = {} as IHeader): Promise<any> {
    const reqHeaders = await this.addHeaders(headers);
    const hasData = Object.keys(data).length > 0;

    const response = await fetch(`${this.endpoint}${path}`, {
      method: hasData ? 'POST' : 'GET',
      headers: { ...reqHeaders },
      ...(hasData && { body: JSON.stringify(data) }),
    });

    const blob = await response.blob();
    download(blob, fileName || ApiClient.getFileNameFromHeader(response.headers));
  }

  private async req<T>(method, path = '', headers = {} as IHeader, data = {}, retryCount = 0): Promise<T> {
    const reqHeaders = await this.addHeaders(headers);
    const response = await fetch(`${this.endpoint}${path}`, {
      method,
      headers: { ...reqHeaders },
      ...(Object.keys(data).length > 0 && { body: JSON.stringify(data) }),
    });

    try {
      await ensureSuccessResponse(response, expectedFormat);
    } catch (e: any) {
      if (e instanceof InvalidDataException) {
        return ApiClient.retryRequest(e, retryCount, () =>
          this.req(method, path, headers, data, retryCount + 1),
        );
      }
      throw e;
    }

    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes(expectedFormat)) {
      return response.json();
    } else {
      return response.text() as any;
    }
  }

  private async addHeaders(headers = {} as IHeader): Promise<IHeader> {
    const authHeaders = await ApiClient.getAuthHeaders();
    return {
      ...this.headers,
      ...authHeaders,
      ...headers,
    };
  }

  public async uploadFiles<T>(path = '', files: File[], fieldName: string = 'files'): Promise<T> {
    const formData = new FormData();

    for (const file of files) {
      formData.append(fieldName, file);
    }

    return this.postFormData<T>(path, formData);
  }

  public async postFormData<T>(path = '', data: FormData): Promise<T> {
    const authHeaders = await ApiClient.getAuthHeaders();

    const response = await fetch(`${this.endpoint}${path}`, {
      method: 'POST',
      headers: { ...authHeaders },
      body: data,
    });

    await ensureSuccessResponse(response);

    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    } else {
      return response.text() as any;
    }
  }

  private static retryRequest<T>(
    e: Exception,
    retryCount: number,
    requestFunction: () => Promise<T>,
  ): Promise<T> {
    if (retryCount < maxRetryCount) {
      return new Promise((resolve) => setTimeout(() => resolve(requestFunction()), retryDelay));
    }
    throw e;
  }

  private static async getAuthHeaders(): Promise<IAuthorizationHeader> {
    const token = await getAuthToken();
    return token ? { authorization: `Bearer ${token}` } : {};
  }

  private static getFileNameFromHeader(headers: Headers): string {
    const contentDisposition = headers.get('Content-Disposition');

    if (contentDisposition) {
      const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
      if (matches != null && matches[1]) {
        return matches[1].replace(/['"]/g, '');
      }
    }
    return '';
  }
}

interface IHeader {
  Accept: string;
  'Content-Type': string;
}

interface IAuthorizationHeader {
  authorization?: string;
}

export default new ApiClient();
