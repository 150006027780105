import { Language } from '@common/constants/language';
import { Role } from '@common/constants/role';
import { SubmissionStatus } from '@common/constants/submission-status';
import { SubmissionType } from '@common/constants/submission-type';
import { IAssociation } from '@common/models/association';
import { IClaim } from '@common/models/claim';
import { ILocalSection } from '@common/models/local-section';
import { IServiceCenter } from '@common/models/service-center';
import { getPhoneValidationSchema } from '@common/utils/validation-schema';

export interface ISubmission<Type extends SubmissionType = SubmissionType, Claim extends IClaim = IClaim> {
  id?: string;
  code?: string;
  version?: number;
  type: Type;
  status?: SubmissionStatus;
  periodId?: string;

  firstName: string;
  lastName: string;
  role: Role;
  association?: Partial<IAssociation>;
  section?: Partial<ILocalSection>;
  serviceCenter?: Partial<IServiceCenter>;

  phoneNumber: string;
  phoneNumberExt: string;
  email: string;
  language: Language;

  amountRequested?: number;

  message?: string;
  justification?: string;
  missingDocumentsDescription?: string;

  claim?: Claim;

  created?: Date;
  updated?: Date;

  notes?: string;
  additionalNotes?: string;

  locked?: boolean;
}

export const getSubmissionSchema = (Yup, version: number, includeAllProperties: boolean) =>
  Yup.object().shape({
    type: Yup.string().oneOf(Object.values(SubmissionType)).required(),
    version: Yup.number().min(version).max(version).required(),
    firstName: Yup.string().max(150).required(),
    lastName: Yup.string().max(150).required(),
    role: Yup.string().oneOf(Object.values(Role)).required(),
    association: Yup.object({
      id: Yup.string().required(),
    }).required(),
    section: Yup.object({
      id: Yup.string().required(),
    })
      .notRequired()
      .nullable(),
    serviceCenter: Yup.object({
      id: Yup.string().required(),
    })
      .notRequired()
      .nullable(),
    phoneNumber: getPhoneValidationSchema(Yup, true).required(),
    phoneNumberExt: Yup.string().max(10),
    email: Yup.string().email().max(150).required(),
    message: Yup.string(),

    ...(includeAllProperties && {
      status: Yup.string().oneOf(Object.values(SubmissionStatus)).required(),
      justification: Yup.mixed()
        .when(['status'], {
          is: (val) =>
            [
              SubmissionStatus.SubmissionRefused,
              SubmissionStatus.SubmissionCancelled,
              SubmissionStatus.SubmissionNotRealised,
              SubmissionStatus.ClaimRefused,
            ].includes(val),
          then: Yup.string().required(),
        })
        .meta({ required: true }),
    }),
    missingDocumentsDescription: Yup.mixed().when(['status'], {
      is: (val) =>
        [SubmissionStatus.ClaimWaitingForDocuments, SubmissionStatus.SubmissionWaitingForDocuments].includes(
          val,
        ),
      then: Yup.string().nullable(),
    }),
  });

export const getSubmissionInitialValues = (currentValues: Partial<ISubmission> = {}): ISubmission => ({
  type: '' as SubmissionType,
  version: 1,
  firstName: '',
  lastName: '',
  role: '' as Role,
  status: '' as SubmissionStatus,
  association: { id: '' },
  section: { id: '' },
  serviceCenter: { id: '' },
  phoneNumber: '',
  phoneNumberExt: '',
  email: '',
  language: '' as Language,
  message: '',
  justification: '',
  missingDocumentsDescription: '',
  amountRequested: null,
  ...currentValues,
});
