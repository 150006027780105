import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '@modules/shared/components/Modal';
import SubmissionForm from './SubmissionForm';

interface IProps {
  onSubmit: (ISubmission) => Promise<void>;
  onClose: () => void;
}

function SubmissionFormModal({ onSubmit, onClose }: IProps) {
  const { t } = useTranslation();
  const isFormDirtyRef = useRef(false);

  const closeHandler = () => {
    if (
      onClose &&
      (!isFormDirtyRef.current || confirm(t('general.confirmations.confirmDirtyFormModalClose')))
    ) {
      onClose();
    }
  };

  return (
    <Modal title={t('submissionAdminPage.newSubmission')} maximize onClose={closeHandler}>
      <SubmissionForm onSubmit={onSubmit} onFormDirty={() => (isFormDirtyRef.current = true)} />
    </Modal>
  );
}

export default SubmissionFormModal;
