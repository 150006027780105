export enum AdminRouteSection {
  Unknown = 'unknown',
  Submissions = '/administration/subventions',
  Associations = '/administration/associations',
  Settings = '/administration/parametres',
}

export enum SiteRoutePage {
  Home = '/',
  Submission = '/subvention',
  SubmissionRecuperation = '/subvention/recuperation',
  Claim = '/reclamation',
  About = '/a-propos',
  Contact = '/contact',
  Confirmation = '/confirmation',
}

export enum AdminRoutePage {
  Authentication = '/administration',
  Submissions = '/administration/subventions',
  Associations = '/administration/associations',
  LocalSections = '/administration/associations/locales',
  ServiceCenters = '/administration/associations/centres-de-services',
  Users = '/administration/parametres/utilisateurs',
  ImportantDates = '/administration/parametres/dates-importantes',
  PeriodAmount = '/administration/parametres/montant-accorde-par-credit',
}
