import { AppActions, AppActionsTypes } from './action';
import { IAppState, initialAppState } from './state';

const appReducer = (state: IAppState = initialAppState, action: AppActions): IAppState => {
  switch (action.type) {
    case AppActionsTypes.SetAuthenticationChecked: {
      return {
        ...state,
        isAuthenticationChecked: true,
      };
    }
    case AppActionsTypes.SetIsLoading: {
      const loadingCount = Math.max(state.loadingCount + (action.payload ? 1 : -1), 0);
      return {
        ...state,
        loadingCount,
      };
    }
    case AppActionsTypes.SetActiveUser: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case AppActionsTypes.DisconnectActiveUser: {
      return {
        ...state,
        user: null,
      };
    }
    case AppActionsTypes.SetCacheLoaded: {
      return {
        ...state,
        cache: {
          ...state.cache,
          isLoaded: action.payload,
        },
      };
    }
    case AppActionsTypes.SetAssociationEntity: {
      return {
        ...state,
        cache: {
          ...state.cache,
          association: action.payload,
        },
      };
    }
    case AppActionsTypes.SetLocalSectionEntity: {
      return {
        ...state,
        cache: {
          ...state.cache,
          localSection: action.payload,
        },
      };
    }
    case AppActionsTypes.SetServiceCenterEntity: {
      return {
        ...state,
        cache: {
          ...state.cache,
          serviceCenter: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default appReducer;
