import { IAssociation } from '@common/models/association';
import { ILocalSection } from '@common/models/local-section';
import { IServiceCenter } from '@common/models/service-center';
import { ISubmissionPeriod } from '@common/models/submission-period';
import { User } from '@common/models/user';
import { getPeriod } from '@common/utils/submission-period';
import IValueProvider from '@common/value-provider';
import versionInfo from '../../../version.json';

const currentPeriod = getPeriod();
const [year1, year2] = currentPeriod.years;

export interface ICacheEntity<T> {
  valueProvider: IValueProvider;
  data: T[];
}

export interface ICache {
  isLoaded: boolean;
  association: ICacheEntity<IAssociation>;
  localSection: ICacheEntity<ILocalSection>;
  serviceCenter: ICacheEntity<IServiceCenter>;
}

export interface IAppState {
  isAuthenticationChecked: boolean;
  loadingCount: number;
  version: string;
  user: User;
  periods: {
    previous: ISubmissionPeriod;
    current: ISubmissionPeriod;
    next: ISubmissionPeriod;
  };
  cache: ICache;
}

export const initialAppState: IAppState = {
  isAuthenticationChecked: false,
  loadingCount: 0,
  version: versionInfo.version,
  user: null,
  periods: {
    previous: getPeriod(`${year1 - 1}${year1}`),
    current: currentPeriod,
    next: getPeriod(`${year2}${year2 + 1}`),
  },
  cache: {
    isLoaded: false,
    association: {
      valueProvider: null,
      data: [],
    },
    localSection: {
      valueProvider: null,
      data: [],
    },
    serviceCenter: {
      valueProvider: null,
      data: [],
    },
  },
};
