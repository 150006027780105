import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, ButtonSize, ButtonStyle } from '@novo-electronique/react-button';
import { BAD_REQUEST } from 'http-status';

import { SubmissionActivity } from '@common/constants/submission-activity';
import { SubmissionStatus } from '@common/constants/submission-status';
import { SubmissionType } from '@common/constants/submission-type';
import { ISubmission } from '@common/models/submission';
import { formatPeriod } from '@common/utils/submission-period';
import {
  selectCurrentPeriod,
  selectIsCacheLoaded,
  selectIsSubmissionAllowed,
  selectIsSubmissionExtension,
} from '@modules/app/redux/selectors';
import Initializing from '@modules/shared/components/Initializing';
import { SiteLayout } from '@modules/shared/components/Layout';
import StatusBox, { Status } from '@modules/shared/components/StatusBox';
import { StatusBoxStyle } from '@modules/shared/components/StatusBox/StatusBox';
import StickyBox from '@modules/shared/components/StickyBox';
import useQuery from '@modules/shared/components/useQuery';
import SubmissionConfirmation, {
  ConfirmationContext,
} from '@modules/submissions/components/SubmissionConfirmation';
import SubmissionForm from '@modules/submissions/components/SubmissionForm';
import SubmissionStatusTag from '@modules/submissions/components/SubmissionStatusTag';
import {
  createSubmission,
  getSubmission,
  isDocumentUploadAllowed,
  isSubmissionEditable,
  updateSubmission,
} from '@modules/submissions/service';

enum SubmissionPageState {
  Loading,
  SubmissionForm,
  SubmissionConfirmation,
  Error,
  NotAllowed,
}

enum SubmissionPageContext {
  NewSubmission = 'new',
  ExistingSubmission = 'existing',
}

function SubmissionPage() {
  const { t } = useTranslation();
  const query = useQuery();

  const isLoaded = useSelector(selectIsCacheLoaded);
  const currentPeriod = useSelector(selectCurrentPeriod);
  const isSubmissionAllowed = useSelector(selectIsSubmissionAllowed);
  const isSubmissionExtension = useSelector(selectIsSubmissionExtension);

  const [submission, setSubmission] = useState<ISubmission>();
  const [pageState, setPageState] = useState<SubmissionPageState>(SubmissionPageState.Loading);

  const hasParameter = query.has('type') && query.has('id');

  const handleFetchError = (statusCode) => {
    if (statusCode === BAD_REQUEST) {
      setPageState(SubmissionPageState.NotAllowed);
    } else {
      setPageState(SubmissionPageState.Error);
    }
  };

  useEffect(() => {
    if (hasParameter) {
      getSubmission(query.get('type') as SubmissionType, query.get('id'))
        .then((submission) => {
          setSubmission(submission);
          setPageState(SubmissionPageState.SubmissionForm);
        })
        .catch((error) => {
          console.error(error);
          handleFetchError(error.payload.statusCode);
        });
    } else if (!isSubmissionAllowed) {
      setPageState(SubmissionPageState.NotAllowed);
    } else {
      setPageState(SubmissionPageState.SubmissionForm);
    }
  }, []);

  const submitSubmission = (values: ISubmission) => {
    return (hasParameter ? updateSubmission(values) : createSubmission(values))
      .then((submission) => {
        setSubmission(submission);
        setPageState(SubmissionPageState.SubmissionConfirmation);
      })
      .catch((e) => {
        console.error(e);
        toast.error(t('submissionPage.submitErrorMessage', { context: e?.message }));
      });
  };

  const isSubmissionExisting = submission && pageState === SubmissionPageState.SubmissionForm;
  let readonly = !isSubmissionEditable(submission);
  const isDisabledExceptDocuments = readonly ? isDocumentUploadAllowed(submission) : false;
  if (isDisabledExceptDocuments) {
    readonly = false;
  }
  const context = hasParameter
    ? SubmissionPageContext.ExistingSubmission
    : SubmissionPageContext.NewSubmission;

  const renderToolbar = () => {
    if (isSubmissionExisting) {
      return (
        <Button size={ButtonSize.Small} style={ButtonStyle.Action} onClick={() => window.print()}>
          <span className={`material-icons cpdIcon cpdIcon--print`} /> {t('general.admin.print')}
        </Button>
      );
    }
    return null;
  };

  const renderStatusFeedbackBox = () => {
    if (!submission?.claim && submission?.status !== SubmissionStatus.SubmissionRefused) {
      return null;
    }
    const isRefused =
      submission.status === SubmissionStatus.SubmissionRefused ||
      submission.status === SubmissionStatus.ClaimRefused;

    const status = isRefused ? Status.Error : Status.Info;
    return (
      <StatusBox
        status={status}
        description={
          <>
            {t('submissionPage.statusFeedback', {
              context: submission.status,
              date: submission?.claim.created,
              amount: submission?.claim.amountApproved,
            })}
            {isRefused && <blockquote>{submission.justification}</blockquote>}
          </>
        }
        style={StatusBoxStyle.Small}
      />
    );
  };

  return (
    <SiteLayout
      title={
        <>
          {t('submissionPage.title')}{' '}
          {isSubmissionExisting && readonly && <SubmissionStatusTag>{submission.status}</SubmissionStatusTag>}
        </>
      }
      toolbar={renderToolbar()}
    >
      {(!isLoaded || pageState === SubmissionPageState.Loading) && <Initializing />}
      {isLoaded && pageState !== SubmissionPageState.Loading && (
        <>
          {pageState === SubmissionPageState.SubmissionForm && (
            <>
              {!submission && isSubmissionExtension && (
                <StickyBox
                  title={t('ifPrecision.title')}
                  description={t('ifPrecision.warning', {
                    date: currentPeriod.extensions[SubmissionActivity.Submission][SubmissionType.If][0],
                    year: currentPeriod.years[0],
                  })}
                />
              )}
              {renderStatusFeedbackBox()}
              <SubmissionForm
                onSubmit={submitSubmission}
                submission={submission}
                readonly={readonly}
                isDisabledExceptDocuments={isDisabledExceptDocuments}
              />
            </>
          )}
          {pageState === SubmissionPageState.SubmissionConfirmation && (
            <SubmissionConfirmation
              submission={submission}
              context={hasParameter ? ConfirmationContext.SubmissionUpdated : ConfirmationContext.Default}
            />
          )}
          {pageState === SubmissionPageState.Error && (
            <StatusBox
              status={Status.Error}
              title={t('submissionPage.error.title')}
              description={t('submissionPage.error.description')}
            />
          )}
          {pageState === SubmissionPageState.NotAllowed && (
            <StatusBox
              status={Status.Warning}
              title={t('submissionPage.notAllowed.title', { context })}
              description={t('submissionPage.notAllowed.description', {
                context,
                period: formatPeriod(currentPeriod),
                date1: currentPeriod.dates[SubmissionActivity.Submission][0],
                date2: currentPeriod.dates[SubmissionActivity.Submission][1],
              })}
            />
          )}
        </>
      )}
    </SiteLayout>
  );
}

export default SubmissionPage;
