import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Language } from '@common/constants/language';
import { SubmissionActivity } from '@common/constants/submission-activity';
import { isActivityAllowed } from '@common/utils/submission-period';
import { SiteRoutePage } from '@constants/routes';
import { selectCurrentPeriod } from '@modules/app/redux/selectors';
import { MenuItemButton, MenuItemLink, MenuResponsive, MenuStyle } from '@modules/shared/components/Menu';

interface IProps {
  style?: MenuStyle.Site | MenuStyle.Home;
}

function SiteMenu({ style = MenuStyle.Site }: IProps) {
  const { t, i18n } = useTranslation();
  const period = useSelector(selectCurrentPeriod);

  const otherLanguage = Object.values(Language).find((language) => language !== i18n.language);
  const isSubmissionAllowed = isActivityAllowed(SubmissionActivity.Submission, period);

  return (
    <MenuResponsive style={style}>
      <MenuItemLink to={SiteRoutePage.Home}>{t('siteMenu.home')}</MenuItemLink>
      {isSubmissionAllowed && (
        <MenuItemLink to={SiteRoutePage.Submission}>{t('siteMenu.submission')}</MenuItemLink>
      )}
      <MenuItemLink to={SiteRoutePage.About}>{t('siteMenu.about')}</MenuItemLink>
      <MenuItemLink to={SiteRoutePage.Contact}>{t('siteMenu.contact')}</MenuItemLink>
      <MenuItemButton onClick={() => i18n.changeLanguage(otherLanguage)}>
        {t('general.language', { context: otherLanguage })}
      </MenuItemButton>
    </MenuResponsive>
  );
}

export default SiteMenu;
