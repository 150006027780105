import React from 'react';
import { Trans } from 'react-i18next';

import Modal from '@modules/shared/components/Modal';

interface IProps {
  title: string;
  details: {
    i18nKey: string;
    context?: string;
    costLink?: string;
  };
  onClose: () => void;
}

function DetailsModal({ title, details: { i18nKey, context, costLink }, onClose }: IProps) {
  // To simplify, we are using Trans component to render information based on tag specified in i18n file. Please note that all tags are sanitized before to be rendered.
  return (
    <Modal title={title} onClose={onClose}>
      <Trans
        i18nKey={i18nKey}
        tOptions={{ context, joinArrays: '' }}
        components={{
          h2: <h2 className="cpdSiteLayout__panel__content__subtitle" />,
          p: <p className="cpdSiteLayout__panel__content__text" />,
          ul: <ul className="cpdSiteLayout__panel__content__list" />,
          ol: <ol className="cpdSiteLayout__panel__content__list" />,
          li: <li className="cpdSiteLayout__panel__content__list__item" />,
          em: (
            <em className="cpdSiteLayout__panel__content__text cpdSiteLayout__panel__content__text--emphasis" />
          ),
          costLink: (
            <a
              rel="noopener noreferrer"
              className="cpdSiteLayout__panel__content__link"
              href={costLink || '#'}
              target="_blank"
            />
          ),
        }}
      />
    </Modal>
  );
}

export default DetailsModal;
