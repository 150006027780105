import './SidePanel.scss';

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FormDataContext } from '@novo-electronique/react-forms';
import { SidePanel as BaseSidePanel } from '@novo-electronique/react-side-panel';

interface IProps {
  closeHandler?: () => void;
  children: JSX.Element | JSX.Element[];
}

function SidePanel({ children, closeHandler }: IProps) {
  const { t } = useTranslation();
  const { formik } = useContext(FormDataContext);

  const onClose = () => {
    if (!closeHandler) {
      return;
    }

    if (formik?.isSubmitting) {
      return alert(t('general.confirmations.pleaseWaitOperationInProgress'));
    }
    if (!formik?.dirty || confirm(t('general.confirmations.confirmDirtyFormModalClose'))) {
      closeHandler();
    }
  };

  return <BaseSidePanel closeHandler={onClose}>{children}</BaseSidePanel>;
}

export default SidePanel;
