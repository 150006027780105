import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  DisplayStyles,
  FieldEvent,
  FormAmountField,
  FormDataContext,
  FormDateField,
  FormFields,
  FormFieldSpacer,
  FormFileField,
  FormNumberField,
  FormOptionsField,
  FormSelectField,
  FormSelectFieldOptions,
  FormTextAreaField,
  FormTextField,
} from '@novo-electronique/react-forms';

import { ActivityDuration } from '@common/constants/activity-duration';
import { ActivityType } from '@common/constants/activity-type';
import { acceptedFormats, maxFilesCountToUpload } from '@common/constants/global';
import { SubmissionType } from '@common/constants/submission-type';
import { IActivitySubmission } from '@common/models/submission';
import { computeActivityAmountRequested, getEncountersAllowedNumber } from '@common/utils/submission';
import {
  activityDurationValueProvider,
  activityTypeValueProvider,
  meetingTypeValueProvider,
  projectTypeValueProvider,
  skillDimensionValueProvider,
  skillValueProvider,
} from '@common/value-provider';
import { selectLocalSections } from '@modules/app/redux/selectors';
import { removeFile, uploadFile } from '@modules/app/service';
import { formatLocalSection } from '@modules/associations/service';
import { valueProviderToOptions } from '@modules/shared/components/Form';
import FilesListField from '@modules/shared/components/Form/FilesListField';
import { appendNoneOption } from '@modules/shared/components/Form/utils';
import DetailsModal from '@modules/submissions/components/DetailsModal';

const noneValue = 'none';
const priorityOrderOptions = [
  { key: '1', label: '1', value: '1' },
  { key: '2', label: '2', value: '2' },
  { key: '3', label: '3', value: '3' },
  { key: '4', label: '4', value: '4' },
];

interface IProps {
  submissionType: SubmissionType;
  editMode?: boolean;
  readonly?: boolean;
  isDisabledExceptDocuments?: boolean;
}

function ActivitySubmissionSection({
  submissionType,
  editMode = false,
  readonly = false,
  isDisabledExceptDocuments = false,
}: IProps) {
  const { t } = useTranslation();
  const { formik, ...context } = useContext(FormDataContext);
  const localSections = useSelector(selectLocalSections);
  const values = context.values as IActivitySubmission;

  const isInitialMountRef = useRef<boolean>(editMode);

  const [isTargetedSectionDefined, setIsTargetedSectionDefined] = useState<boolean>(false);
  const [isShowingCostModal, setIsShowingCostModal] = useState<boolean>();
  const [isShowingOtherInformationModal, setIsShowingOtherInformationModal] = useState<boolean>();

  const localSectionsFiltered = localSections.filter(
    (localSection) => localSection.association.id === values.association?.id,
  );

  useEffect(
    () => {
      if (isInitialMountRef.current) {
        isInitialMountRef.current = false;
        return;
      }

      if (values.section && values.section.id) {
        formik.setFieldValue('targetedSection.id', values.section.id);
      } else if (localSectionsFiltered.length === 0) {
        formik.setFieldValue('targetedSection', null);
      } else {
        formik.setFieldValue('targetedSection.id', '');
      }
    },
    editMode
      ? [values.association, values.section]
      : [values.association, values.section, isTargetedSectionDefined],
  );

  // Need another effect to determine if the property is defined. Without it, it fails to update value correctly.
  useEffect(() => setIsTargetedSectionDefined(true), [values.targetedSection]);

  useEffect(() => {
    if (version > 1) {
      formik.setFieldValue(
        'amountRequested',
        computeActivityAmountRequested(
          submissionType as SubmissionType.Pca | SubmissionType.Pp,
          values.duration as ActivityDuration,
          values.participantsCount,
          values.encountersNumber,
        ) ?? 0,
      );
    }
  }, [values.duration, values.participantsCount, values.encountersNumber]);

  const onSectionChange: FieldEvent<string> = (value, setFieldValue) => {
    if (value === noneValue) {
      setFieldValue('targetedSection', null);
    }
  };

  const version = values.version;
  const isShowingSectionSelector =
    localSectionsFiltered.length > 0 &&
    values.association &&
    values.section === null &&
    values.serviceCenter === null;

  return (
    <>
      {/* General Info */}
      <FormFields disabled={isDisabledExceptDocuments} title={t('submissionPage.projectGeneralInfo')}>
        {isShowingSectionSelector && (
          <FormSelectField
            name="targetedSection.id"
            label={t('submission.section')}
            onChange={onSectionChange}
            width={50}
          >
            <FormSelectFieldOptions
              options={appendNoneOption(
                localSectionsFiltered.map((localSection) => formatLocalSection(localSection)),
              )}
              addEmptyOption={values.targetedSection && !values.targetedSection.id}
              keepOriginalOrder
            />
          </FormSelectField>
        )}
        <FormDateField name="completionDate" label={t('submission.completionDate')} />
        {!isShowingSectionSelector && <FormFieldSpacer width={50} />}

        <FormOptionsField
          name="activityType"
          label={t('submission.type')}
          options={valueProviderToOptions(activityTypeValueProvider)}
          type="radio"
          displayStyle={DisplayStyles.Inline}
          width={50}
        />
        {values.activityType === ActivityType.Presential && (
          <FormTextField name="activityLocation" label={t('submission.activityLocation')} />
        )}
        {values.activityType !== ActivityType.Presential && <FormFieldSpacer width={50} />}

        <FormNumberField
          name="participantsCount"
          label={t('submission.participantsCount')}
          min={submissionType === SubmissionType.Pca && version > 1 ? 2 : 1}
          tooltip={!editMode && t('submission.participantsCountTooltip')}
        />
        {submissionType === SubmissionType.Pca && (
          <FormSelectField
            name="priorityOrder"
            label={t('submission.priorityOrder')}
            tooltip={!editMode && t('submission.priorityOrderTooltip')}
          >
            <FormSelectFieldOptions options={priorityOrderOptions} />
          </FormSelectField>
        )}

        {version > 1 && (
          <>
            {submissionType === SubmissionType.Pp && <FormFieldSpacer width={50} />}
            <FormSelectField
              disabled={isDisabledExceptDocuments}
              name="duration"
              label={t('submission.duration')}
            >
              <FormSelectFieldOptions
                options={valueProviderToOptions(activityDurationValueProvider).filter(
                  ({ value }) =>
                    submissionType === SubmissionType.Pca ||
                    [
                      ActivityDuration.ThreeHoursOrLess,
                      ActivityDuration.MoreThanThreeOrLessThanSixHours,
                    ].includes(value as ActivityDuration),
                )}
                keepOriginalOrder
              />
            </FormSelectField>
            {submissionType === SubmissionType.Pp && (
              <FormNumberField
                name="encountersNumber"
                label={t('submission.encountersNumber')}
                min={1}
                max={getEncountersAllowedNumber(values.duration as ActivityDuration)}
                disabled={!values.duration}
              />
            )}
            {submissionType === SubmissionType.Pca && <FormFieldSpacer width={50} />}
          </>
        )}
        <FormAmountField
          name="amountRequested"
          label={t('submission.amountRequested')}
          min={1}
          tooltip={!editMode && t('submission.amountRequestedTooltip', { version })}
          disabled={version > 1}
        />
      </FormFields>

      {/* Description */}
      <FormFields title={t('submissionPage.submissionDescription')}>
        <FormOptionsField
          name="skills"
          label={t('submission.skills')}
          description={!editMode && t('submission.skillsPrecision')}
          options={valueProviderToOptions(skillValueProvider)}
          type="checkbox"
          width={100}
          disabled={isDisabledExceptDocuments}
        />
        {submissionType === SubmissionType.Pca && version === 1 && (
          <FormSelectField
            disabled={isDisabledExceptDocuments}
            name="meetingType"
            label={t('submission.meetingType')}
          >
            <FormSelectFieldOptions options={valueProviderToOptions(meetingTypeValueProvider)} />
          </FormSelectField>
        )}
        {submissionType === SubmissionType.Pp && (
          <FormSelectField
            disabled={isDisabledExceptDocuments}
            name="projectType"
            label={t('submission.projectType')}
          >
            <FormSelectFieldOptions
              options={valueProviderToOptions(projectTypeValueProvider)}
              keepOriginalOrder
            />
          </FormSelectField>
        )}
        {version === 1 && <FormFieldSpacer width={50} />}
        <FormTextField
          width={50}
          name="theme"
          label={t('submission.theme')}
          description={!editMode && t('submission.themePrecision')}
          disabled={isDisabledExceptDocuments}
        />
        {submissionType === SubmissionType.Pca && <FormFieldSpacer width={50} />}
        <FormTextField
          disabled={isDisabledExceptDocuments}
          name="trainerName"
          label={t('submission.trainerName', { context: submissionType })}
        />
        {submissionType === SubmissionType.Pca && version === 1 && (
          <FormTextField
            disabled={isDisabledExceptDocuments}
            name="duration"
            label={t('submission.duration')}
          />
        )}
        <FormOptionsField
          name="skillDimensions"
          label={t('submission.skillDimensions')}
          description={!editMode && t('submission.skillDimensionsPrecision')}
          options={valueProviderToOptions(skillDimensionValueProvider)}
          type="checkbox"
          width={100}
          disabled={isDisabledExceptDocuments}
        />

        <FormTextAreaField
          width={100}
          name="realizationDescription"
          label={t('submission.realizationDescription')}
          description={!editMode && t('submission.realizationDescriptionPrecision')}
          disabled={isDisabledExceptDocuments}
        />
        <FormTextAreaField
          width={100}
          name="activityCostDescription"
          label={t('submission.activityCostDescription')}
          description={!editMode && t('submission.activityCostDescriptionPrecision')}
          tooltip={!editMode ? () => setIsShowingCostModal(true) : undefined}
          disabled={isDisabledExceptDocuments}
        />
        <FormTextAreaField
          width={100}
          name="activityBenefitsDescription"
          label={t('submission.activityBenefitsDescription')}
          description={!editMode && t('submission.activityBenefitsDescriptionPrecision')}
          disabled={isDisabledExceptDocuments}
        />

        {!readonly && (
          <div className="cpdHiddenForPrint">
            {!editMode && (
              <FormFileField
                name="supportingDocuments"
                label={t('submission.supportingDocuments')}
                description={t('submission.supportingDocumentsPrecision')}
                upload={uploadFile}
                remove={removeFile}
                tooltip={!editMode ? () => setIsShowingOtherInformationModal(true) : undefined}
                accept={acceptedFormats}
                maxFiles={maxFilesCountToUpload}
                width={100}
              />
            )}
            {editMode && (
              <FilesListField
                name="supportingDocuments"
                label={t('submission.supportingDocuments')}
                width={100}
              />
            )}
          </div>
        )}
      </FormFields>
      {isShowingCostModal && (
        <DetailsModal
          title={t('submissionPage.detailsModalTitle', { name: t('submission.activityCostDescription') })}
          details={{ i18nKey: 'submissionPage.activityCostDescriptionModal', context: submissionType }}
          onClose={() => setIsShowingCostModal(false)}
        />
      )}
      {isShowingOtherInformationModal && (
        <DetailsModal
          title={t('submissionPage.detailsModalTitle', { name: t('submission.supportingDocuments') })}
          details={{ i18nKey: 'submissionPage.supportingDocumentsModal' }}
          onClose={() => setIsShowingOtherInformationModal(false)}
        />
      )}
    </>
  );
}

export default ActivitySubmissionSection;
