import './Menu.scss';

import React, { ReactNode } from 'react';
import classNames from 'classnames';

export enum MenuStyle {
  Admin = 'admin',
  Site = 'default',
  Home = 'home',
}

export enum MenuType {
  Main = 'main',
  Submenu = 'submenu',
}

interface IProps {
  children: ReactNode;
  style?: MenuStyle;
  type?: MenuType;
}

function Menu({ children, style = MenuStyle.Site, type = MenuType.Main }: IProps) {
  const className = classNames('cpdMenu', {
    'cpdMenu--home': style === MenuStyle.Home,
    'cpdMenu--admin': style === MenuStyle.Admin,
    'cpdMenu--submenu': type === MenuType.Submenu,
  });
  return (
    <ul role="menu" className={className}>
      {children}
    </ul>
  );
}

export default Menu;
