import React from 'react';

import { AdminRoutePage } from '@constants/routes';
import ImportantDatesAdminPage from '@modules/settings/ImportantDatesAdminPage';
import PeriodAmountAdminPage from '@modules/settings/PeriodAmountAdminPage';
import UsersAdminPage from '@modules/settings/UsersAdminPage';
import { RouteConfig, RouteType } from '@modules/shared/route';

export default [
  { path: AdminRoutePage.Users, type: RouteType.Protected, component: <UsersAdminPage /> },
  { path: AdminRoutePage.ImportantDates, type: RouteType.Protected, component: <ImportantDatesAdminPage /> },
  { path: AdminRoutePage.PeriodAmount, type: RouteType.Protected, component: <PeriodAmountAdminPage /> },
] as RouteConfig[];
