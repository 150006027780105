import '@novo-electronique/react-blanket/dist/index.css';
import '@novo-electronique/react-tooltip/dist/index.css';
import '@novo-electronique/react-circular-progress/dist/index.css';
import '@novo-electronique/react-button/dist/index.css';
import '@novo-electronique/react-dropzone/dist/index.css';
import '@novo-electronique/react-forms/dist/index.css';
import '@novo-electronique/react-data-table/dist/index.css';
import '@novo-electronique/react-side-panel/dist/index.css';
import '@novo-electronique/react-tabs/dist/index.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import classNames from 'classnames';

import AppRouter from '@modules/app/AppRouter';
import { selectIsAppLoading, selectUser } from '@modules/app/redux/selectors';
import { refreshCache } from '@modules/app/service';
import { refreshPeriodAmounts } from '@modules/settings/service';
import { useThunkDispatch } from '@src/redux/useThunkDispatch';

function App() {
  const dispatch = useThunkDispatch();
  const isLoading = useSelector(selectIsAppLoading);
  const currentUser = useSelector(selectUser);

  useEffect(() => {
    dispatch(refreshCache());
  }, []);

  useEffect(() => {
    if (currentUser) {
      dispatch(refreshPeriodAmounts());
    }
  }, [currentUser]);

  const className = classNames('cpdApp', { 'cpdApp--is-loading': isLoading });
  return (
    <div className={className}>
      <Suspense fallback={null}>
        <ToastContainer />
        <AppRouter />
      </Suspense>
    </div>
  );
}

export default App;
