import * as statuses from 'http-status';

/**
 * Return the name of the status code. Exemples:
 * ```
 *  404 -> 'NOT_FOUND'
 *  500 -> 'INTERNAL_SERVER_ERROR'
 * ```
 */
export function getStatusName(status: number): string {
  return statuses[`${status}_NAME`].toString();
}

/**
 * Return a description of the status code. Exemples:
 * ```
 *  404 -> The requested resource could not be found...
 *  500 -> A generic error message, given when...
 * ```
 */
export function getStatusMessage(status: number): string {
  return statuses[`${status}_MESSAGE`].toString();
}
