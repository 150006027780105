import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, useDataTable } from '@novo-electronique/react-data-table';
import i18n from 'i18next';

import { IUser } from '@common/models/user';
import { userColumnDefinition } from '@modules/data-table/column-definitions';
import { DataTableSource } from '@modules/data-table/data-table-source';
import GridToolbar, { GridToolbarActionType } from '@modules/shared/components/GridToolbar';
import { AdminLayout } from '@modules/shared/components/Layout';
import UserEditionPanel from './components/UserEditionPanel';
import { saveUser } from './service';

const emptyUser: IUser = {
  firstName: '',
  lastName: '',
  email: '',
  enabled: null,
};

function UsersAdminPage() {
  const { t } = useTranslation();
  const [userToEdit, setUserToEdit] = useState<IUser>(null);

  const { gridProperties, refresh, resetFilters } = useDataTable<IUser>(
    DataTableSource.User,
    userColumnDefinition(),
    (data: IUser) => setUserToEdit(data),
  );

  const refreshGrid = () => refresh({ purge: true });

  const onSubmit = async (user: IUser) => {
    return saveUser(user).then(() => {
      setUserToEdit(null);
      refreshGrid();
    });
  };

  const onCancel = () => {
    setUserToEdit(null);
  };

  return (
    <AdminLayout>
      <header className="cpdAdminLayout__content__header">
        <h1 className="cpdAdminLayout__content__header__title">{t('usersAdminPage.title')}</h1>
        <GridToolbar
          actions={{
            [GridToolbarActionType.Add]: {
              action: () => setUserToEdit(emptyUser),
              label: i18n.t('usersAdminPage.addNewUser'),
            },
            [GridToolbarActionType.FilterOff]: resetFilters,
            [GridToolbarActionType.Refresh]: refreshGrid,
          }}
        />
      </header>
      <div className="cpdAdminLayout__content__grid">
        <DataTable {...gridProperties} />
        {userToEdit && (
          <UserEditionPanel user={userToEdit} submitHandler={onSubmit} cancelHandler={onCancel} />
        )}
      </div>
    </AdminLayout>
  );
}

export default UsersAdminPage;
