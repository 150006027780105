import './StatusBox.scss';

import React, { ReactNode } from 'react';

export enum Status {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

export enum StatusBoxStyle {
  Normal = 'normal',
  Small = 'small',
}

interface IProps {
  status: Status;
  title?: string;
  description?: string | ReactNode;
  style?: StatusBoxStyle;
}

function StatusBox({ status, title, description, style = StatusBoxStyle.Normal }: IProps) {
  return (
    <div className={`cpdStatusBox cpdStatusBox--${status} cpdStatusBox--${style}`}>
      <div className="cpdStatusBox__icon material-icons" />
      <div className="cpdStatusBox__content">
        {title && <h3 className="cpdStatusBox__title">{title}</h3>}
        {description && <p className="cpdStatusBox__description">{description}</p>}
      </div>
    </div>
  );
}

export default StatusBox;
