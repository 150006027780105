import './MenuResponsive.scss';

import React, { ReactNode, ReactNodeArray, useEffect, useState } from 'react';
import { Blanket } from '@novo-electronique/react-blanket';
import classNames from 'classnames';

import Menu, { MenuStyle } from '@modules/shared/components/Menu/Menu';

interface IProps {
  children: ReactNode | ReactNodeArray;
  style: MenuStyle.Site | MenuStyle.Home;
  mobileMaxWidth?: number;
}

function MenuResponsive({ children, style = MenuStyle.Site, mobileMaxWidth = 1050 }: IProps) {
  const computeIsMobileScreen = (): boolean => window.innerWidth <= mobileMaxWidth;

  const [isMobileScreen, setMobileScreen] = useState<boolean>(computeIsMobileScreen());
  const [isMobileMenuVisible, setMobileMenuVisible] = useState<boolean>(false);

  useEffect(() => {
    const onResize = () => setMobileScreen(computeIsMobileScreen());

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (!isMobileScreen) {
      setMobileMenuVisible(false);
    }
  }, [isMobileScreen]);

  useEffect(() => {
    document.body.style.overflow = isMobileMenuVisible ? 'hidden' : 'visible';
  }, [isMobileMenuVisible]);

  if (!isMobileScreen) {
    return <Menu style={style}>{children}</Menu>;
  }

  const className = classNames('cpdMenuResponsive', { 'cpdMenuResponsive--home': style === MenuStyle.Home });
  return (
    <div className={className}>
      {isMobileMenuVisible && (
        <Blanket onClick={() => setMobileMenuVisible(false)}>
          <div className="cpdMenuResponsive__panel" onClick={(e) => e.stopPropagation()}>
            <button
              className="cpdMenuResponsive__panel__close material-icons"
              onClick={() => setMobileMenuVisible(false)}
            >
              close
            </button>
            <Menu style={style}>{children}</Menu>
          </div>
        </Blanket>
      )}
      <button
        title="Menu"
        className="cpdMenuResponsive__control material-icons"
        onClick={() => setMobileMenuVisible(!isMobileMenuVisible)}
      >
        menu
      </button>
    </div>
  );
}

export default MenuResponsive;
