import './ActivityTableFees.scss';

import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormAmountField, FormDataContext } from '@novo-electronique/react-forms';
import classNames from 'classnames';

import { ActivityFees } from '@common/constants/activity-fees';
import { IClaim } from '@common/models/claim';
import { formatCurrency } from '@common/utils/currency';

interface IProps {
  approvedMode?: boolean;
  paymentIsCreated?: boolean;
}

const ActivityTableFees = ({ approvedMode = false, paymentIsCreated = false }: IProps) => {
  const { t } = useTranslation();
  const {
    formik: { setFieldValue },
    values,
  } = useContext(FormDataContext);

  const claim = values.claim as IClaim;
  const feesNames = Object.values(ActivityFees);
  const feesApprovedNames = feesNames.map((name) => `${name}Approved`);

  useEffect(
    () => {
      const totalApproved = feesApprovedNames.reduce((total, feesName) => total + (+claim[feesName] || 0), 0);
      setFieldValue('claim.amountApproved', totalApproved);
    },
    feesApprovedNames.map((name) => claim[name]),
  );

  const total = feesNames.reduce((total, feesName) => total + (+claim[feesName] || 0), 0);
  const className = classNames('cpdActivityTableFees', {
    'cpdActivityTableFees--approved-mode': approvedMode,
  });
  return (
    <table className={className}>
      <tbody>
        {feesNames.map((name) => (
          <tr key={name} className="cpdActivityTableFees__row">
            <td className="cpdActivityTableFees__row__name">{t(`claim.${name}`)}</td>
            <td className="cpdActivityTableFees__row__amount">
              {approvedMode ? (
                formatCurrency(claim[name])
              ) : (
                <FormAmountField name={`claim.${name}`} width={100} />
              )}
            </td>
            {approvedMode && (
              <td className="cpdActivityTableFees__row__amount">
                <FormAmountField
                  name={`claim.${name}Approved`}
                  width={100}
                  max={+claim[name]}
                  disabled={paymentIsCreated}
                />
              </td>
            )}
          </tr>
        ))}
        <tr className="cpdActivityTableFees__row cpdActivityTableFees__row--total">
          <td className="cpdActivityTableFees__row__name">{t('claim.total')}</td>
          <td className="cpdActivityTableFees__row__amount">{`${formatCurrency(total)}`}</td>
          {approvedMode && (
            <td className="cpdActivityTableFees__row__amount">{`${formatCurrency(
              claim.amountApproved || 0,
            )}`}</td>
          )}
        </tr>
      </tbody>
    </table>
  );
};

export default ActivityTableFees;
