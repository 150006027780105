import { IAssociation } from '@common/models/association';
import { ILocalSection } from '@common/models/local-section';
import { IPeriodAmount } from '@common/models/period-amount';
import { IServiceCenter } from '@common/models/service-center';
import { User } from '@common/models/user';
import { ICacheEntity } from '@modules/app/redux/state';

export enum AppActionsTypes {
  SetAuthenticationChecked = '[APP] Auth checked',
  SetIsLoading = '[APP] Set loading state',
  SetActiveUser = `[APP] Set active user`,
  DisconnectActiveUser = `[APP] Disconnect active user`,
  SetCacheLoaded = '[APP] Set cache loaded',
  SetAssociationEntity = '[APP] Set association entity',
  SetLocalSectionEntity = '[APP] Set local section entity',
  SetServiceCenterEntity = '[APP] Set service center entity',
}

interface ISetAuthenticationChecked {
  type: typeof AppActionsTypes.SetAuthenticationChecked;
  payload: null;
}

export const setAuthenticationChecked = (): ISetAuthenticationChecked => ({
  type: AppActionsTypes.SetAuthenticationChecked,
  payload: null,
});

interface ISetIsLoading {
  type: typeof AppActionsTypes.SetIsLoading;
  payload: boolean;
}

export const setAppIsLoading = (isLoading: boolean): ISetIsLoading => ({
  type: AppActionsTypes.SetIsLoading,
  payload: isLoading,
});

interface ISetActiveUser {
  type: typeof AppActionsTypes.SetActiveUser;
  payload: User;
}

export const setActiveUser = (user: User): ISetActiveUser => ({
  type: AppActionsTypes.SetActiveUser,
  payload: user,
});

interface IDisconnectActiveUser {
  type: typeof AppActionsTypes.DisconnectActiveUser;
  payload: null;
}

export const disconnectActiveUser = (): IDisconnectActiveUser => ({
  type: AppActionsTypes.DisconnectActiveUser,
  payload: null,
});

interface ISetCacheLoaded {
  type: typeof AppActionsTypes.SetCacheLoaded;
  payload: boolean;
}

export const setCacheLoaded = (isLoaded: boolean): ISetCacheLoaded => ({
  type: AppActionsTypes.SetCacheLoaded,
  payload: isLoaded,
});

interface ISetAssociationEntity {
  type: typeof AppActionsTypes.SetAssociationEntity;
  payload: ICacheEntity<IAssociation>;
}

export const setAssociationEntity = (entity: ICacheEntity<IAssociation>): ISetAssociationEntity => ({
  type: AppActionsTypes.SetAssociationEntity,
  payload: entity,
});

interface ISetLocalSectionEntity {
  type: typeof AppActionsTypes.SetLocalSectionEntity;
  payload: ICacheEntity<ILocalSection>;
}

export const setLocalSectionEntity = (entity: ICacheEntity<ILocalSection>): ISetLocalSectionEntity => ({
  type: AppActionsTypes.SetLocalSectionEntity,
  payload: entity,
});

interface ISetServiceCenterEntity {
  type: typeof AppActionsTypes.SetServiceCenterEntity;
  payload: ICacheEntity<IServiceCenter>;
}

export const setServiceCenterEntity = (entity: ICacheEntity<IServiceCenter>): ISetServiceCenterEntity => ({
  type: AppActionsTypes.SetServiceCenterEntity,
  payload: entity,
});

export type AppActions =
  | ISetAuthenticationChecked
  | ISetIsLoading
  | ISetActiveUser
  | IDisconnectActiveUser
  | ISetAssociationEntity
  | ISetLocalSectionEntity
  | ISetServiceCenterEntity
  | ISetCacheLoaded;
