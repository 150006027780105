import { Language } from '@common/constants/language';
import { formatCurrency } from '@common/utils/currency';
import { formatDate, isValid } from '@common/utils/date';

export function formatLocale(value: any, format: string, lng: string | Language) {
  if (value instanceof Date && isValid(value)) {
    return formatDate(value, format || undefined, lng as Language);
  }
  if (format === 'currency' && typeof value === 'number') {
    return formatCurrency(value, lng);
  }
  return value;
}
