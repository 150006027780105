import { IOption } from '@novo-electronique/react-forms';

import { IAssociation } from '@common/models/association';
import { ILocalSection } from '@common/models/local-section';
import { IServiceCenter } from '@common/models/service-center';
import { IEntityValue } from '@common/value-provider/entity-value';
import apiClient from '@modules/shared/api-client';

export function getAssociations(): Promise<IAssociation[]> {
  return apiClient.get('/association/all');
}

export function getLocalSections(): Promise<ILocalSection[]> {
  return apiClient.get('/local-section/all');
}

export function getServiceCenters(): Promise<IServiceCenter[]> {
  return apiClient.get('/service-center/all');
}

export function formatAssociation(association: IAssociation): IEntityValue | IOption {
  return {
    value: association.id,
    label: `${association.abbr} – ${association.name}`,
  };
}

export function formatLocalSection(section: ILocalSection): IEntityValue | IOption {
  return {
    value: section.id,
    label: section.abbr ? `${section.abbr} – ${section.name}` : section.name,
  };
}

export function formatServiceCenter(serviceCenter: IServiceCenter): IEntityValue | IOption {
  return {
    value: serviceCenter.id,
    label: `${serviceCenter.code} – ${serviceCenter.name}`,
  };
}
