import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, ButtonStyle } from '@novo-electronique/react-button';

import { SiteRoutePage } from '@constants/routes';
import { SiteLayout } from '@modules/shared/components/Layout';

function NotFoundPage() {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <SiteLayout title={t('notFoundPage.title')}>
      <p className="cpdSiteLayout__panel__content__text">
        <strong>{t('notFoundPage.subtitle')}</strong>
      </p>
      <p className="cpdSiteLayout__panel__content__text">{t('notFoundPage.description')}</p>
      <Button style={ButtonStyle.Primary} onClick={() => history.push(SiteRoutePage.Home)}>
        {t('notFoundPage.action')}
      </Button>
    </SiteLayout>
  );
}

export default NotFoundPage;
