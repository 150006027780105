import React from 'react';

interface IProps {
  onClick: () => void;
  children: string;
}

function MenuItemButton({ onClick, children }: IProps) {
  return (
    <li role="menuitem" className="cpdMenu__item">
      <button className="cpdMenu__item__button" onClick={onClick}>
        {children}
      </button>
    </li>
  );
}

export default MenuItemButton;
