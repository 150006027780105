import {
  amountPerCredit,
  maxCuCreditsAllowed,
  maxFilesCountToUpload,
  maxIfCreditsAllowed,
} from '@common/constants/global';
import { SubmissionType } from '@common/constants/submission-type';
import { University, UniversitySemester } from '@common/constants/university';
import { ICoursesClaim } from '@common/models/claim';
import { getAllowedCreditsPerYear } from '@common/utils/credits';
import { getPeriod, isSubmissionExtension } from '@common/utils/submission-period';
import { getEnumArrayValidationSchema } from '@common/utils/validation-schema';
import { IAttachment } from '../attachment';
import { getSubmissionInitialValues, getSubmissionSchema, ISubmission } from './submission';

export type CoursesSubmissionType = SubmissionType.Cu | SubmissionType.If;

export interface ICoursesSubmission extends ISubmission<CoursesSubmissionType, ICoursesClaim> {
  university: University;
  semesters: UniversitySemester[];
  programTitle: string;
  creditsCount: number;
  creditsCountAlreadyObtained: number;
  hasThesisToDefend?: boolean;
  thesisDefenseExpectedYear?: string;
  supportingDocuments?: Partial<IAttachment>[];
}

export const getIfSubmissionSchema = (
  Yup,
  version,
  includeAllProperties: boolean,
  isAuthenticated: boolean,
) =>
  Yup.object().shape({
    ...getSubmissionSchema(Yup, version, includeAllProperties).fields,
    type: Yup.string().oneOf([SubmissionType.Cu, SubmissionType.If]).required(),
    university: Yup.string().oneOf(Object.values(University)).required(),
    semesters: getEnumArrayValidationSchema(Yup, UniversitySemester).min(1).required(),
    creditsCountAlreadyObtained: Yup.number().min(0).max(maxIfCreditsAllowed).required(),
    creditsCount: Yup.number()
      .when(['type', 'creditsCountAlreadyObtained'], (type, creditsCountAlreadyObtained) => {
        const allowedCredits = getAllowedCreditsPerYear(type, creditsCountAlreadyObtained);
        return Yup.number().min(Math.min(allowedCredits, 1)).max(allowedCredits);
      })
      .required(),
    programTitle: Yup.string().max(150).required(),
    amountRequested: Yup.number().min(amountPerCredit),
    supportingDocuments: getSubmissionExtensionSchema(Yup, isAuthenticated).max(maxFilesCountToUpload),
  });

export const getCuSubmissionSchema = (
  Yup,
  version: number,
  includeAllProperties: boolean,
  isAuthenticated: boolean,
) =>
  Yup.object().shape({
    ...getIfSubmissionSchema(Yup, version, includeAllProperties, isAuthenticated).fields,
    creditsCountAlreadyObtained: Yup.number().min(0).max(maxCuCreditsAllowed).required(),

    ...(version === 1 && {
      hasThesisToDefend: Yup.boolean().required(),
      thesisDefenseExpectedYear: Yup.mixed()
        .when(['hasThesisToDefend'], {
          is: true,
          then: Yup.string().min(4).max(4).required(),
        })
        .meta({ required: true }),
    }),
  });

export const getSubmissionExtensionSchema = (Yup, isAuthenticated: boolean) => {
  const isExtension = isSubmissionExtension(getPeriod());
  return isExtension && !isAuthenticated ? Yup.array().min(1).required() : Yup.array().min(0);
};

export const getCoursesInitialValues = (
  currentValues: Partial<ICoursesSubmission> = {},
): ICoursesSubmission => ({
  ...(getSubmissionInitialValues() as ISubmission<CoursesSubmissionType, ICoursesClaim>),
  university: '' as University,
  semesters: [],
  programTitle: '',
  creditsCount: null,
  creditsCountAlreadyObtained: null,
  hasThesisToDefend: null,
  thesisDefenseExpectedYear: null,
  supportingDocuments: [],
  ...currentValues,
});
