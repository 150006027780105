import React from 'react';
import { useTranslation } from 'react-i18next';

import { AdminRoutePage } from '@constants/routes';
import Menu, { MenuItemLink, MenuStyle, MenuType } from '@modules/shared/components/Menu';

function SettingsMenu() {
  const { t } = useTranslation();

  return (
    <Menu style={MenuStyle.Admin} type={MenuType.Submenu}>
      <MenuItemLink to={AdminRoutePage.Users}>{t('adminSettingsMenu.users')}</MenuItemLink>
      <MenuItemLink to={AdminRoutePage.ImportantDates}>{t('adminSettingsMenu.importantDates')}</MenuItemLink>
      <MenuItemLink to={AdminRoutePage.PeriodAmount}>{t('adminSettingsMenu.periodAmount')}</MenuItemLink>
    </Menu>
  );
}

export default SettingsMenu;
