import {
  claimActivityDates,
  claimDocumentsExtensionActivityDates,
  firstSubmissionYear,
  ifActivityExtensionDates,
  newPrincipalSubmissionDate,
  submissionActivityDates,
  submissionDocumentsExtensionActivityDates,
  submissionStartPeriodDate as startPeriodDate,
  timeZone as defaultTimeZone,
} from '../constants/global';
import { PeriodType } from '../constants/period-type';
import { SubmissionActivity, SubmissionActivityStatus } from '../constants/submission-activity';
import { SubmissionType } from '../constants/submission-type';
import { ISubmissionPeriod, PeriodDate } from '../models/submission-period';
import { zonedTimeToUtc } from '../utils/date';
import { range } from './array';

const yearFormatter = Intl.DateTimeFormat('en-US', { timeZone: defaultTimeZone, year: 'numeric' });

export function getPeriod(date?: Date): ISubmissionPeriod;
export function getPeriod(periodId: string): ISubmissionPeriod;
export function getPeriod(param: string | Date = new Date()): ISubmissionPeriod {
  const years = param instanceof Date ? getYearsFromDate(param) : getYearsFromPeriodId(param);

  const getDates = ([date1, date2]: [PeriodDate, PeriodDate]): [Date, Date] => [
    zonedTimeToUtc(new Date(years[date1.year], date1.month, date1.day), defaultTimeZone),
    zonedTimeToUtc(new Date(years[date2.year], date2.month, date2.day, 23, 59, 59, 999), defaultTimeZone),
  ];

  return {
    years,
    id: years.join(''),
    dates: {
      [SubmissionActivity.Submission]: getDates(submissionActivityDates),
      [SubmissionActivity.Claim]: getDates(claimActivityDates),
      [SubmissionActivity.ClaimDocumentsExtension]: getDates(claimDocumentsExtensionActivityDates),
      [SubmissionActivity.SubmissionDocumentsExtension]: getDates(submissionDocumentsExtensionActivityDates),
    },
    extensions: {
      [SubmissionActivity.Submission]: { [SubmissionType.If]: getDates(ifActivityExtensionDates) },
    },
    newPrincipalsDates: {
      [SubmissionActivity.Submission]: getDates(newPrincipalSubmissionDate),
    },
  };
}

export function getPeriodByEndYear(selectedYear: number) {
  if (selectedYear <= firstSubmissionYear) {
    throw Error('There are no periods prior to the first submission year');
  }

  const firstDateOfSelectedYear = zonedTimeToUtc(new Date(selectedYear, 0), defaultTimeZone);
  return getPeriod(firstDateOfSelectedYear);
}

export function getPeriods(endDate = new Date()): ISubmissionPeriod[] {
  const startYear = firstSubmissionYear;
  const endYear = getYearsFromDate(endDate)[0];

  return range(endYear - startYear + 1).map((d, i) => {
    const year = startYear + i;
    return getPeriod(`${year}${year + 1}`);
  });
}

export function formatPeriod(period: ISubmissionPeriod): string;
export function formatPeriod(periodId: string): string;
export function formatPeriod(param: string | ISubmissionPeriod): string {
  const years = typeof param === 'string' ? getYearsFromPeriodId(param) : param.years;
  return years.join('-');
}

export function getPeriodType(periodId: string, currentPeriod: ISubmissionPeriod): PeriodType {
  const [year] = getPeriod(periodId).years;
  const [currentYear] = currentPeriod.years;

  if (year < currentYear) {
    return PeriodType.Past;
  } else if (year > currentYear) {
    return PeriodType.Forthcoming;
  } else {
    return PeriodType.Ongoing;
  }
}

/**
 * @note When no type is specified, the activity is allowed if the date is contained in an extension.
 */
export function getActivityStatus(
  activity: SubmissionActivity,
  period: ISubmissionPeriod,
  types?: SubmissionType | SubmissionType[],
  date: Date = new Date(),
): SubmissionActivityStatus {
  const getRangeStatus = (date: Date, [date1, date2]: [Date, Date]): SubmissionActivityStatus => {
    if (date.getTime() < date1.getTime()) {
      return SubmissionActivityStatus.Forthcoming;
    } else if (date.getTime() > date2.getTime()) {
      return SubmissionActivityStatus.Past;
    } else {
      return SubmissionActivityStatus.Ongoing;
    }
  };

  const datesStatus = getRangeStatus(date, period.dates[activity]);
  if (datesStatus === SubmissionActivityStatus.Ongoing) {
    return SubmissionActivityStatus.Ongoing;
  }

  let extensionsStatuses = [];
  const activityWithExtensions =
    period.extensions[Object.keys(period.extensions).find((currentActivity) => currentActivity === activity)];

  if (activityWithExtensions) {
    extensionsStatuses = Object.keys(activityWithExtensions)
      .filter(
        (type) => !types || (Array.isArray(types) ? types.includes(type as SubmissionType) : type === types),
      )
      .map((type) => getRangeStatus(date, activityWithExtensions[type]));
  }

  return extensionsStatuses.some((status) => status === SubmissionActivityStatus.Ongoing)
    ? SubmissionActivityStatus.Ongoing
    : datesStatus;
}

export function isActivityAllowed(
  activity: SubmissionActivity,
  period: ISubmissionPeriod,
  types?: SubmissionType | SubmissionType[],
  date: Date = new Date(),
): boolean {
  return getActivityStatus(activity, period, types, date) === SubmissionActivityStatus.Ongoing;
}

export function isSubmissionExtension(currentPeriod: ISubmissionPeriod, date: Date = new Date()): boolean {
  const isStandardSubmissionAllowed = isActivityAllowed(
    SubmissionActivity.Submission,
    currentPeriod,
    [SubmissionType.Cu, SubmissionType.Pca, SubmissionType.Pp],
    date,
  );
  const isIfActivityAllowed = isActivityAllowed(
    SubmissionActivity.Submission,
    currentPeriod,
    SubmissionType.If,
    date,
  );

  return !isStandardSubmissionAllowed && isIfActivityAllowed;
}

export function getYearsFromPeriodId(periodId: string): [number, number] {
  return [+periodId.substring(0, 4), +periodId.substring(4)];
}

export function isDuringSubmissionDocumentExtension(period: ISubmissionPeriod): boolean {
  const date = new Date();
  const date1 = period.dates['submission-extension'][0];
  const date2 = period.dates['submission-extension'][1];
  return date.getTime() > date1.getTime() && date.getTime() < date2.getTime();
}

function getYearsFromDate(date: Date): [number, number] {
  const { day, month } = startPeriodDate;
  const fullYear = +yearFormatter.format(date);

  const year =
    date.getMonth() > month || (date.getMonth() === month && date.getDate() >= day) ? fullYear : fullYear - 1;

  return [year, year + 1];
}
