import './FileViewer.scss';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFileUploaded } from '@novo-electronique/react-dropzone';
import isImage from 'is-image';

import { IAttachment } from '@common/models/attachment';
import { getFileUrl } from '@modules/app/service';
import Initializing from '@modules/shared/components/Initializing';
import Modal from '@modules/shared/components/Modal';
import ImageVisualizer from './ImageVisualizer';

interface IProps {
  file: IAttachment | IFileUploaded;
  onClose?: () => void;
}

function FileViewer({ file, onClose }: IProps) {
  const { t } = useTranslation();
  const [url, setUrl] = useState<string>(null);
  const [hasError, setError] = useState<boolean>(false);

  useEffect(() => {
    getFileUrl(file.id)
      .then((url) => setUrl(url))
      .catch((e) => {
        console.error(e);
        setError(true);
      });
  }, []);

  const isSuccessfully = url && !hasError;
  const isImageFile = isImage(file.name);
  return (
    <Modal title={file.name} onClose={onClose} isHost={isSuccessfully} maximize>
      {!url && !hasError && <Initializing />}
      {hasError && <p>{t('general.error')}</p>}
      {isSuccessfully && isImageFile && <ImageVisualizer imageUrl={url} />}
      {isSuccessfully && !isImageFile && <iframe className="cpdFileViewer" src={url} />}
    </Modal>
  );
}

export default FileViewer;
