import './CurrentUserMenu.scss';

import React, { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AdminRoutePage } from '@constants/routes';
import { selectUser } from '@modules/app/redux/selectors';
import { signOut } from '@modules/auth/service';
import { useThunkDispatch } from '@src/redux/useThunkDispatch';

export default function CurrentUserMenu() {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();
  const history = useHistory();

  const user = useSelector(selectUser);
  const [isShowingActions, setShowingActions] = useState(false);

  useEffect(() => {
    const closeMenu = () => setShowingActions(false);
    window.addEventListener('click', closeMenu);
    return () => {
      window.removeEventListener('click', closeMenu);
    };
  }, []);

  const toggleMenu = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowingActions(!isShowingActions);
  };

  if (!user) {
    return null;
  }
  return (
    <article className="cpdCurrentUserMenu">
      <button className="cpdCurrentUserMenu__userInfo" onClick={toggleMenu}>
        <span className="material-icons cpdCurrentUserMenu__userInfo__profile">account_circle</span>
        {user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email}

        <span className="material-icons cpdCurrentUserMenu__userInfo__chevronDown">expand_more</span>
      </button>
      {isShowingActions && (
        <ul role="menu" className="cpdCurrentUserMenu__actions">
          <li className="cpdCurrentUserMenu__actions__item">
            <button
              className="cpdCurrentUserMenu__actions__item__button"
              onClick={() => dispatch(signOut()).then(() => history.push(AdminRoutePage.Authentication))}
            >
              {t(`currentUserMenu.signOut`)}
            </button>
          </li>
        </ul>
      )}
    </article>
  );
}
