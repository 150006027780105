import React from 'react';
import { useTranslation } from 'react-i18next';

import { AdminRoutePage, AdminRouteSection } from '@constants/routes';
import Menu, { MenuItemLink, MenuStyle } from '@modules/shared/components/Menu';

function AdminMenu() {
  const { t } = useTranslation();

  return (
    <Menu style={MenuStyle.Admin}>
      <MenuItemLink to={AdminRoutePage.Submissions} section={AdminRouteSection.Submissions}>
        {t('adminMenu.submissions')}
      </MenuItemLink>
      {/*<MenuItemLink to={AdminRoutePage.Associations} section={AdminRouteSection.Associations}>
        {t('adminMenu.associations')}
      </MenuItemLink>*/}
      <MenuItemLink to={AdminRoutePage.Users} section={AdminRouteSection.Settings}>
        {t('adminMenu.settings')}
      </MenuItemLink>
    </Menu>
  );
}

export default AdminMenu;
