import { ActivityDuration } from '@common/constants/activity-duration';
import { ActivityType } from '@common/constants/activity-type';
import { maxFilesCountToUpload } from '@common/constants/global';
import { MeetingType } from '@common/constants/meeting-type';
import { ProjectType } from '@common/constants/project-type';
import { Skill } from '@common/constants/skill';
import { SkillDimension } from '@common/constants/skill-dimension';
import { SubmissionType } from '@common/constants/submission-type';
import { IAttachment } from '@common/models/attachment';
import { IActivityClaim } from '@common/models/claim';
import { ILocalSection } from '@common/models/local-section';
import { getEncountersAllowedNumber } from '@common/utils/submission';
import { getEnumArrayValidationSchema } from '@common/utils/validation-schema';
import { getSubmissionInitialValues, getSubmissionSchema, ISubmission } from './submission';

export type ActivitySubmissionType = SubmissionType.Pca | SubmissionType.Pp;

export interface IActivitySubmission extends ISubmission<ActivitySubmissionType, IActivityClaim> {
  priorityOrder?: number;
  participantsCount: number;
  encountersNumber?: number;
  skillDimensions: SkillDimension[];
  activityType: ActivityType;
  activityLocation?: string;
  meetingType?: MeetingType;
  projectType?: ProjectType;
  completionDate: Date;

  theme: string;
  duration?: string | ActivityDuration;
  trainerName: string;
  skills: Skill[];

  activityCostDescription: string;
  activityBenefitsDescription: string;
  realizationDescription: string;
  supportingDocuments?: Partial<IAttachment>[];

  targetedSection?: Partial<ILocalSection>;
}

const getActivitySubmissionSchema = (Yup, version: number, includeAllProperties: boolean) =>
  Yup.object().shape({
    ...getSubmissionSchema(Yup, version, includeAllProperties).fields,
    type: Yup.string().oneOf([SubmissionType.Pca, SubmissionType.Pp]).required(),
    amountRequested: Yup.number().min(1).required(),
    participantsCount: Yup.number().min(1).required(),
    activityType: Yup.string().oneOf(Object.values(ActivityType)).required(),
    activityLocation: Yup.mixed()
      .when('activityType', (type) =>
        type === ActivityType.Presential ? Yup.string().max(150).required() : Yup.mixed(),
      )
      .meta({ required: true }),
    completionDate: Yup.date().required(),
    skillDimensions: getEnumArrayValidationSchema(Yup, SkillDimension).min(1).required(),
    theme: Yup.string().max(150).required(),
    trainerName: Yup.string().max(150).required(),
    skills: getEnumArrayValidationSchema(Yup, Skill).min(1).max(4).required(),
    activityCostDescription: Yup.string().required(),
    activityBenefitsDescription: Yup.string().required(),
    realizationDescription: Yup.string().required(),
    supportingDocuments: Yup.array().max(maxFilesCountToUpload),
    targetedSection: Yup.object({
      id: Yup.string().required(),
    })
      .notRequired()
      .nullable(),

    ...(version > 1 && {
      amountRequested: Yup.number().min(1),
      supportingDocuments: Yup.array().min(1).max(maxFilesCountToUpload).required(),
    }),
  });

export const getPpActivitySubmissionSchema = (Yup, version: number, includeAllProperties: boolean) =>
  Yup.object().shape({
    ...getActivitySubmissionSchema(Yup, version, includeAllProperties).fields,
    projectType: Yup.string().required(),

    ...(version > 1 && {
      duration: Yup.string()
        .oneOf([ActivityDuration.ThreeHoursOrLess, ActivityDuration.MoreThanThreeOrLessThanSixHours])
        .required(),
      encountersNumber: Yup.number()
        .when(['duration'], (duration: ActivityDuration) => {
          return Yup.number().min(1).max(getEncountersAllowedNumber(duration));
        })
        .required(),
    }),
  });

export const getPcaActivitySubmissionSchema = (Yup, version: number, includeAllProperties: boolean) =>
  Yup.object().shape({
    ...getActivitySubmissionSchema(Yup, version, includeAllProperties).fields,
    meetingType: Yup.string().required(),
    duration: Yup.string().max(15).required(),
    priorityOrder: Yup.number().min(0).required(),

    ...(version > 1 && {
      participantsCount: Yup.number().min(2).required(),
      meetingType: undefined,
      duration: Yup.string().oneOf(Object.values(ActivityDuration)).required(),
    }),
  });

export const getActivityInitialValues = (
  currentValues: Partial<IActivitySubmission> = {},
): IActivitySubmission => ({
  ...(getSubmissionInitialValues() as IActivitySubmission),
  priorityOrder: null,
  participantsCount: '' as any,
  skillDimensions: [],
  activityType: '' as ActivityType,
  activityLocation: '',
  meetingType: '' as MeetingType,
  projectType: '' as ProjectType,
  completionDate: null,

  theme: '',
  duration: '',
  trainerName: '',
  skills: [],

  activityCostDescription: '',
  activityBenefitsDescription: '',
  realizationDescription: '',
  supportingDocuments: [],

  targetedSection: { id: '' },

  ...currentValues,
});
