import React, { Fragment, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { SubmissionType } from '@common/constants/submission-type';
import { SiteLayout } from '@modules/shared/components/Layout';

function AboutPage() {
  const { t } = useTranslation();

  const renderContactLine = (submissionTypes: SubmissionType[], email: string) => {
    return (
      <li className="cpdSiteLayout__panel__content__list__item">
        {submissionTypes
          .map<ReactNode>((type) => (
            <Fragment key={type}>
              {t(`submissionType.${type}.abbr`)} ({t(`submissionType.${type}.title`)})
            </Fragment>
          ))
          .reduce((prev, current) => [prev, ` ${t(`general.and`)} `, current])}
         :{' '}
        <a className="cpdSiteLayout__panel__content__link" href={`mailto:${email}`}>
          {email}
        </a>
      </li>
    );
  };

  return (
    <SiteLayout title={t('contactPage.title')} decoration>
      <p className="cpdSiteLayout__panel__content__text">
        <Trans
          i18nKey="contactPage.description"
          components={{
            a: <a className="cpdSiteLayout__panel__content__link" href="mailto:info@cpd.quebec" />,
          }}
        />
      </p>
      <p className="cpdSiteLayout__panel__content__text">{t('contactPage.moreInfos')}</p>
      <ul className="cpdSiteLayout__panel__content__list">
        {renderContactLine([SubmissionType.Cu, SubmissionType.If], 'info@cpd.quebec')}
        {renderContactLine([SubmissionType.Pca, SubmissionType.Pp], 'responsableCPD@cpd.quebec')}
      </ul>
    </SiteLayout>
  );
}

export default AboutPage;
