import i18n from 'i18next';

import { Language } from '@common/constants/language';

export const formatCurrency = (
  amount: number,
  locale: string = i18n.language as Language,
  currency: string = 'CAD',
): string => {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: currency ? 'currency' : undefined,
    currency,
  })
    .format(amount)
    .replace('CA', '');
};

export const accountantRound = (amount: number, decimalPlaces: number = 2): number => {
  const d = decimalPlaces || 0;
  const m = Math.pow(10, d);
  const n = +(d ? amount * m : amount).toFixed(8); // Avoid rounding errors
  const i = Math.floor(n),
    f = n - i;
  const e = 1e-8; // Allow for rounding errors in f
  const r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);
  return d ? r / m : r;
};
