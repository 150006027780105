import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  FormDataContext,
  FormEmailField,
  FormFields,
  FormPhoneField,
  FormSelectField,
  FormSelectFieldOptions,
  FormTextField,
} from '@novo-electronique/react-forms';

import { SubmissionType } from '@common/constants/submission-type';
import { IServiceCenter } from '@common/models/service-center';
import { ISubmission } from '@common/models/submission';
import { roleValueProvider, submissionTypeValueProvider } from '@common/value-provider';
import { selectAssociations, selectLocalSections, selectServiceCenters } from '@modules/app/redux/selectors';
import { formatAssociation, formatLocalSection, formatServiceCenter } from '@modules/associations/service';
import { valueProviderToOptions } from '@modules/shared/components/Form';
import { appendNoneOption } from '@modules/shared/components/Form/utils';

const noneValue = 'none';
interface IProps {
  editMode?: boolean;
  isDisabled?: boolean;
}

const isValueDefined = <T extends { id?: string }>(value: T): boolean => Boolean(value && value.id);
const isValueUndefined = <T extends { id?: string }>(value: T): boolean =>
  value !== null && !Boolean(value && value.id);

function SubmissionGeneralInformationSection({ editMode = false, isDisabled = false }: IProps) {
  const { t } = useTranslation();
  const associations = useSelector(selectAssociations);
  const localSections = useSelector(selectLocalSections);
  const serviceCenters = useSelector(selectServiceCenters);
  const {
    association: activeAssociation,
    section: activeLocalSection,
    serviceCenter: activeServiceCenter,
  } = useContext(FormDataContext).values as ISubmission;

  const getAllowedDomain = (serviceCentersAllowed: IServiceCenter[]): string => {
    if (isValueDefined(activeServiceCenter)) {
      const serviceCenter = serviceCenters.find(
        (serviceCenter) => serviceCenter.id === activeServiceCenter?.id,
      );
      return serviceCenter?.domains[0];
    } else if (
      activeServiceCenter === null ||
      (serviceCentersAllowed.length === 0 && activeLocalSection === null)
    ) {
      const association = associations.find((association) => association.id === activeAssociation?.id);
      return association?.domains[0];
    } else {
      return '';
    }
  };

  const onAssociationChange = (
    associationId: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ) => {
    setFieldValue('section.id', '', false);
    setFieldValue('serviceCenter.id', '', false);
    setFieldValue('email', '', false);
  };

  const onSectionChange = (
    sectionId: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ) => {
    const localSection =
      sectionId === noneValue ? null : localSections.find((localSection) => localSection.id === sectionId);

    if (localSection === null) {
      setFieldValue('section', null, false);
      setFieldValue('serviceCenter', null, false);
    } else {
      setFieldValue('serviceCenter.id', '', false);
    }
    setFieldValue('email', '', false);
  };

  const onServiceCenterChange = (
    serviceCenterId: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ) => {
    const serviceCenter =
      serviceCenterId === noneValue
        ? null
        : serviceCenters.find((serviceCenter) => serviceCenter.id === serviceCenterId);

    if (serviceCenter === null || (serviceCenter && serviceCenter.sections.length === 0)) {
      setFieldValue('section', null, false);

      if (serviceCenter === null) {
        setFieldValue('serviceCenter', null, false);
      }
    }
    setFieldValue('email', '', false);
  };

  const localSectionsFiltered = localSections.filter(
    (section) => section.association.id === activeAssociation?.id,
  );
  const serviceCentersFiltered = serviceCenters.filter((serviceCenter) => {
    const hasValidAssociation = serviceCenter.associations.some(
      (association) => association.id === activeAssociation?.id,
    );
    const hasValidSection =
      localSectionsFiltered.length === 0 ||
      (activeLocalSection === null && serviceCenter.sections.length === 0) ||
      serviceCenter.sections.some((section) => section.id === activeLocalSection?.id);

    return hasValidAssociation && hasValidSection;
  });

  const isLocalSectionUndefined = isValueUndefined(activeLocalSection);
  const serviceCentersOptions = serviceCentersFiltered.map((serviceCenter) =>
    formatServiceCenter(serviceCenter),
  );
  return (
    <FormFields disabled={isDisabled} title={t('submissionPage.userGeneralInfo')}>
      <FormTextField name="firstName" label={t('submission.firstName')} />
      <FormTextField name="lastName" label={t('submission.lastName')} />
      <FormSelectField
        name="role"
        label={t('submission.role')}
        description={!editMode && t('submission.rolePrecision')}
        width={100}
      >
        <FormSelectFieldOptions options={valueProviderToOptions(roleValueProvider)} keepOriginalOrder />
      </FormSelectField>

      <div className="cpdVisibleForPrint">
        <FormSelectField name="type" label={t('submissionPage.submissionType')}>
          <FormSelectFieldOptions
            options={valueProviderToOptions(submissionTypeValueProvider)}
            keepOriginalOrder
            addEmptyOption={false}
          />
        </FormSelectField>
      </div>

      <FormSelectField
        name="association.id"
        label={t('submission.association')}
        description={!editMode && t('submission.associationDescription')}
        width={100}
        onChange={onAssociationChange}
      >
        <FormSelectFieldOptions options={associations.map((association) => formatAssociation(association))} />
      </FormSelectField>
      {localSectionsFiltered.length > 0 && (
        <FormSelectField
          name="section.id"
          label={t('submission.section')}
          disabled={localSectionsFiltered.length === 0}
          width={100}
          onChange={onSectionChange}
        >
          <FormSelectFieldOptions
            options={appendNoneOption(
              localSectionsFiltered.map((section) => formatLocalSection(section)),
              noneValue,
            )}
            addEmptyOption={isLocalSectionUndefined}
            keepOriginalOrder
          />
        </FormSelectField>
      )}
      {(isLocalSectionUndefined || serviceCentersFiltered.length > 0) && (
        <FormSelectField
          name="serviceCenter.id"
          label={t('submission.serviceCenter')}
          width={100}
          onChange={onServiceCenterChange}
          disabled={
            isValueUndefined(activeAssociation) ||
            (localSectionsFiltered.length > 0 && isLocalSectionUndefined)
          }
        >
          <FormSelectFieldOptions
            options={
              activeLocalSection === null || localSectionsFiltered.length === 0
                ? appendNoneOption(serviceCentersOptions, noneValue)
                : serviceCentersOptions
            }
            addEmptyOption={isValueUndefined(activeServiceCenter)}
            keepOriginalOrder
          />
        </FormSelectField>
      )}

      <FormPhoneField name="phoneNumber" label={t('submission.phoneNumber')} width={37.5} />
      <FormTextField name="phoneNumberExt" label={t('submission.phoneNumberExt')} width={12.5} />
      {editMode && <FormTextField name="email" type="email" label={t('submission.email')} />}
      {!editMode && (
        <FormEmailField
          domain={getAllowedDomain(serviceCentersFiltered)}
          name="email"
          label={t('submission.email')}
        />
      )}
    </FormFields>
  );
}

export default SubmissionGeneralInformationSection;
