import { ReactNode } from 'react';

import { AdminRoutePage, SiteRoutePage } from '@constants/routes';

export enum RouteType {
  Normal = 'normal',
  Protected = 'protected',
  Unauthorized = 'unauthorized',
}

export type RouteConfig = { path: SiteRoutePage | AdminRoutePage; component: ReactNode; type?: RouteType };
