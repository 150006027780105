import './FilesListField.scss';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonSize, ButtonStyle } from '@novo-electronique/react-button';
import { FileUploaded, FileUploadedAction, IFileUploaded } from '@novo-electronique/react-dropzone';
import {
  FieldWidth,
  FormFieldLabel,
  FormFields,
  FormFileField,
  useFormField,
} from '@novo-electronique/react-forms';

import { acceptedFormats, maxFilesCountToUpload } from '@common/constants/global';
import { downloadFile, uploadFile } from '@modules/app/service';
import FileViewer from '@modules/shared/components/FileViewer';
import Tooltip from '@modules/shared/components/Tooltip';
import Modal from '../Modal';

interface IProps {
  name?: string;
  label?: string;
  width?: FieldWidth;
  accept?: string[];
  maxFiles?: number;
}

function FilesListField({
  name,
  label,
  width,
  accept = acceptedFormats,
  maxFiles = maxFilesCountToUpload,
}: IProps) {
  const { t } = useTranslation();
  const { value: filesUploaded, fieldProps, required, disabled } = useFormField<IFileUploaded[]>(name, width);

  const [selectedFile, setSelectedFile] = useState<IFileUploaded>(undefined);
  const [isModifyingFiles, setIsModifyingFiles] = useState(false);

  const onCloseDialog = () => setIsModifyingFiles(false);

  return (
    <div {...fieldProps} className={`${fieldProps.className} novoFormField--files-list`}>
      {label && (
        <div className="novoFormField__name">
          <FormFieldLabel required={required}>{label}</FormFieldLabel>
        </div>
      )}
      {(!filesUploaded || filesUploaded.length === 0) && <p>{t('general.forms.filesList.empty')}</p>}
      {filesUploaded && filesUploaded.length > 0 && (
        <ul className="cpdFilesList">
          {filesUploaded.map((file) => (
            <li key={file.id} className="cpdFilesList__item" title={t('general.forms.filesList.view')}>
              <FileUploaded
                labelsNamespace="general.forms.dropzone"
                file={file}
                disabled={disabled}
                actions={{
                  [FileUploadedAction.Download]: (file) => downloadFile(file.id),
                }}
                onClick={(file) => setSelectedFile(file)}
              />
            </li>
          ))}
        </ul>
      )}

      <Tooltip text={t('general.admin.edit')}>
        <Button size={ButtonSize.Small} style={ButtonStyle.Normal} onClick={() => setIsModifyingFiles(true)}>
          <span className={`material-icons cpdIcon cpdIcon--edit`} />
        </Button>
      </Tooltip>

      {selectedFile && <FileViewer file={selectedFile} onClose={() => setSelectedFile(undefined)} />}
      {isModifyingFiles && (
        <Modal
          title={t('claimPage.supportingDocumentsSection')}
          footer={
            <Button size={ButtonSize.Small} style={ButtonStyle.Primary} onClick={onCloseDialog}>
              {t('general.admin.ok')}
            </Button>
          }
          onClose={onCloseDialog}
        >
          <div className="cpdFilesEdit">
            <FormFields required>
              <FormFileField
                name={name}
                upload={uploadFile}
                accept={accept}
                maxFiles={maxFiles}
                width={100}
              />
            </FormFields>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default FilesListField;
