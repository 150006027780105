import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  DisplayStyles,
  FieldEvent,
  FormDataContext,
  FormFields,
  FormFieldSpacer,
  FormFileField,
  FormNumberField,
  FormOptionsField,
  FormSelectField,
  FormSelectFieldOptions,
  FormTextField,
} from '@novo-electronique/react-forms';

import {
  acceptedFormats,
  amountPerCredit,
  maxCuCreditsAllowed,
  maxFilesCountToUpload,
  maxIfCreditsAllowed,
} from '@common/constants/global';
import { SubmissionType } from '@common/constants/submission-type';
import { ICoursesSubmission } from '@common/models/submission';
import { computeAmountPerCredit, getAllowedCreditsPerYear } from '@common/utils/credits';
import { formatCurrency } from '@common/utils/currency';
import { universitySemesterValueProvider, universityValueProvider } from '@common/value-provider';
import { selectCurrentPeriod, selectIsSubmissionExtension } from '@modules/app/redux/selectors';
import { removeFile, uploadFile } from '@modules/app/service';
import { valueProviderToOptions } from '@modules/shared/components/Form';
import FilesListField from '@modules/shared/components/Form/FilesListField';

interface IProps {
  submissionType: SubmissionType.If | SubmissionType.Cu;
  editMode?: boolean;
  readonly?: boolean;
}

function CoursesSubmissionSection({ submissionType, editMode = false, readonly = false }: IProps) {
  const { t } = useTranslation();
  const period = useSelector(selectCurrentPeriod);
  const isSubmissionExtension = useSelector(selectIsSubmissionExtension);

  const { formik, ...context } = useContext(FormDataContext);
  const values = context.values as ICoursesSubmission;
  const { hasThesisToDefend, creditsCountAlreadyObtained, creditsCount } = values;

  const creditsCountAlreadyObtainedChange: FieldEvent<number> = (value, setFieldValue) =>
    setFieldValue('amountRequested', computeAmountPerCredit(submissionType, creditsCount, value));

  const onCreditsCountChange: FieldEvent<number> = (value, setFieldValue) =>
    setFieldValue(
      'amountRequested',
      computeAmountPerCredit(submissionType, value, creditsCountAlreadyObtained),
    );

  const onThesisToDefendValueChange = (
    value: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ) => {
    const hasToDefend = value === true.toString();
    if (!hasToDefend) {
      setFieldValue('thesisDefenseExpectedYear', '', false);
    }
  };

  const version = values.version;
  const maxCreditsCount = getAllowedCreditsPerYear(submissionType, creditsCountAlreadyObtained);
  const options = [true, false].map((value) => ({
    label: t('general.boolean', { context: value.toString() }),
    value: value.toString(),
  }));
  return (
    <FormFields title={t('submissionPage.projectGeneralInfo')}>
      <FormOptionsField
        name="semesters"
        label={t('submission.semesters', { context: version })}
        tooltip={!editMode && t('submission.semestersTooltip')}
        options={valueProviderToOptions(universitySemesterValueProvider)}
        type="checkbox"
        displayStyle={DisplayStyles.Inline}
      />
      <FormFieldSpacer width={50} />
      <FormSelectField name="university" label={t('submission.universityName')}>
        <FormSelectFieldOptions options={valueProviderToOptions(universityValueProvider)} keepOriginalOrder />
      </FormSelectField>
      <FormTextField name="programTitle" label={t('submission.programTitle', { context: submissionType })} />

      <FormNumberField
        name="creditsCountAlreadyObtained"
        label={t('submission.creditsCountAlreadyObtained')}
        onChange={creditsCountAlreadyObtainedChange}
        min={0}
        max={submissionType === SubmissionType.If ? maxIfCreditsAllowed : maxCuCreditsAllowed}
        width={33}
      />
      <FormNumberField
        name="creditsCount"
        label={t('submission.creditsCount')}
        onChange={onCreditsCountChange}
        min={Math.min(1, maxCreditsCount)}
        max={maxCreditsCount}
        width={33}
      />
      <FormTextField
        disabled={true}
        name="amountRequested"
        label={t('submission.amountRequested', { context: submissionType, amount: amountPerCredit })}
        formatter={(value) => (isFinite(+value) ? formatCurrency(+value) : '')}
        width={33}
      />
      {version === 1 && submissionType === SubmissionType.Cu && (
        <>
          <FormOptionsField
            label={t('submission.hasThesisToDefend')}
            name="hasThesisToDefend"
            type="radio"
            options={options}
            displayStyle={DisplayStyles.Inline}
            width={100}
            onChange={onThesisToDefendValueChange}
          />
          {hasThesisToDefend?.toString() === true.toString() && (
            <>
              <FormTextField
                name="thesisDefenseExpectedYear"
                label={t('submission.thesisDefenseExpectedYear')}
              />
              <FormFieldSpacer width={50} />
            </>
          )}
        </>
      )}

      {!editMode && !readonly && isSubmissionExtension && (
        <div className="cpdHiddenForPrint">
          <FormFileField
            name="supportingDocuments"
            label={t('submission.proofOfEmployment')}
            tooltip={t('submission.proofOfEmploymentPrecision', { year: period.years[0] })}
            upload={uploadFile}
            remove={removeFile}
            accept={acceptedFormats}
            maxFiles={maxFilesCountToUpload}
            width={100}
          />
        </div>
      )}
      {editMode && (
        <FilesListField name="supportingDocuments" label={t('submission.proofOfEmployment')} width={100} />
      )}
    </FormFields>
  );
}

export default CoursesSubmissionSection;
