import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { sectionFromPathname } from '@modules/shared/url';

interface IProps {
  to: string;
  children: string;
  section?: string;
}

function MenuItemLink({ to, children, section = '' }: IProps) {
  const location = useLocation();

  const isSelected =
    location.pathname === to || (section && section === sectionFromPathname(location.pathname));
  const className = classNames('cpdMenu__item', { 'cpdMenu__item--selected': isSelected });

  return (
    <li role="menuitem" className={className}>
      <Link className="cpdMenu__item__link" to={to}>
        {children}
      </Link>
    </li>
  );
}

export default MenuItemLink;
