import './ImportantDatesAdmin.scss';

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { SubmissionActivity, SubmissionActivityStatus } from '@common/constants/submission-activity';
import { getActivityStatus } from '@common/utils/submission-period';
import { selectAllPeriods, selectIsClaimAllowed } from '@modules/app/redux/selectors';
import Panel from '@modules/shared/components/Panel/Panel';

function ImportantDatesAdmin() {
  const { t } = useTranslation();

  const { previousPeriod, currentPeriod, nextPeriod } = useSelector(selectAllPeriods);
  const isClaimAllowed = useSelector(selectIsClaimAllowed);

  const submissionActivityStatus = getActivityStatus(SubmissionActivity.Submission, currentPeriod);
  const claimActivityStatus = getActivityStatus(SubmissionActivity.Claim, currentPeriod);
  const isSubmissionOnGoing = submissionActivityStatus === SubmissionActivityStatus.Ongoing;

  const getParams = (
    context: SubmissionActivityStatus,
    [date1, date2]: [Date, Date],
    extensionDateEnd: Date,
  ) => {
    return { context, date1, date2, extensionDateEnd };
  };

  const submissionParams = {
    ...getParams(
      submissionActivityStatus,
      currentPeriod.dates.submission,
      currentPeriod.extensions.submission.IF[1],
    ),
    nextDate1: nextPeriod.dates.submission[0],
    nextYear1: nextPeriod.years[0],
    nextYear2: nextPeriod.years[1],
  };

  const claimParams = () => {
    const selectedPeriod =
      isClaimAllowed && claimActivityStatus === SubmissionActivityStatus.Forthcoming
        ? previousPeriod
        : currentPeriod;
    return getParams(
      claimActivityStatus,
      selectedPeriod.dates.claim,
      selectedPeriod.dates['claim-extension'][1],
    );
  };

  const renderForm = (submissionType: SubmissionActivity, isOnGoing: boolean, params: object) => {
    const formStateClasses = classNames('cpdImportantDatesAdmin__form-state', {
      'cpdImportantDatesAdmin__form-state--active': isOnGoing,
    });

    const messageKey: string = isOnGoing
      ? `importantDatesAdminPage.form.${submissionType}.message`
      : 'importantDatesAdminPage.form.message';

    return (
      <Panel title={t(`importantDatesAdminPage.form.${submissionType}.title`)}>
        <p className={formStateClasses}>
          {t('importantDatesAdminPage.form.activeState', { context: isOnGoing.toString() })}
        </p>
        <p className="cpdImportantDatesAdmin__content">
          <Trans
            i18nKey={t(messageKey, params)}
            components={{ b: <strong className="cpdImportantDatesAdmin__content__date" /> }}
          />
        </p>
      </Panel>
    );
  };

  return (
    <div className="cpdImportantDatesAdmin">
      {renderForm(SubmissionActivity.Submission, isSubmissionOnGoing, submissionParams)}
      {renderForm(SubmissionActivity.Claim, isClaimAllowed, claimParams())}
    </div>
  );
}

export default ImportantDatesAdmin;
