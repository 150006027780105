import './ImageVisualizer.scss';

import React, { useEffect, useRef, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import classNames from 'classnames';

interface IProps {
  imageUrl: string;
  padding?: number;
  minScale?: number;
  maxScale?: number;
  toolbarTimeout?: number;
}

type InitialPositions = {
  x: number;
  y: number;
  scale: number;
};

function ImageVisualizer({
  imageUrl,
  padding = 40,
  minScale = 1,
  maxScale = 4,
  toolbarTimeout = 2000,
}: IProps) {
  const nodeRef = useRef<HTMLDivElement>();
  const initialPositionsRef = useRef<InitialPositions>();
  const toolbarTimeoutRef = useRef<number>(null);
  const [isPanning, setPanning] = useState<boolean>(false);
  const [isToolbarVisible, setToolbarVisible] = useState<boolean>(true);
  const [imageLoaded, setImageLoaded] = useState<HTMLImageElement>(null);

  useEffect(() => {
    const image = new Image();
    const onLoad = () => {
      const { height, width } = nodeRef.current.getBoundingClientRect();
      const scale = Math.min(
        1,
        width / (image.naturalWidth + 2 * padding),
        height / (image.naturalHeight + 2 * padding),
      );

      initialPositionsRef.current = {
        scale,
        x: (width - image.naturalWidth * scale) / 2,
        y: (height - image.naturalHeight * scale) / 2,
      };

      showToolbar();
      setImageLoaded(image);
    };

    image.addEventListener('load', onLoad);
    image.src = imageUrl;

    return () => {
      if (toolbarTimeoutRef.current) {
        clearTimeout(toolbarTimeoutRef.current);
      }
      image.removeEventListener('load', onLoad);
    };
  }, []);

  const showToolbar = () => {
    if (!isToolbarVisible) {
      setToolbarVisible(true);
    }
    if (toolbarTimeoutRef.current) {
      clearTimeout(toolbarTimeoutRef.current);
    }
    toolbarTimeoutRef.current = window.setTimeout(() => setToolbarVisible(false), toolbarTimeout);
  };

  const minScaleComputed = initialPositionsRef.current?.scale * minScale;
  const maxScaleComputed = initialPositionsRef.current?.scale * maxScale;
  const wrapperClassName = classNames('cpdImageVisualizer__wrapper', {
    'cpdImageVisualizer__wrapper--panning': isPanning,
  });
  const toolbarClassName = classNames('cpdImageVisualizerTools', {
    'cpdImageVisualizerTools--visible': isToolbarVisible,
  });

  return (
    <div className="cpdImageVisualizer" ref={nodeRef} onMouseMove={showToolbar} onWheel={showToolbar}>
      {imageLoaded && initialPositionsRef.current && (
        <TransformWrapper
          initialPositionX={initialPositionsRef.current.x}
          initialPositionY={initialPositionsRef.current.y}
          initialScale={initialPositionsRef.current.scale}
          minScale={minScaleComputed}
          maxScale={maxScaleComputed}
          onPanningStart={() => setPanning(true)}
          onPanningStop={() => setPanning(false)}
        >
          {({ zoomIn, zoomOut, resetTransform, state: { scale } }) => (
            <>
              <div className={toolbarClassName} role="toolbar">
                <button
                  className="cpdImageVisualizerTools__item cpdImageVisualizerTools__item--zoom-out material-icons"
                  onClick={() => zoomOut()}
                  disabled={scale <= minScaleComputed}
                />
                <button
                  className="cpdImageVisualizerTools__item cpdImageVisualizerTools__item--reset material-icons"
                  onClick={() => resetTransform()}
                  disabled={scale <= minScaleComputed}
                />
                <button
                  className="cpdImageVisualizerTools__item cpdImageVisualizerTools__item--zoom-in material-icons"
                  onClick={() => zoomIn()}
                  disabled={scale >= maxScaleComputed}
                />
              </div>
              <TransformComponent
                wrapperClass={wrapperClassName}
                contentClass="cpdImageVisualizer__wrapper__content"
              >
                <img alt="Image" src={imageLoaded.src} />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      )}
    </div>
  );
}

export default ImageVisualizer;
