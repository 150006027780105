import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormDataContext,
  FormFields,
  FormFieldSpacer,
  FormFileField,
  FormPostalCodeField,
  FormSelectField,
  FormSelectFieldOptions,
  FormTextField,
} from '@novo-electronique/react-forms';
import i18n from 'i18next';

import { acceptedChequeFormats, maxFilesCountToUpload } from '@common/constants/global';
import { PaymentMethod } from '@common/constants/payment-method';
import { ISubmission } from '@common/models/submission';
import { provinceValueProvider } from '@common/value-provider';
import { removeFile, uploadFile } from '@modules/app/service';
import { valueProviderToOptions } from '@modules/shared/components/Form';
import FilesListField from '@modules/shared/components/Form/FilesListField';

interface IProps {
  readonly?: boolean;
  editMode?: boolean;
}

const PaymentInfo = ({ readonly = false, editMode = false }: IProps) => {
  const { t } = useTranslation();
  const {
    claim: { bankAccountNumber, method },
  } = useContext(FormDataContext).values as ISubmission;

  const isDisplayingAccountInfo = Boolean(bankAccountNumber);

  const renderCheckDetails = () => {
    return (
      <FormFields title={i18n.t('payment.address.title')}>
        <FormTextField name="claim.addrCivicNumber" label={i18n.t('payment.address.civicNo')} width={25} />
        <FormTextField name="claim.addrStreet" label={i18n.t('payment.address.street')} width={50} />
        <FormTextField name="claim.addrApartment" label={i18n.t('payment.address.apartment')} width={25} />

        <FormTextField name="claim.addrCity" label={i18n.t('payment.address.city')} width={50} />
        <FormSelectField name="claim.addrProvince" label={i18n.t('payment.address.province')} width={25}>
          <FormSelectFieldOptions options={valueProviderToOptions(provinceValueProvider)} />
        </FormSelectField>
        <FormPostalCodeField
          name="claim.addrPostalCode"
          label={i18n.t('payment.address.postalCode')}
          width={25}
        />
      </FormFields>
    );
  };

  const renderDepositDetails = () => {
    return (
      <>
        {!editMode && (
          <FormFileField
            name="claim.specimenCheque"
            label={t('claim.specimenCheque')}
            description={t('claim.specimenChequeDescription', {})}
            upload={uploadFile}
            remove={removeFile}
            accept={acceptedChequeFormats}
            maxFiles={1}
            width={100}
          />
        )}

        {editMode && (
          <>
            {isDisplayingAccountInfo && (
              <>
                <FormTextField
                  name="claim.bankTransitNumber"
                  label={t('payment.bankTransitNumber')}
                  width={33}
                />
                <FormTextField
                  name="claim.bankInstitutionNumber"
                  label={t('payment.bankInstitutionNumber')}
                  width={33}
                />
                <FormTextField
                  name="claim.bankAccountNumber"
                  label={t('payment.bankAccountNumber')}
                  width={33}
                />
              </>
            )}
            <FilesListField
              label={t('claim.specimenCheque')}
              name="claim.specimenCheque"
              accept={acceptedChequeFormats}
              maxFiles={maxFilesCountToUpload}
              width={100}
            />
          </>
        )}
      </>
    );
  };

  return (
    <FormFields title={t('payment.bankDetails')} readonly={readonly}>
      <FormSelectField name="claim.method" label={i18n.t('payment.method')} width={50}>
        <FormSelectFieldOptions
          options={Object.values(PaymentMethod).map((method, index) => ({
            key: index.toString(),
            value: method,
            label: i18n.t(`payment.${method}`),
          }))}
          addEmptyOption={!editMode}
        />
      </FormSelectField>
      <FormFieldSpacer width={50} />

      {method === PaymentMethod.Check && renderCheckDetails()}
      {method === PaymentMethod.DirectDeposit && renderDepositDetails()}
    </FormFields>
  );
};

export default PaymentInfo;
