import './SignInForm.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Form,
  FormActionButton,
  FormActionButtonStyle,
  FormActionButtonType,
  FormContext,
  FormPasswordField,
  FormProvider,
  FormTextField,
} from '@novo-electronique/react-forms';
import * as Yup from 'yup';

import { AuthProvider } from '@common/constants/auth-provider';
import { AdminRoutePage } from '@constants/routes';
import { selectIsAppLoading } from '@modules/app/redux/selectors';
import { history } from '@src/redux/store';
import { useThunkDispatch } from '@src/redux/useThunkDispatch';
import { resetIdentity } from '../redux/action';
import { getIdentity } from '../redux/selectors';
import { signIn, signInMicrosoft, validateIdentity } from '../service';
import SignInMicrosoftButton from './SignInMicrosoftButton';

export default function SignInForm() {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();
  const identity = useSelector(getIdentity);
  const isLoading = useSelector(selectIsAppLoading);

  const isIdentityValidated = !!(identity && identity.email);
  const initialValues = { email: '', password: '', provider: AuthProvider.EmailPassword };

  const getSignInSchema = () =>
    Yup.object().shape({
      email: Yup.string().email().required(),
      password:
        isIdentityValidated && identity.provider === AuthProvider.EmailPassword
          ? Yup.string().required()
          : Yup.mixed(),
    });

  const submitIdentityForm = (values, setErrors): Promise<void> => {
    return dispatch(validateIdentity(values.email)).catch(setErrors);
  };

  const submitLoginForm = (values, setErrors): Promise<void> => {
    return dispatch(
      identity.provider === AuthProvider.EmailPassword
        ? signIn(values.email, values.password)
        : signInMicrosoft(values.email),
    )
      .then(() => history.push(AdminRoutePage.Submissions))
      .catch(setErrors);
  };

  const backButtonClicked = () => dispatch(resetIdentity());

  return (
    <div className="cpdSignInForm">
      <FormProvider isShowingRequiredSymbol={false} labelsNamespace="general.forms">
        <FormContext
          initialValues={initialValues}
          validationSchema={getSignInSchema()}
          onSubmit={isIdentityValidated ? submitLoginForm : submitIdentityForm}
        >
          <Form>
            {/* Email */}
            <FormTextField
              name="email"
              type="email"
              label={isIdentityValidated ? '' : t('authenticationPage.email')}
              disabled={isIdentityValidated}
              width={100}
              autoFocus={true}
            />

            {/* Password */}
            {isIdentityValidated && identity.provider === AuthProvider.EmailPassword && (
              <FormPasswordField
                label={t('authenticationPage.password')}
                name="password"
                width={100}
                autoFocus={true}
              />
            )}

            {/* Sign in with Microsoft */}
            {isIdentityValidated && identity.provider === AuthProvider.Microsoft && <SignInMicrosoftButton />}

            {/* Footer */}
            <footer className="cpdSignInForm__footer">
              <div className="cpdSignInForm__footer__col">
                {/* Submit button */}
                {identity.provider !== AuthProvider.Microsoft && (
                  <FormActionButton
                    type={FormActionButtonType.Submit}
                    suppressErrorTooltip={true}
                    disabled={(formik) =>
                      isLoading ||
                      !formik.isValid ||
                      (!isIdentityValidated && !Object.values(formik.values).some((d) => d)) ||
                      (isIdentityValidated && !Object.values(formik.values).every((d) => d))
                    }
                  >
                    {t('authenticationPage.next')}
                  </FormActionButton>
                )}
              </div>

              {isIdentityValidated && (
                <div className="cpdSignInForm__footer__col cpdSignInForm__footer__col--back">
                  <FormActionButton
                    type={FormActionButtonType.Reset}
                    style={FormActionButtonStyle.Link}
                    callback={() => backButtonClicked()}
                  >
                    <span className="material-icons">arrow_back_ios</span>
                    {t('authenticationPage.back')}
                  </FormActionButton>
                </div>
              )}
            </footer>
          </Form>
        </FormContext>
      </FormProvider>
    </div>
  );
}
