import React from 'react';
import { useTranslation } from 'react-i18next';

import { AdminRoutePage } from '@constants/routes';
import Menu, { MenuItemLink, MenuStyle, MenuType } from '@modules/shared/components/Menu';

function AssociationsMenu() {
  const { t } = useTranslation();

  return (
    <Menu style={MenuStyle.Admin} type={MenuType.Submenu}>
      <MenuItemLink to={AdminRoutePage.Associations}>{t('adminAssociationsMenu.associations')}</MenuItemLink>
      <MenuItemLink to={AdminRoutePage.LocalSections}>
        {t('adminAssociationsMenu.localSections')}
      </MenuItemLink>
      <MenuItemLink to={AdminRoutePage.ServiceCenters}>
        {t('adminAssociationsMenu.serviceCenters')}
      </MenuItemLink>
    </Menu>
  );
}

export default AssociationsMenu;
