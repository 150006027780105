import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  FieldWidth,
  Form,
  FormActionButton,
  FormActionButtonType,
  FormCheckboxField,
  FormContext,
  FormFields,
  FormTextAreaField,
} from '@novo-electronique/react-forms';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { SubmissionActivity } from '@common/constants/submission-activity';
import { SubmissionType } from '@common/constants/submission-type';
import { getSubmissionInitialValues, ISubmission } from '@common/models/submission';
import { getInitialValuesFromType, getValidationSchemaFromType, getVersion } from '@common/utils/submission';
import { isActivityAllowed } from '@common/utils/submission-period';
import { selectCurrentPeriod, selectUser } from '@modules/app/redux/selectors';
import FormPrint from '@modules/shared/components/FormPrint';
import ActivitySubmissionSection from '@modules/submissions/components/ActivitySubmissionSection';
import CoursesSubmissionSection from '@modules/submissions/components/CoursesSubmissionSection';
import SubmissionGeneralInformationSection from '@modules/submissions/components/SubmissionGeneralInformationSection';
import SubmissionTypeField from '@modules/submissions/components/SubmissionTypeField';

interface IProps {
  onSubmit: (submission: ISubmission) => Promise<void>;
  onFormDirty?: () => void;
  submission?: ISubmission;
  readonly?: boolean;
  isDisabledExceptDocuments?: boolean;
}

function SubmissionForm({ onSubmit, onFormDirty, submission, readonly, isDisabledExceptDocuments }: IProps) {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const period = useSelector(selectCurrentPeriod);

  const formikRef = useRef<ReturnType<typeof useFormik>>(null);
  const scrollToElementRef = useRef<HTMLDivElement>(null);

  const [validationSchema, setValidationSchema] = useState(getValidationSchemaFromType(Yup));
  const [submissionType, setSubmissionType] = useState(submission ? submission.type : null);

  useEffect(() => {
    if (!formikRef.current || !submissionType) {
      return;
    }

    const version = getVersion(submissionType, period);
    setValidationSchema(
      Yup.object({
        ...getValidationSchemaFromType(Yup, submissionType, version, false).fields,
        confirmation: Yup.mixed().when('status', () => {
          return isDisabledExceptDocuments ? Yup.bool().nullable() : Yup.bool().oneOf([true]).required();
        }),
      }),
    );

    const initialValues = getInitialValuesFromType(submissionType);
    const values = {
      ...initialValues,
      ...formikRef.current.values,
      version,
      type: submissionType,
      confirmation: false,
    };
    formikRef.current.setValues(values, true).then(() => {
      if (!submission) {
        scrollToElementRef.current.scrollIntoView({
          block: 'start',
          inline: 'nearest',
          behavior: 'smooth',
        });
      }
    });
  }, [submissionType]);

  useEffect(() => {
    if (formikRef.current && formikRef.current.isValidating) {
      formikRef.current.validateForm();
    }
  }, [validationSchema]);

  const initialValues = submission || getSubmissionInitialValues();
  return (
    <FormContext
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikRef={formikRef}
      onSubmit={onSubmit}
      onDirty={onFormDirty}
    >
      <FormPrint title={initialValues.code}>
        <Form readonly={readonly}>
          <SubmissionGeneralInformationSection isDisabled={isDisabledExceptDocuments} />

          <div className="cpdHiddenForPrint">
            <FormFields title={t('submissionPage.submissionType')} readonly={Boolean(submission)}>
              <div className="cpdStretchContainer">
                {Object.values(SubmissionType)
                  .filter((type) => !submission || submission.type === type)
                  .map((type, _i, items) => (
                    <SubmissionTypeField
                      key={type}
                      type={type}
                      onChange={() => setSubmissionType(type)}
                      width={(100 / items.length) as FieldWidth}
                      disabled={
                        (!user && !isActivityAllowed(SubmissionActivity.Submission, period, type)) ||
                        (submission && submission.type != type)
                      }
                    />
                  ))}
              </div>
              <div ref={scrollToElementRef} />
            </FormFields>
          </div>

          {submissionType && (
            <>
              {(submissionType === SubmissionType.Cu || submissionType === SubmissionType.If) && (
                <CoursesSubmissionSection submissionType={submissionType} readonly={readonly} />
              )}
              {(submissionType === SubmissionType.Pca || submissionType === SubmissionType.Pp) && (
                <ActivitySubmissionSection
                  submissionType={submissionType}
                  readonly={readonly}
                  isDisabledExceptDocuments={isDisabledExceptDocuments}
                />
              )}
              <FormFields title={t('submissionPage.message')}>
                <FormTextAreaField
                  disabled={isDisabledExceptDocuments}
                  name="message"
                  label={t('submission.message')}
                  width={100}
                />
              </FormFields>

              {!readonly && (
                <div className="cpdHiddenForPrint">
                  {!isDisabledExceptDocuments && (
                    <FormFields title={t('submissionPage.confirmation')} required>
                      <div className="cpdFormSingleField">
                        <FormCheckboxField
                          name="confirmation"
                          label={t('submissionPage.confirmationLabel', {
                            context:
                              submissionType === SubmissionType.Cu || submissionType === SubmissionType.If
                                ? 'courses'
                                : 'activity',
                          })}
                          value="true"
                          width={100}
                        />
                      </div>
                    </FormFields>
                  )}

                  <FormActionButton type={FormActionButtonType.Submit}>
                    {!submission && t('submissionPage.submit')}
                    {submission && t('submissionPage.submit', { context: 'modifications' })}
                  </FormActionButton>
                </div>
              )}
            </>
          )}
        </Form>
      </FormPrint>
    </FormContext>
  );
}

export default SubmissionForm;
