import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  FormActionButton,
  FormActionButtonType,
  FormContext,
  FormEmailField,
  FormFields,
  FormSelectField,
  FormSelectFieldOptions,
  FormTextField,
  IFormErrors,
} from '@novo-electronique/react-forms';
import {
  SidePanelContent,
  SidePanelFooter,
  SidePanelFooterActions,
  SidePanelHeader,
} from '@novo-electronique/react-side-panel';
import * as Yup from 'yup';

import { getUserValidationSchema, IUser } from '@common/models/user';
import SidePanel from '@modules/shared/components/SidePanel';

interface IProps {
  user: IUser;
  submitHandler: (values: any, setErrors?: (errors: IFormErrors) => void) => Promise<void>;
  cancelHandler: () => void;
}

function UserEditionPanel({ user, submitHandler, cancelHandler }: IProps) {
  const { t } = useTranslation();
  const validationSchema = getUserValidationSchema(Yup);
  const emailDomain = 'fqde.qc.ca';

  const statusesOptions = [true, false].map((value) => ({
    value: (+value).toString(),
    label: t('usersAdminPage.activeStatus', { context: value.toString() }),
  }));

  return (
    <FormContext
      initialValues={{ ...user, enabled: user.enabled?.toString() }}
      onSubmit={submitHandler}
      validationSchema={validationSchema}
    >
      <Form>
        <SidePanel closeHandler={cancelHandler}>
          <SidePanelHeader displayCloseButton>
            {user?.id ? `${user.firstName} ${user.lastName}` : t('usersAdminPage.new')}
          </SidePanelHeader>

          <SidePanelContent>
            <FormFields title={t('usersAdminPage.editTitle')}>
              <FormTextField name="firstName" label={t('user.firstName')} width={50} />
              <FormTextField name="lastName" label={t('user.lastName')} width={50} />
              <FormEmailField name="email" domain={emailDomain} label={t('user.email')} />
              <FormSelectField name="enabled" label={t('user.enabled')}>
                <FormSelectFieldOptions options={statusesOptions} />
              </FormSelectField>
            </FormFields>
          </SidePanelContent>

          <SidePanelFooter>
            <SidePanelFooterActions>
              <FormActionButton type={FormActionButtonType.Submit} small>
                {t('general.forms.save')}
              </FormActionButton>
            </SidePanelFooterActions>
            <SidePanelFooterActions isSecondary>
              <FormActionButton type={FormActionButtonType.Reset} small />
            </SidePanelFooterActions>
          </SidePanelFooter>
        </SidePanel>
      </Form>
    </FormContext>
  );
}

export default UserEditionPanel;
