import {
  addYears as dateFnsAddYears,
  differenceInDays as dateFnsDifferenceInDays,
  differenceInWeeks as dateFnsDifferenceInWeeks,
  endOfMonth as dateFnsEndOfMonth,
  format,
  isDate as dateFnsIsDate,
  isValid as dateFnsIsValid,
  parse as dateParse,
  parseISO,
  subDays as dateFnsSubDays,
  subYears as dateFnsSubYears,
} from 'date-fns';
import { enCA as en, fr } from 'date-fns/locale';
import { zonedTimeToUtc as dateFnsZonedTimeToUtc } from 'date-fns-tz';
import i18n from 'i18next';

import { Language } from '../constants/language';

export function getDate(): Date {
  return new Date();
}

export function parse(date: string, format: string): Date {
  return dateParse(date, format, new Date());
}

export function formatDate(
  date: string | Date,
  pattern: string = 'yyyy-MM-dd',
  language = i18n.language as Language,
): string {
  if (!date) return '';

  const parsedDate = date instanceof Date ? date : parseISO(date);

  if (isValid(parsedDate)) {
    return customFormat(parsedDate, pattern, language);
  }

  return date instanceof Date ? date.toString() : date;
}

export function parseIsoDateString(date: string): Date {
  return parseISO(date);
}

export function isDate(date: any): boolean {
  return dateFnsIsDate(date);
}

export function isValid(date: Date): boolean {
  return dateFnsIsValid(date);
}

export function differenceInDays(laterDate: Date, earlierDate: Date): number {
  return dateFnsDifferenceInDays(laterDate, earlierDate);
}

export function daysSince(date: Date): number {
  return differenceInDays(new Date(), date);
}

export function endOfMonth(date: Date): Date {
  return dateFnsEndOfMonth(date);
}

export function differenceInWeeks(laterDate: Date, earlierDate: Date): number {
  return dateFnsDifferenceInWeeks(laterDate, earlierDate);
}

export function subYears(date: Date, yearsToSubtract: number): Date {
  return dateFnsSubYears(date, yearsToSubtract);
}

export function subDays(date: Date, daysToSubtract: number): Date {
  return dateFnsSubDays(date, daysToSubtract);
}

export function addYears(date: Date, yearsToAdd: number): Date {
  return dateFnsAddYears(date, yearsToAdd);
}

export function zonedTimeToUtc(date: Date, timeZone: string): Date {
  return dateFnsZonedTimeToUtc(date, timeZone);
}

export function monthName(monthIndex: number, language = i18n.language as Language): string {
  const date = new Date();
  date.setDate(1);
  date.setMonth(monthIndex - 1);

  return format(new Date(date), 'LLLL', { locale: getLocale(language) });
}

function customFormat(date: Date, pattern: string, language: Language): string {
  const dateFormatted = format(date, pattern, { locale: getLocale(language) });
  if (language !== Language.Fr) {
    return dateFormatted;
  }

  switch (pattern) {
    case 'PPP':
      const parts = dateFormatted.split(' ');
      if (parts[0] === '1' && language === Language.Fr) {
        parts[0] = '1er';
      }
      return parts.join(' ');
    default:
      return dateFormatted;
  }
}

function getLocale(language: Language) {
  switch (language) {
    case Language.Fr:
      return fr;
    default:
      return en;
  }
}
