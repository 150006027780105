import { IDisposable } from '@common/models/disposable';

const expirationIntervalDelay = 1000;
const trackerTimeoutDelay = 250;

// Based on: https://medium.com/tinyso/how-to-detect-inactive-user-to-auto-logout-by-using-idle-timeout-in-javascript-react-angular-and-b6279663acf2
export class IdleTimer implements IDisposable {
  private trackerTimeout: number;
  private expirationInterval: number;
  private isRunning = false;

  constructor(
    private readonly timeoutInSeconds: number,
    private readonly storage: Storage,
    private readonly timerId: string = '_expirationTime',
  ) {}

  run(onTimeout: () => void) {
    if (this.isRunning) {
      throw new Error('The timer is already started.');
    }

    const expirationTime = this.getExpirationTime();
    if (expirationTime > 0 && expirationTime < Date.now()) {
      return onTimeout();
    }

    this.isRunning = true;
    this.registerTrackers();
    this.updateExpirationTime();

    this.expirationInterval = window.setInterval(() => {
      const expirationTime = this.getExpirationTime();
      if (expirationTime < Date.now()) {
        this.dispose();
        onTimeout();
      }
    }, expirationIntervalDelay);
  }

  dispose() {
    if (this.trackerTimeout) {
      clearTimeout(this.trackerTimeout);
    }
    if (this.expirationInterval) {
      clearInterval(this.expirationInterval);
    }
    this.storage.removeItem(this.timerId);

    window.removeEventListener('mousemove', this.updateExpirationTimeHandler);
    window.removeEventListener('scroll', this.updateExpirationTimeHandler);
    window.removeEventListener('keydown', this.updateExpirationTimeHandler);

    this.isRunning = false;
  }

  private registerTrackers() {
    window.addEventListener('mousemove', this.updateExpirationTimeHandler);
    window.addEventListener('scroll', this.updateExpirationTimeHandler);
    window.addEventListener('keydown', this.updateExpirationTimeHandler);
  }

  private updateExpirationTimeHandler = () => {
    if (this.trackerTimeout) {
      clearTimeout(this.trackerTimeout);
    }

    this.trackerTimeout = window.setTimeout(() => {
      this.updateExpirationTime();
    }, trackerTimeoutDelay);
  };

  private getExpirationTime(): number {
    return parseInt(this.storage.getItem(this.timerId)) || 0;
  }

  private updateExpirationTime() {
    const timeout = this.timeoutInSeconds * 1000;
    this.storage.setItem(this.timerId, (Date.now() + timeout).toString());
  }
}
