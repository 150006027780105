import { IEntityValue } from './entity-value';
import { IEntityValueLabels } from './entity-value-labels';
import IValueProvider from './value-provider';

export default class EntityValueProvider implements IValueProvider {
  protected valueLabels: IEntityValueLabels;

  constructor(private readonly getEntities: () => IEntityValue[] | Promise<IEntityValue[]>) {}

  private populateValueLabels = async () => {
    if (this.valueLabels) return;

    const values = await this.getEntities();
    this.valueLabels = valuesToValueLabelMap(values);
  };

  label(value: string): string {
    if (!this.valueLabels) {
      return value;
    }
    return this.valueLabels[value];
  }

  values(): string[] {
    if (!this.valueLabels) return [];
    return Object.keys(this.valueLabels);
  }

  async init() {
    await this.populateValueLabels();
  }
}

export const valuesToValueLabelMap = (values: IEntityValue[]): { [key: string]: string } => {
  return values.reduce((filterValueLabels: IEntityValueLabels, filterValue: IEntityValue) => {
    filterValueLabels[filterValue.value] = filterValue.label;
    return filterValueLabels;
  }, {});
};
