import React from 'react';
import { useTranslation } from 'react-i18next';

import { SiteLayout } from '@modules/shared/components/Layout';
import RecuperationLink from './components/RecuperationLink';
import { sendActiveSubmissionsList } from './service';

function RecuperationPage() {
  const { t } = useTranslation();

  return (
    <SiteLayout title={t('submissionRecuperationPage.title')}>
      <RecuperationLink onSubmit={sendActiveSubmissionsList} />
    </SiteLayout>
  );
}

export default RecuperationPage;
