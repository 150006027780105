import './SiteLayout.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { ReactComponent as HorizontalLogo } from '@assets/horizontal-logo.svg';
import { ReactComponent as Logo } from '@assets/logo.svg';
import { SiteRoutePage } from '@constants/routes';
import SiteMenu from '@modules/app/components/SiteMenu';
import { MenuStyle } from '@modules/shared/components/Menu';
import DocumentTitle from '../DocumentTitle/DocumentTitle';

export enum SiteLayoutStyle {
  Default = 'default',
  Home = 'home',
}

interface IProps {
  children: React.ReactNode | React.ReactNodeArray;
  title?: string | React.ReactElement;
  width?: 'large' | 'medium' | 'small';
  style?: SiteLayoutStyle;
  decoration?: boolean;
  footer?: React.ReactNode;
  toolbar?: React.ReactNode | React.ReactNodeArray;
}

function SiteLayout({
  children,
  title,
  footer,
  toolbar,
  width = 'large',
  style = SiteLayoutStyle.Default,
  decoration = false,
}: IProps) {
  const { t } = useTranslation();
  const layoutClassName = classNames('cpdSiteLayout', { [`cpdSiteLayout--${width}`]: width });
  const panelClassName = classNames('cpdSiteLayout__panel', {
    'cpdSiteLayout__panel--home': style === SiteLayoutStyle.Home,
    'cpdSiteLayout__panel--decoration': style === SiteLayoutStyle.Default && decoration,
  });

  const renderHeaderLogo = () => {
    if (style === SiteLayoutStyle.Home) {
      return <Logo className="cpdSiteLayout__panel__header__logo" />;
    }
    return (
      <Link to={SiteRoutePage.Home} title={t('general.home')}>
        <HorizontalLogo className="cpdSiteLayout__panel__header__logo" />
      </Link>
    );
  };

  return (
    <div className={layoutClassName}>
      <DocumentTitle>{t('general.title')}</DocumentTitle>
      {width === 'small' && (
        <div className="cpdSiteLayout__logo">
          <Logo className="cpdSiteLayout__logo__img" />
        </div>
      )}
      <article className={panelClassName}>
        {width !== 'small' && (
          <header className="cpdSiteLayout__panel__header">
            {renderHeaderLogo()}
            <nav className="cpdSiteLayout__panel__header__nav">
              <SiteMenu style={style === SiteLayoutStyle.Home ? MenuStyle.Home : MenuStyle.Site} />
            </nav>
          </header>
        )}
        <div className="cpdSiteLayout__panel__content">
          <div className="cpdSiteLayout__panel__content__header">
            {title && <h1 className="cpdSiteLayout__panel__content__title">{title}</h1>}
            {toolbar && (
              <div className="cpdSiteLayout__panel__content__toolbar" role="toolbar">
                {toolbar}
              </div>
            )}
          </div>
          {children}
        </div>
      </article>
      {footer && <footer className="cpdSiteLayout__footer">{footer}</footer>}
    </div>
  );
}

export default SiteLayout;
