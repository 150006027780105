import './HomePage.scss';

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Button, ButtonStyle } from '@novo-electronique/react-button';
import i18next from 'i18next';

import { Language } from '@common/constants/language';
import { SubmissionActivity, SubmissionActivityStatus } from '@common/constants/submission-activity';
import { SubmissionType } from '@common/constants/submission-type';
import { formatPeriod, getActivityStatus, isActivityAllowed } from '@common/utils/submission-period';
import { SiteRoutePage } from '@constants/routes';
import {
  selectCurrentPeriod,
  selectIsClaimAllowed,
  selectIsSubmissionAllowed,
  selectNextPeriod,
} from '@modules/app/redux/selectors';
import { SiteLayout } from '@modules/shared/components/Layout';
import { SiteLayoutStyle } from '@modules/shared/components/Layout/SiteLayout';
import { datesAnchorName } from './AboutPage';

const organizationLogos = [
  { name: 'aaesq', type: 'svg', website: 'https://www.aaesq.ca/' },
  { name: 'aqpde', type: 'png', website: 'https://www.aqpde.ca/' },
  { name: 'qesba', type: 'png', website: 'https://www.qesba.qc.ca/fr/' },
  { name: 'fcssq', type: 'svg', website: 'https://www.fcssq.quebec/' },
  { name: 'amdes', type: 'svg', website: 'https://amdes.qc.ca/' },
  { name: 'fqde', type: 'svg', website: 'https://fqde.qc.ca/' },
  { name: 'eesq', type: 'svg', website: 'https://www.education.gouv.qc.ca/' },
];

function HomePage() {
  const { t } = useTranslation();
  const history = useHistory();
  const currentPeriod = useSelector(selectCurrentPeriod);
  const nextPeriod = useSelector(selectNextPeriod);
  const isSubmissionAllowed = useSelector(selectIsSubmissionAllowed);
  const isClaimAllowed = useSelector(selectIsClaimAllowed);

  const submissionActivityStatus = getActivityStatus(SubmissionActivity.Submission, currentPeriod);
  const isStandardSubmissionAllowed = isActivityAllowed(SubmissionActivity.Submission, currentPeriod, [
    SubmissionType.Cu,
    SubmissionType.Pca,
    SubmissionType.Pp,
  ]);
  const isSubmissionExtension =
    !isStandardSubmissionAllowed && submissionActivityStatus === SubmissionActivityStatus.Ongoing;

  const params = {
    context: submissionActivityStatus,
    year1: currentPeriod.years[0],
    year2: currentPeriod.years[1],
    date1: currentPeriod.dates[SubmissionActivity.Submission][0],
    date2: currentPeriod.dates[SubmissionActivity.Submission][1],
    nextYear1: nextPeriod.years[0],
    nextYear2: nextPeriod.years[1],
    nextDate1: nextPeriod.dates[SubmissionActivity.Submission][0],
  };

  const renderSubmissionButton = () => (
    <Button style={ButtonStyle.Primary} onClick={() => history.push(SiteRoutePage.Submission)}>
      {t('homePage.submissionPanel.subscriptionAction')}
    </Button>
  );

  const renderFooter = () => {
    return (
      <article className="cpdHomePageFooter">
        <h1 className="cpdHomePageFooter__title">{t('homePage.footerTitle')}</h1>
        <ul className="cpdHomePageFooter__organizations">
          {organizationLogos.map(({ name, type, website }) => (
            <li key={name} className="cpdHomePageFooter__organizations__item">
              <a rel="noopener noreferrer" href={website} target="_blank">
                <img
                  className={`cpdHomePageFooter__organizations__item__logo cpdHomePageFooter__organizations__item__logo--${name}`}
                  src={`${process.env.PUBLIC_URL}/assets/organizations/${name}.${type}`}
                  alt={name.toUpperCase()}
                />
              </a>
            </li>
          ))}
        </ul>
      </article>
    );
  };

  const fileLink =
    i18next.language === Language.Fr
      ? 'https://fqde.qc.ca/wp-content/uploads/2022/09/Document-info-CPD-Version-francaise.pdf'
      : 'https://fqde.qc.ca/wp-content/uploads/2022/09/Document-info-CPD-Version-anglaise.pdf';

  return (
    <SiteLayout
      title={<Trans i18nKey="homePage.title" components={{ br: <span className="cpdLineBreak" /> }} />}
      style={SiteLayoutStyle.Home}
      footer={renderFooter()}
    >
      <p className="cpdSiteLayout__panel__content__text">{t('homePage.intro')}</p>
      <article className="cpdHomePagePanel">
        <h2 className="cpdHomePagePanel__title">
          {t('homePage.submissionPanel.title', {
            context: submissionActivityStatus,
            period: formatPeriod(currentPeriod),
          })}
        </h2>

        {!isSubmissionExtension && (
          <p className="cpdHomePagePanel__text">
            {t('homePage.submissionPanel.description', params)}
            {' – '}
            <HashLink
              className="cpdHomePagePanel__text__link"
              to={`${SiteRoutePage.About}#${datesAnchorName}`}
            >
              {t('homePage.submissionPanel.importantDates')}
            </HashLink>
          </p>
        )}

        {isStandardSubmissionAllowed && renderSubmissionButton()}
        {isSubmissionExtension && (
          <article className="cpdHomePagePanel">
            <h3 className="cpdHomePagePanel__title">{t('ifPrecision.title')}</h3>
            <p className="cpdHomePagePanel__text">{t('ifPrecision.scope', params)}</p>
            <p className="cpdHomePagePanel__text">
              {t('ifPrecision.extension', {
                date1: currentPeriod.extensions[SubmissionActivity.Submission][SubmissionType.If][0],
                date2: currentPeriod.extensions[SubmissionActivity.Submission][SubmissionType.If][1],
              })}
            </p>
            {renderSubmissionButton()}
          </article>
        )}
        {(isSubmissionAllowed || isClaimAllowed) && (
          <p className="cpdHomePagePanel__text">
            {t('homePage.submissionPanel.requestsLink')}{' '}
            <Link className="cpdHomePagePanel__text__link" to="/subvention/recuperation">
              {t('homePage.submissionPanel.requestsLinkAction')}
            </Link>
          </p>
        )}
        <ul className="cpdHomePageDocumentsList">
          <li className="cpdHomePageDocumentsList__item">
            <a href={fileLink} className="cpdHomePageDocumentsList__item__link">
              {t('homePage.submissionPanel.detailedDocument')}
            </a>
          </li>
        </ul>
      </article>
    </SiteLayout>
  );
}

export default HomePage;
