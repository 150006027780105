import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { IPeriodAmount } from '@common/models/period-amount';
import { selectAllPeriods } from '@modules/app/redux/selectors';
import PeriodAmountsForm from '@modules/settings/components/PeriodAmountsForm';
import { selectPeriodAmounts } from '@modules/settings/redux/selectors';
import Initializing from '@modules/shared/components/Initializing';
import { AdminLayout } from '@modules/shared/components/Layout';
import { useThunkDispatch } from '@src/redux/useThunkDispatch';
import { getCurrentAmount, savePeriodAmount } from './service';

function PeriodAmountAdminPage() {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();
  const periodAmounts = useSelector(selectPeriodAmounts);
  const { previousPeriod, currentPeriod, nextPeriod } = useSelector(selectAllPeriods);

  const onSubmit = async (periodAmounts: IPeriodAmount[]) => {
    try {
      await dispatch(savePeriodAmount(periodAmounts));
      toast.success(t('periodAmountAdminPage.saveConfirmationMessage'));
    } catch (e) {
      console.error(e);
      toast.error(t('periodAmountAdminPage.saveErrorMessage'));
    }
  };

  if (!periodAmounts) {
    return <Initializing />;
  }

  const filteredPeriodAmounts = [previousPeriod, currentPeriod, nextPeriod].map<IPeriodAmount>((period) => ({
    periodId: period.id,
    amount: getCurrentAmount(periodAmounts, period.id),
  }));
  return (
    <AdminLayout>
      <header className="cpdAdminLayout__content__header">
        <h1 className="cpdAdminLayout__content__header__title">{t('adminSettingsMenu.periodAmount')}</h1>
      </header>
      <PeriodAmountsForm periodAmounts={filteredPeriodAmounts} onSubmit={onSubmit} />
    </AdminLayout>
  );
}

export default PeriodAmountAdminPage;
