import './StickyBox.scss';

import React from 'react';

interface IProps {
  title: string;
  description: string;
}

function StickyBox({ title, description }: IProps) {
  return (
    <div className="cpdStickyBox">
      <h3 className="cpdStickyBox__title">{title}</h3>
      <p className="cpdStickyBox__description">{description}</p>
    </div>
  );
}

export default StickyBox;
