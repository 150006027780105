import React from 'react';
import { useTranslation } from 'react-i18next';

import SignInForm from '@modules/auth/components/SignInForm';
import { SiteLayout } from '@modules/shared/components/Layout';

export default function AuthPage() {
  const { t } = useTranslation();

  return (
    <SiteLayout width="small" title={t('authenticationPage.title')}>
      <SignInForm />
    </SiteLayout>
  );
}
