import i18n from 'i18next';

import { allowedTypesForModifications, submissionAllowedStatuses } from '@common/constants/global';
import { Language } from '@common/constants/language';
import { SubmissionActivity } from '@common/constants/submission-activity';
import { claimStatuses, SubmissionStatus, submissionStatuses } from '@common/constants/submission-status';
import { SubmissionType } from '@common/constants/submission-type';
import { IActivityClaim, IClaim, ICoursesClaim } from '@common/models/claim';
import { ISubmission } from '@common/models/submission';
import { getActivityClaimModificationsCount, getCoursesClaimModificationsCount } from '@common/utils/claim';
import { getPeriod, isActivityAllowed } from '@common/utils/submission-period';
import apiClient from '@modules/shared/api-client';

export function createSubmission(submission: ISubmission): Promise<ISubmission> {
  return apiClient.post(`/submission/${submission.type}`, {
    ...submission,
    language: i18n.language as Language,
  });
}

export function updateSubmission(
  submission: ISubmission,
  shouldNotifyMember: boolean = true,
): Promise<ISubmission> {
  return apiClient.put(
    `/submission/${submission.type}/${submission.id}?notifyMember=${shouldNotifyMember.toString()}`,
    submission,
  );
}

export function createClaim(submission: ISubmission, claim: IClaim): Promise<ISubmission> {
  return apiClient.post(`/submission/${submission.type}/${submission.id}/claim`, claim);
}

export function updateClaim(submission: ISubmission, claim: IClaim): Promise<ISubmission> {
  return apiClient.put(`/submission/${submission.type}/${submission.id}/claim/${claim.id}`, claim);
}

export function getSubmission<T extends ISubmission>(type: SubmissionType, id: string): Promise<T> {
  return apiClient.get<T>(`/submission/${type}/${id}`).then((submission) => ({
    ...submission,
    created: new Date(submission.created),
    updated: new Date(submission.updated),

    claim: submission.claim && {
      ...submission.claim,
      created: new Date(submission.claim.created),
    },
  }));
}

export function sendActiveSubmissionsList(email: string): Promise<void> {
  return apiClient.post('/submission/send-active-list', { email });
}

export function getAvailableStatuses(status: SubmissionStatus, type: SubmissionType): SubmissionStatus[] {
  let submissionStatusList = submissionStatuses;
  if ([SubmissionType.Pca, SubmissionType.Pp].includes(type)) {
    submissionStatusList = [...submissionStatuses, SubmissionStatus.SubmissionWaitingForDocuments];
  }
  if (status === SubmissionStatus.NewSubmission) {
    return [...submissionStatusList, SubmissionStatus.NewSubmission];
  } else {
    return [[SubmissionStatus.ClaimPaid], submissionStatusList, claimStatuses].find((statusList) =>
      statusList.includes(status),
    );
  }
}

export function getAvailableSubmissionTypes(type: SubmissionType): SubmissionType[] {
  switch (type) {
    case SubmissionType.Cu:
    case SubmissionType.If:
      return [SubmissionType.Cu, SubmissionType.If];
    case SubmissionType.Pca:
    case SubmissionType.Pp:
      return [SubmissionType.Pca, SubmissionType.Pp];
    default:
      return [type];
  }
}

export function isSubmissionEditable(submission: ISubmission, date: Date = new Date()): boolean {
  return (
    !submission ||
    (allowedTypesForModifications.includes(submission.type) &&
      submissionAllowedStatuses.includes(submission.status) &&
      isActivityAllowed(SubmissionActivity.Submission, getPeriod(submission.periodId), submission.type, date))
  );
}

export function isDocumentUploadAllowed(submission: ISubmission, date: Date = new Date()): boolean {
  return (
    !submission ||
    (submission.status === SubmissionStatus.SubmissionWaitingForDocuments &&
      isActivityAllowed(
        SubmissionActivity.SubmissionDocumentsExtension,
        getPeriod(submission.periodId),
        submission.type,
        date,
      ))
  );
}

export function isClaimAllowedForSubmission(submission: ISubmission, date: Date = new Date()): boolean {
  const allowedStatuses = [
    SubmissionStatus.SubmissionAccepted,
    SubmissionStatus.ClaimWaitingForDocuments,
    SubmissionStatus.ClaimMade,
  ];

  const activities =
    submission.status === SubmissionStatus.SubmissionAccepted
      ? [SubmissionActivity.Claim]
      : [SubmissionActivity.Claim, SubmissionActivity.ClaimDocumentsExtension];

  return (
    !submission.locked ||
    (allowedStatuses.includes(submission.status) &&
      activities.some((activity) =>
        isActivityAllowed(activity, getPeriod(submission.periodId), submission.type, date),
      ))
  );
}

export function getModificationsCount(submission: ISubmission): number {
  if (!submission || !submission.claim) {
    return 0;
  }
  if ([SubmissionType.Cu, SubmissionType.If].includes(submission.type)) {
    return getCoursesClaimModificationsCount(submission.claim as ICoursesClaim);
  } else {
    return getActivityClaimModificationsCount(submission.claim as IActivityClaim);
  }
}
