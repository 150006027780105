export enum Environments {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export interface IEnvironment {
  env: Environments.Development | Environments.Staging | Environments.Production;
  storageKey: string;
  apiEndpoint: string;
  sessionTimeout: number;
  microsoftAuth: {
    tenant: string;
  };
  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
}

const nodeEnv = process.env.REACT_APP_NODE_ENV as Environments;

export const environment: IEnvironment = {
  env: nodeEnv || Environments.Development,
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT || '',
  storageKey: '__appStorageKey__',
  sessionTimeout: +process.env.REACT_APP_SESSION_TIMEOUT,
  microsoftAuth: {
    tenant: process.env.REACT_APP_MICROSOFT_AUTH_TENANT,
  },
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_APP_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  },
};

export default environment;
