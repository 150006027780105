import './RecuperationLink.scss';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Form,
  FormActionButton,
  FormActionButtonType,
  FormContext,
  FormTextField,
} from '@novo-electronique/react-forms';
import i18n from 'i18next';
import * as Yup from 'yup';

interface IProps {
  onSubmit: (email: string) => Promise<void>;
}

function RecuperationLink({ onSubmit }: IProps) {
  const { t } = useTranslation();
  const [isLinkSent, setLinkSent] = useState(false);

  const onValuesSubmit = async (values: { email: string }) => {
    await onSubmit(values.email)
      .then(() => {
        toast.success(i18n.t('submissionRecuperationPage.linkSentMessage'));
        setLinkSent(true);
      })
      .catch(() => toast.error(i18n.t('submissionRecuperationPage.linkErrorMessage')));
  };

  const initialValues = { email: '' };
  const validationSchema = Yup.object({
    email: Yup.string().email().required(),
  });

  return (
    <>
      {(t('submissionRecuperationPage.precisions', { returnObjects: true }) as string[]).map(
        (precision, i) => (
          <p
            key={i}
            className="cpdSiteLayout__panel__content__text cpdSiteLayout__panel__content__text--strong"
          >
            {precision}
          </p>
        ),
      )}

      <p className="cpdSiteLayout__panel__content__text">{t('submissionRecuperationPage.instruction')}</p>
      <div className="cpdRecuperationLinkForm">
        <FormContext
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onValuesSubmit}
        >
          <Form disabled={isLinkSent}>
            <FormTextField name="email" label={t('submission.email')} type="email" width={100} autoFocus />
            <FormActionButton type={FormActionButtonType.Submit} suppressErrorTooltip>
              {t('submissionRecuperationPage.getLink')}
            </FormActionButton>
          </Form>
        </FormContext>
      </div>
    </>
  );
}

export default RecuperationLink;
