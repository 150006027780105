import React from 'react';
import { useTranslation } from 'react-i18next';

import ImportantDatesAdmin from '@modules/settings/components/ImportantDatesAdmin';
import { AdminLayout } from '@modules/shared/components/Layout';

function ImportantDatesAdminPage() {
  const { t } = useTranslation();

  return (
    <AdminLayout>
      <header className="cpdAdminLayout__content__header">
        <h1 className="cpdAdminLayout__content__header__title">
          {t('importantDatesAdminPage.title')}
          <span className="cpdAdminLayout__content__header__title__secondary">
            {t('importantDatesAdminPage.today', { date: new Date() })}
          </span>
        </h1>
      </header>

      <ImportantDatesAdmin />
    </AdminLayout>
  );
}

export default ImportantDatesAdminPage;
