import { IFileUploaded } from '@novo-electronique/react-dropzone';
import { ThunkDispatch } from 'redux-thunk';

import { User } from '@common/models/user';
import { EntityValueProvider } from '@common/value-provider';
import { IEntityValue } from '@common/value-provider/entity-value';
import {
  setAssociationEntity,
  setCacheLoaded,
  setLocalSectionEntity,
  setServiceCenterEntity,
} from '@modules/app/redux/action';
import {
  formatAssociation,
  formatLocalSection,
  formatServiceCenter,
  getAssociations,
  getLocalSections,
  getServiceCenters,
} from '@modules/associations/service';
import apiClient from '@modules/shared/api-client';

export function getCurrentUser(): Promise<User> {
  return apiClient.get<User>('/user/current');
}

export function getFileUrl(attachmentId: string): Promise<string> {
  return apiClient.get<string>(`/attachment/${attachmentId}/file/url`);
}

export function downloadFile(attachmentId: string): Promise<void> {
  return apiClient.download(`/attachment/${attachmentId}/file`);
}

export function uploadFile(file: IFileUploaded): Promise<string> {
  return apiClient.uploadFiles('/attachment', [file]).then((attachments) => attachments[0].id);
}

export function removeFile(file: IFileUploaded): Promise<void> {
  return apiClient.delete(`/attachment/${file.id}`);
}

export function refreshCache() {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch(setCacheLoaded(false));
    try {
      await Promise.all([
        getAssociations().then(async (associations) => {
          const valueProvider = await getValueProvider(
            associations.map((association) => formatAssociation(association)),
          );
          dispatch(
            setAssociationEntity({
              valueProvider,
              data: associations,
            }),
          );
        }),
        getLocalSections().then(async (sections) => {
          const valueProvider = await getValueProvider(
            sections.map((section) => formatLocalSection(section)),
          );
          dispatch(
            setLocalSectionEntity({
              valueProvider,
              data: sections,
            }),
          );
        }),
        getServiceCenters().then(async (serviceCenters) => {
          const valueProvider = await getValueProvider(
            serviceCenters.map((serviceCenter) => formatServiceCenter(serviceCenter)),
          );
          dispatch(
            setServiceCenterEntity({
              valueProvider,
              data: serviceCenters,
            }),
          );
        }),
      ]);

      dispatch(setCacheLoaded(true));
    } catch (e: any) {
      console.error(e);
    }
  };
}

async function getValueProvider(values: IEntityValue[]) {
  const valueProvider = new EntityValueProvider(() => values);
  await valueProvider.init();

  return valueProvider;
}
