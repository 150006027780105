import { PaymentMethod } from '@common/constants/payment-method';
import { Province } from '@common/constants/province';
import { IAttachment } from '@common/models/attachment';
import { ISubmission } from './submission';

export interface IPaymentInfo {
  method: PaymentMethod;

  // Direct deposit
  bankTransitNumber?: string;
  bankInstitutionNumber?: string;
  bankAccountNumber?: string;
  specimenCheque?: Partial<IAttachment>[];

  // Check
  addrCivicNumber?: string;
  addrStreet?: string;
  addrApartment?: string;
  addrCity?: string;
  addrProvince?: Province;
  addrPostalCode?: string;
}

export const getPaymentInfoValidationSchema = (Yup) => {
  // Only require fields that are associated with a specific payment method
  const applyConditionToValidation = (
    baseSchema: { [key: string]: any },
    condition: (method: PaymentMethod, bankTransitionNumber?: string) => boolean,
  ): { [key: string]: any } => {
    return Object.keys(baseSchema).reduce((conditionalValidation, key) => {
      const item = baseSchema[key];

      conditionalValidation[key] = item
        .when(['method', 'bankTransitNumber'], {
          is: condition,
          then: item.min(1).required(),
        })
        .meta({ required: true });

      return conditionalValidation;
    }, {});
  };

  const directDepositValidation = {
    specimenCheque: Yup.array().max(1),
  };

  const checkValidation = {
    addrCivicNumber: Yup.string().max(10),
    addrStreet: Yup.string().max(100),
    addrCity: Yup.string().max(50),
    addrProvince: Yup.string().oneOf(Object.values(Province)),
    addrPostalCode: Yup.string()
      .max(7)
      .matches(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, { excludeEmptyString: true }),
  };

  return Yup.object().shape({
    method: Yup.string().oneOf(Object.values(PaymentMethod)).required(),

    addrApartment: Yup.string().max(3),

    bankTransitNumber: Yup.string()
      .nullable()
      .transform((o, c) => (o === '' ? null : c))
      .matches(/^\d{5}$/, { excludeEmptyString: true }),
    bankInstitutionNumber: Yup.string()
      .nullable()
      .transform((o, c) => (o === '' ? null : c))
      .matches(/^\d{3}$/, { excludeEmptyString: true }),
    bankAccountNumber: Yup.string()
      .nullable()
      .transform((o, c) => (o === '' ? null : c))
      .matches(/^\d{6}\d?\d?\d?\d?\d?\d?$/, {
        excludeEmptyString: true,
      }),

    ...applyConditionToValidation(checkValidation, (method) => method === PaymentMethod.Check),
    ...applyConditionToValidation(
      directDepositValidation,
      (method, bankTransitNumber) => method === PaymentMethod.DirectDeposit && !bankTransitNumber,
    ),
  });
};

export const getPaymentInfoInitialValues = (submission: ISubmission): IPaymentInfo => {
  return {
    method: submission.claim?.method || undefined,
    bankTransitNumber: submission.claim?.bankTransitNumber || '',
    bankInstitutionNumber: submission.claim?.bankInstitutionNumber || '',
    bankAccountNumber: submission.claim?.bankAccountNumber || '',
    specimenCheque: submission.claim?.specimenCheque || [],
    addrCivicNumber: submission.claim?.addrCivicNumber || '',
    addrStreet: submission.claim?.addrStreet || '',
    addrApartment: submission.claim?.addrApartment || '',
    addrCity: submission.claim?.addrCity || '',
    addrProvince: submission.claim?.addrProvince || Province.Qc,
    addrPostalCode: submission.claim?.addrPostalCode || '',
  };
};
