import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { allowedTypesForModifications } from '@common/constants/global';
import { SubmissionType } from '@common/constants/submission-type';
import { ISubmission } from '@common/models/submission';
import StatusBox, { Status } from '@modules/shared/components/StatusBox';

export enum ConfirmationContext {
  Default = '',
  SubmissionUpdated = 'submission-updated',
  Claim = 'claim',
}

interface IProps {
  submission: ISubmission;
  context?: ConfirmationContext;
}

function SubmissionConfirmation({ submission, context = ConfirmationContext.Default }: IProps) {
  const { t } = useTranslation();

  const steps = t('submissionConfirmation.steps', {
    returnObjects: true,
    email: submission.email,
    context,
  }) as string[];

  if (context !== ConfirmationContext.Claim && allowedTypesForModifications.includes(submission.type)) {
    steps.push(t('submissionConfirmation.extraStep'));
  }

  return (
    <>
      <StatusBox
        status={Status.Success}
        title={t('confirmBox.title', { context })}
        description={
          <Trans i18nKey={'confirmBox.text'} values={{ code: submission.code }}>
            <strong />
          </Trans>
        }
      />
      {submission.type === SubmissionType.Cu && !submission.claim && (
        <Trans
          i18nKey="submissionConfirmation.optionalMessage_CU"
          components={{
            p: <p className="cpdSiteLayout__panel__content__text" />,
          }}
        />
      )}
      <h3 className="cpdSiteLayout__panel__content__subtitle">{t('submissionConfirmation.subTitle')}</h3>
      <ul className="cpdSiteLayout__panel__content__list">
        {steps.map((step, i) => (
          <li key={i} className="cpdSiteLayout__panel__content__list__item">
            {step}
          </li>
        ))}
      </ul>
      <p className="cpdSiteLayout__panel__content__text">
        {t('submissionConfirmation.thank')}
        <br /> <strong>{t('submissionConfirmation.team')}</strong>
      </p>
    </>
  );
}

export default SubmissionConfirmation;
