import './AboutPage.scss';

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SubmissionActivity, SubmissionActivityStatus } from '@common/constants/submission-activity';
import { SubmissionType } from '@common/constants/submission-type';
import { getActivityStatus, isActivityAllowed } from '@common/utils/submission-period';
import { selectCurrentPeriod, selectNextPeriod } from '@modules/app/redux/selectors';
import { SiteLayout } from '@modules/shared/components/Layout';

export const datesAnchorName = 'cpd-dates';

function AboutPage() {
  const { t } = useTranslation();
  const currentPeriod = useSelector(selectCurrentPeriod);
  const nextPeriod = useSelector(selectNextPeriod);

  const isIfAllowed = isActivityAllowed(SubmissionActivity.Submission, currentPeriod, SubmissionType.If);
  const claimActivityStatus = getActivityStatus(SubmissionActivity.Claim, currentPeriod);
  const submissionActivityStatus = getActivityStatus(SubmissionActivity.Submission, currentPeriod, [
    SubmissionType.Cu,
    SubmissionType.Pca,
    SubmissionType.Pp,
  ]);

  const params = {
    year1: currentPeriod.years[0],
    year2: currentPeriod.years[1],
    nextYear1: nextPeriod.years[0],
    nextYear2: nextPeriod.years[1],
  };

  return (
    <SiteLayout title={t('aboutPage.title')} decoration>
      <Trans
        i18nKey="aboutPage.description"
        components={{
          p: <p className="cpdSiteLayout__panel__content__text" />,
          ul: <ul className="cpdSiteLayout__panel__content__list" />,
          li: <li className="cpdSiteLayout__panel__content__list__item" />,
        }}
      />
      <article className="cpdImportantDates" id={datesAnchorName}>
        <h2 className="cpdImportantDates__title">{t('aboutPage.importantDates.title')}</h2>

        <section className="cpdImportantDates__section">
          <h3 className="cpdImportantDates__section__title">
            {t('aboutPage.importantDates.submission.title')}
          </h3>
          <p className="cpdImportantDates__section__text">
            <Trans
              i18nKey="aboutPage.importantDates.submission.description"
              components={{
                p: <span className="cpdSiteLayout__panel__content__text" />,
              }}
              values={{
                ...params,
                context: submissionActivityStatus,
                date1: currentPeriod.dates[SubmissionActivity.Submission][0],
                date2: currentPeriod.dates[SubmissionActivity.Submission][1],
                nextDate1: nextPeriod.dates[SubmissionActivity.Submission][0],
                date3: currentPeriod.newPrincipalsDates.submission[0],
                date4: currentPeriod.newPrincipalsDates.submission[1],
              }}
            />
          </p>
          {isIfAllowed && submissionActivityStatus !== SubmissionActivityStatus.Ongoing && (
            <article className="cpdImportantDatesPanel">
              <h4 className="cpdImportantDatesPanel__title">{t('ifPrecision.title')}</h4>
              <p className="cpdImportantDates__section__text">
                <strong>{t('ifPrecision.scope', params)}</strong>
              </p>
              <p className="cpdImportantDates__section__text">
                {t('ifPrecision.extension', {
                  ...params,
                  context: claimActivityStatus,
                  date1: currentPeriod.extensions[SubmissionActivity.Submission][SubmissionType.If][0],
                  date2: currentPeriod.extensions[SubmissionActivity.Submission][SubmissionType.If][1],
                })}
              </p>
              <p className="cpdImportantDates__section__text">{t('ifPrecision.precision')}</p>
            </article>
          )}
        </section>

        <section className="cpdImportantDates__section">
          <h3 className="cpdImportantDates__section__title">{t('aboutPage.importantDates.claim.title')}</h3>
          <p className="cpdImportantDates__section__text">
            <Trans
              i18nKey={'aboutPage.importantDates.claim.description'}
              components={{
                p: <span className="cpdSiteLayout__panel__content__text" />,
              }}
              values={{
                ...params,
                context: claimActivityStatus,
                date1: currentPeriod.dates[SubmissionActivity.Claim][0],
                date2: currentPeriod.dates[SubmissionActivity.Claim][1],
                date3: currentPeriod.dates[SubmissionActivity.ClaimDocumentsExtension][1],
              }}
            />
          </p>
        </section>
      </article>
    </SiteLayout>
  );
}

export default AboutPage;
