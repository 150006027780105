import { SubmissionActivity } from '@common/constants/submission-activity';
import { IAssociation } from '@common/models/association';
import { ILocalSection } from '@common/models/local-section';
import { IServiceCenter } from '@common/models/service-center';
import { IAllPeriods, ISubmissionPeriod } from '@common/models/submission-period';
import { User } from '@common/models/user';
import { isActivityAllowed, isSubmissionExtension } from '@common/utils/submission-period';
import IValueProvider from '@common/value-provider';
import { ICache } from '@modules/app/redux/state';
import { IRootState } from '@src/redux/root.state';

export const selectIsAppLoading = (state: IRootState): boolean => state.app.loadingCount > 0;
export const selectVersion = (state: IRootState): string => state.app.version;
export const selectUser = (state: IRootState): User => state.app.user;
export const selectIsAuthenticationChecked = (state: IRootState): boolean =>
  state.app.isAuthenticationChecked;

export const selectAllPeriods = (state: IRootState): IAllPeriods => ({
  previousPeriod: state.app.periods.previous,
  currentPeriod: selectCurrentPeriod(state),
  nextPeriod: selectNextPeriod(state),
});
export const selectCurrentPeriod = (state: IRootState): ISubmissionPeriod => state.app.periods.current;
export const selectNextPeriod = (state: IRootState): ISubmissionPeriod => state.app.periods.next;

export const selectIsSubmissionAllowed = (state: IRootState): boolean =>
  isActivityAllowed(SubmissionActivity.Submission, state.app.periods.current);

export const selectIsSubmissionExtension = (state: IRootState): boolean =>
  isSubmissionExtension(state.app.periods.current);

export const selectIsClaimAllowed = (state: IRootState): boolean =>
  [state.app.periods.previous, state.app.periods.current].some(
    (period) =>
      isActivityAllowed(SubmissionActivity.Claim, period) ||
      isActivityAllowed(SubmissionActivity.ClaimDocumentsExtension, period),
  );

export const selectIsCacheLoaded = (state: IRootState): boolean => state.app.cache.isLoaded;
export const selectCache = (state: IRootState): ICache => state.app.cache;

export const selectAssociations = (state: IRootState): IAssociation[] => state.app.cache.association.data;
export const selectLocalSections = (state: IRootState): ILocalSection[] => state.app.cache.localSection.data;
export const selectServiceCenters = (state: IRootState): IServiceCenter[] =>
  state.app.cache.serviceCenter.data;

export const selectAssociationValueProvider = (state: IRootState): IValueProvider =>
  state.app.cache.association.valueProvider;
export const selectLocalSectionValueProvider = (state: IRootState): IValueProvider =>
  state.app.cache.localSection.valueProvider;
export const selectServiceCenterProvider = (state: IRootState): IValueProvider =>
  state.app.cache.serviceCenter.valueProvider;
