import { IAuthAccount } from '@common/models/auth-account';

export enum AuthActionsTypes {
  SetIdentity = '[AUTH] Set identity',
  ResetIdentity = '[AUTH] Reset identity',
}

export interface ISetIdentity {
  type: typeof AuthActionsTypes.SetIdentity;
  payload: IAuthAccount;
}

export const setIdentity = (authAccount: IAuthAccount): ISetIdentity => ({
  type: AuthActionsTypes.SetIdentity,
  payload: authAccount,
});

export interface IResetIdentity {
  type: typeof AuthActionsTypes.ResetIdentity;
}

export const resetIdentity = (): IResetIdentity => ({
  type: AuthActionsTypes.ResetIdentity,
});

export type AuthActionTypes = ISetIdentity | IResetIdentity;
