import React from 'react';
import classNames from 'classnames';

import { SubmissionStatus } from '@common/constants/submission-status';
import { submissionStatusValueProvider } from '@common/value-provider';

interface IProps {
  children: SubmissionStatus;
  fullWidth?: boolean;
}

function SubmissionStatusTag({ children, fullWidth = false }: IProps) {
  const className = classNames('cpdTag', {
    'cpdTag--full-width': fullWidth,
    'cpdTag--success': [SubmissionStatus.NewSubmission, SubmissionStatus.ClaimMade].includes(children),
    'cpdTag--danger': [SubmissionStatus.SubmissionRefused, SubmissionStatus.ClaimRefused].includes(children),
    'cpdTag--info': children === SubmissionStatus.ClaimPaid,
    'cpdTag--primary': [SubmissionStatus.SubmissionAccepted, SubmissionStatus.ClaimAccepted].includes(
      children,
    ),
    'cpdTag--warning': [
      SubmissionStatus.SubmissionInProcessing,
      SubmissionStatus.ClaimWaitingForDocuments,
      SubmissionStatus.SubmissionWaitingForDocuments,
    ].includes(children),
    'cpdTag--neutral': [
      SubmissionStatus.SubmissionCancelled,
      SubmissionStatus.SubmissionNotRealised,
    ].includes(children),
  });

  return <span className={className}>{submissionStatusValueProvider.label(children)}</span>;
}

export default SubmissionStatusTag;
