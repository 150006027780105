import * as Yup from 'yup';

import { ActivityDuration } from '@common/constants/activity-duration';
import {
  activityAllowedAmount,
  encountersAllowedNumberMap,
  firstYearVersion2,
} from '@common/constants/global';
import { Language } from '@common/constants/language';
import { SubmissionType } from '@common/constants/submission-type';
import { getPaymentValidationSchema } from '@common/models/payment';
import {
  getActivityInitialValues,
  getCoursesInitialValues,
  getCuSubmissionSchema,
  getIfSubmissionSchema,
  getPcaActivitySubmissionSchema,
  getPpActivitySubmissionSchema,
  getSubmissionInitialValues,
  getSubmissionSchema,
  ISubmission,
} from '@common/models/submission';
import { ISubmissionPeriod } from '@common/models/submission-period';
import { getValidationSchemaFromType as getClaimValidationSchemaFromType } from '@common/utils/claim';
import { getYearsFromPeriodId } from '@common/utils/submission-period';

export function getValidationSchemaFromType(
  Yup,
  type?: SubmissionType | null,
  version = 1,
  includeAllProperties = false,
  isAuthenticated = false,
): Yup.ObjectSchema<any> {
  switch (type) {
    case SubmissionType.Cu:
      return getCuSubmissionSchema(Yup, version, includeAllProperties, isAuthenticated);
    case SubmissionType.If:
      return getIfSubmissionSchema(Yup, version, includeAllProperties, isAuthenticated);
    case SubmissionType.Pca:
      return getPcaActivitySubmissionSchema(Yup, version, includeAllProperties);
    case SubmissionType.Pp:
      return getPpActivitySubmissionSchema(Yup, version, includeAllProperties);
    default:
      return getSubmissionSchema(Yup, version, includeAllProperties);
  }
}

export function getEditionValidationSchemaFromType(
  Yup,
  type: SubmissionType,
  submission: ISubmission,
  isAuthenticated = true,
): Yup.ObjectSchema<any> {
  return Yup.object({
    ...getValidationSchemaFromType(Yup, type, submission.version, true, isAuthenticated).fields,
    language: Yup.string().oneOf(Object.values(Language)).required(),
    claim:
      submission.claim &&
      Yup.object().shape({
        ...getClaimValidationSchemaFromType(Yup, submission, true).fields,
        payment: getPaymentValidationSchema(Yup, submission.claim),
      }),
  });
}

export function getInitialValuesFromType(
  type: SubmissionType | null,
  currentValues: unknown = {},
): ISubmission {
  switch (type) {
    case SubmissionType.Cu:
    case SubmissionType.If:
      return getCoursesInitialValues(currentValues);
    case SubmissionType.Pca:
    case SubmissionType.Pp:
      return getActivityInitialValues(currentValues);
    default:
      return getSubmissionInitialValues(currentValues);
  }
}

export function getVersion(type: SubmissionType, period: ISubmissionPeriod): number {
  const [startYear] = getYearsFromPeriodId(period.id);

  const isVersion2 =
    startYear >= firstYearVersion2 &&
    (type === SubmissionType.Pca || type === SubmissionType.Pp || type === SubmissionType.Cu);

  return isVersion2 ? 2 : 1;
}

export function getEncountersAllowedNumber(duration: ActivityDuration): number {
  return (
    encountersAllowedNumberMap[duration] ??
    encountersAllowedNumberMap[ActivityDuration.MoreThanThreeOrLessThanSixHours]
  );
}

export function computeActivityAmountRequested(
  type: SubmissionType.Pp | SubmissionType.Pca,
  duration: ActivityDuration,
  participantsCount: number,
  encountersNumber?: number,
): number {
  const amount = activityAllowedAmount[type][duration] * participantsCount;
  return type === SubmissionType.Pp
    ? amount * Math.min(encountersNumber, getEncountersAllowedNumber(duration))
    : amount;
}
