import { ActivityType } from '@common/constants/activity-type';
import { SubmissionType } from '@common/constants/submission-type';
import { IAttachment } from '@common/models/attachment';
import { getClaimInitialValues, getClaimSchema, IClaim } from '@common/models/claim/claim';
import { IActivitySubmission } from '../submission';

export interface IActivityClaim extends IClaim {
  activityType: ActivityType;
  activityLocation?: string;

  participantsCount: number;
  participantsList?: string;
  participantsListFile?: IAttachment[];

  hasChanges: boolean;
  changesDescription: string;

  preparationAndAnimationFees: number;
  subsistenceFees: number;
  travelFees: number;
  teachingMaterialFees: number;
  roomRentalFees: number;
  equipmentRentalFees: number;

  preparationAndAnimationFeesApproved: number;
  subsistenceFeesApproved: number;
  travelFeesApproved: number;
  teachingMaterialFeesApproved: number;
  roomRentalFeesApproved: number;
  equipmentRentalFeesApproved: number;

  completionDate: Date;

  submission?: IActivitySubmission;
}

export const getActivityClaimSchema = (Yup, submission: IActivitySubmission, includeAllProperties: boolean) =>
  Yup.object().shape({
    ...getClaimSchema(Yup).fields,
    activityType: Yup.string().oneOf(Object.values(ActivityType)).required(),
    activityLocation: Yup.mixed()
      .when('activityType', (type) =>
        type === ActivityType.Presential ? Yup.string().max(150).required() : Yup.mixed(),
      )
      .meta({ required: true }),
    participantsCount: Yup.number().required().min(1),
    participantsList: Yup.string().required().min(1),
    hasChanges: Yup.bool().oneOf([true, false]).required(),
    changesDescription: Yup.mixed()
      .when(['hasChanges'], {
        is: true,
        then: Yup.string().required(),
      })
      .meta({ required: true }),
    preparationAndAnimationFees: Yup.number().min(0),
    subsistenceFees: Yup.number().min(0),
    travelFees: Yup.number().min(0),
    teachingMaterialFees: Yup.number().min(0),
    roomRentalFees: Yup.number().min(0),
    equipmentRentalFees: Yup.number().min(0),
    completionDate: Yup.date().required(),

    ...(includeAllProperties && {
      preparationAndAnimationFeesApproved: Yup.number().min(0).nullable().meta({ required: true }),
      subsistenceFeesApproved: Yup.number().min(0).nullable().meta({ required: true }),
      travelFeesApproved: Yup.number().min(0).nullable().meta({ required: true }),
      teachingMaterialFeesApproved: Yup.number().min(0).nullable().meta({ required: true }),
      roomRentalFeesApproved: Yup.number().min(0).nullable().meta({ required: true }),
      equipmentRentalFeesApproved: Yup.number().min(0).nullable().meta({ required: true }),
    }),

    ...(submission.version > 1 && {
      participantsList: undefined,
      participantsCount: Yup.number()
        .required()
        .min(submission.type === SubmissionType.Pca ? 2 : 1),
      participantsListFile: Yup.mixed()
        .when('participantsCount', (count) =>
          count > 1 ? Yup.array().min(1).max(1).required() : Yup.array(),
        )
        .meta({ required: true }),
    }),
  });

export const getActivityClaimInitialValues = (submission: IActivitySubmission): IActivityClaim => ({
  ...getClaimInitialValues(submission),
  activityType: submission.claim?.activityType || submission.activityType,
  activityLocation: submission.claim?.activityLocation || submission.activityLocation,
  participantsCount: submission.claim?.participantsCount || submission.participantsCount,
  participantsList: submission.claim?.participantsList || '',
  participantsListFile: submission.claim?.participantsListFile || [],
  completionDate: submission.claim?.completionDate || submission.completionDate,
  hasChanges: submission.claim?.hasChanges,
  changesDescription: submission.claim?.changesDescription || '',
  preparationAndAnimationFees: submission.claim?.preparationAndAnimationFees,
  subsistenceFees: submission.claim?.subsistenceFees,
  travelFees: submission.claim?.travelFees,
  teachingMaterialFees: submission.claim?.teachingMaterialFees,
  roomRentalFees: submission.claim?.roomRentalFees,
  equipmentRentalFees: submission.claim?.equipmentRentalFees,
  preparationAndAnimationFeesApproved: submission.claim?.preparationAndAnimationFees,
  subsistenceFeesApproved: submission.claim?.subsistenceFees,
  travelFeesApproved: submission.claim?.travelFees,
  teachingMaterialFeesApproved: submission.claim?.teachingMaterialFees,
  roomRentalFeesApproved: submission.claim?.roomRentalFees,
  equipmentRentalFeesApproved: submission.claim?.equipmentRentalFees,
  submission,
});
