import { AuthActionsTypes, AuthActionTypes } from './action';
import { IAuthState, initialAuthState } from './state';

const authReducer = (state: IAuthState = initialAuthState, action: AuthActionTypes): IAuthState => {
  switch (action.type) {
    case AuthActionsTypes.SetIdentity:
      return {
        ...state,
        identity: action.payload,
      };
    case AuthActionsTypes.ResetIdentity:
      return {
        ...state,
        identity: initialAuthState.identity,
      };
    default:
      return state;
  }
};

export default authReducer;
