import 'firebase/auth';

import firebase from 'firebase/app';

import { environment } from '@config/env';

export const configureFirebase = (): firebase.app.App => {
  const firebaseApp = firebase.initializeApp(environment.firebase);
  firebaseApp.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

  return firebaseApp;
};
