import { DataType } from '@common/constants/data-type';
import { createEnumValueProvider } from '@common/value-provider/enum-value-provider-factory';
import EntityValueProvider from './entity-value-provider';
import EnumValueProvider from './enum-value-provider';
import IValueProvider from './value-provider';

type enumDataTypes =
  | DataType.ActivityDuration
  | DataType.ActivityType
  | DataType.MeetingType
  | DataType.ProjectType
  | DataType.ProgramStatus
  | DataType.Role
  | DataType.Skill
  | DataType.SkillDimension
  | DataType.SubmissionType
  | DataType.SubmissionStatus
  | DataType.University
  | DataType.UniversitySemester
  | DataType.Language
  | DataType.Province;

const enumAllowedTypes: enumDataTypes[] = [
  DataType.ActivityType,
  DataType.MeetingType,
  DataType.ProjectType,
  DataType.ProgramStatus,
  DataType.Role,
  DataType.Skill,
  DataType.SkillDimension,
  DataType.SubmissionType,
  DataType.SubmissionStatus,
  DataType.University,
  DataType.UniversitySemester,
  DataType.Language,
  DataType.Province,
];

type entityDataTypes = DataType.Association | DataType.LocalSection | DataType.ServiceCenter;
const entityAllowedTypes: entityDataTypes[] = [
  DataType.Association,
  DataType.LocalSection,
  DataType.ServiceCenter,
];

const activityDurationValueProvider = createEnumValueProvider(DataType.ActivityDuration);
const activityTypeValueProvider = createEnumValueProvider(DataType.ActivityType);
const meetingTypeValueProvider = createEnumValueProvider(DataType.MeetingType);
const projectTypeValueProvider = createEnumValueProvider(DataType.ProjectType);
const programStatusValueProvider = createEnumValueProvider(DataType.ProgramStatus);
const roleValueProvider = createEnumValueProvider(DataType.Role);
const skillValueProvider = createEnumValueProvider(DataType.Skill);
const skillDimensionValueProvider = createEnumValueProvider(DataType.SkillDimension);
const submissionTypeValueProvider = createEnumValueProvider(DataType.SubmissionType);
const submissionStatusValueProvider = createEnumValueProvider(DataType.SubmissionStatus);
const universityValueProvider = createEnumValueProvider(DataType.University);
const universitySemesterValueProvider = createEnumValueProvider(DataType.UniversitySemester);
const languageValueProvider = createEnumValueProvider(DataType.Language);
const provinceValueProvider = createEnumValueProvider(DataType.Province);

export default IValueProvider;
export {
  activityDurationValueProvider,
  activityTypeValueProvider,
  createEnumValueProvider,
  entityAllowedTypes,
  EntityValueProvider,
  enumAllowedTypes,
  EnumValueProvider,
  languageValueProvider,
  meetingTypeValueProvider,
  programStatusValueProvider,
  projectTypeValueProvider,
  provinceValueProvider,
  roleValueProvider,
  skillDimensionValueProvider,
  skillValueProvider,
  submissionStatusValueProvider,
  submissionTypeValueProvider,
  universitySemesterValueProvider,
  universityValueProvider,
};
export type { entityDataTypes, enumDataTypes };
