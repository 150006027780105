export enum Skill {
  StructureSchoolOrganization = 'structure-school-organization',
  SupportEducationalPractices = 'support-educational-practices',
  AssistGoverningBoard = 'assist-governing-board',
  LeadSchoolPlanDevelopment = 'lead-school-plan-development',
  SupportCollaborationAndPartnerships = 'support-collaboration-and-partnerships',
  EnsureCompetentActInPractice = 'ensure-competent-act-in-practice',
  EnsureCompetentActionOfEachTeam = 'ensure-competent-action-of-each-team',
  EnsureSkillsDevelopment = 'ensure-skills-development',
  ManageFinancialResourcesEffectively = 'manage-financial-resources-effectively',
  ManageMaterialResourcesEffectively = 'manage-material-resources-effectively',
  ManageHumanResourcesEffectively = 'manage-human-resources-effectively',
  ImprovementReflectivePractices = 'improvement-of-reflective-practices',
  ImproveKnowledgeLawsRelatingEducationSector = 'improve-knowledge-laws-relating-education-sector',
}
