import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormFields,
  FormPhoneField,
  FormSelectField,
  FormSelectFieldOptions,
  FormTextField,
} from '@novo-electronique/react-forms';

import { submissionTypeValueProvider } from '@common/value-provider';
import { valueProviderToOptions } from '@modules/shared/components/Form';

function ClaimGeneralInformationSection() {
  const { t } = useTranslation();
  return (
    <FormFields title={t('submissionPage.userGeneralInfo')} readonly={true}>
      <FormTextField name="firstName" label={t('submission.firstName')} />
      <FormTextField name="lastName" label={t('submission.lastName')} />
      <FormPhoneField name="phoneNumber" label={t('submission.phoneNumber')} />
      <FormTextField name="email" type="email" label={t('submission.email')} />
      <div className="cpdVisibleForPrint">
        <FormSelectField name="type" label={t('submissionPage.submissionType')}>
          <FormSelectFieldOptions
            options={valueProviderToOptions(submissionTypeValueProvider)}
            keepOriginalOrder
            addEmptyOption={false}
          />
        </FormSelectField>
      </div>
      <FormTextField name="code" label={t('submission.code')} />
    </FormFields>
  );
}

export default ClaimGeneralInformationSection;
