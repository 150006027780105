import './Modal.scss';

import React, { ReactNode, ReactNodeArray, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Blanket } from '@novo-electronique/react-blanket';
import classNames from 'classnames';

const container = document.getElementById('cpd-modal');

interface IProps {
  title: string;
  children: string | ReactNode | ReactNodeArray;
  footer?: ReactNode | ReactNodeArray;
  onClose?: () => void;
  isHost?: boolean;
  maximize?: boolean;
}

let modalId = 0;
const activeModalIds: number[] = [];

function Modal({ title, children, footer, onClose, isHost, maximize }: IProps) {
  const blanketRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLElement>(null);
  const modalIdRef = useRef<number>(null);
  const onCloseHandler = () => onClose && onClose();

  useEffect(() => {
    modalIdRef.current = ++modalId;
    activeModalIds.push(modalIdRef.current);

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Escape' && modalIdRef.current === activeModalIds[activeModalIds.length - 1]) {
        onCloseHandler();
      }
    };

    document.body.style.overflow = 'hidden';
    document.addEventListener('keydown', onKeyDown);

    return () => {
      activeModalIds.pop();
      document.body.style.overflow = 'visible';
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  const onMouseDown = (event: any) => {
    if (blanketRef.current.contains(event.target) && !modalRef.current.contains(event.target)) {
      onCloseHandler();
    }
  };

  const containerClassName = classNames('cpdModalContainer', { 'cpdModalContainer--maximize': maximize });
  const contentClassName = classNames('cpdModal__content', { 'cpdModal__content--host': isHost });
  return createPortal(
    <Blanket onMouseDown={onMouseDown} ref={blanketRef}>
      <div className={containerClassName}>
        <article className="cpdModal" role="dialog" ref={modalRef}>
          <header className="cpdModal__header">
            <button className="cpdModal__header__close material-icons" onClick={onCloseHandler}>
              close
            </button>
            <h1 className="cpdModal__header__title">{title}</h1>
          </header>
          <div className={contentClassName}>{children}</div>
          {footer && <footer className="cpdModal__footer">{footer}</footer>}
        </article>
      </div>
    </Blanket>,
    container,
  );
}

export default Modal;
