import React from 'react';

import { SiteRoutePage } from '@constants/routes';
import { RouteConfig } from '@modules/shared/route';
import AboutPage from '@modules/site/AboutPage';
import ContactPage from '@modules/site/ContactPage';
import HomePage from '@modules/site/HomePage';

export default [
  { path: SiteRoutePage.Home, component: <HomePage /> },
  { path: SiteRoutePage.About, component: <AboutPage /> },
  { path: SiteRoutePage.Contact, component: <ContactPage /> },
] as RouteConfig[];
