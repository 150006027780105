import { ActivityDuration } from '@common/constants/activity-duration';
import { ActivityType } from '@common/constants/activity-type';
import { DataType } from '@common/constants/data-type';
import { Language } from '@common/constants/language';
import { MeetingType } from '@common/constants/meeting-type';
import { ProgramStatus } from '@common/constants/program-status';
import { ProjectType } from '@common/constants/project-type';
import { Province } from '@common/constants/province';
import { Role } from '@common/constants/role';
import { Skill } from '@common/constants/skill';
import { SkillDimension } from '@common/constants/skill-dimension';
import { SubmissionStatus } from '@common/constants/submission-status';
import { SubmissionType } from '@common/constants/submission-type';
import { University, UniversitySemester } from '@common/constants/university';
import IValueProvider, { enumDataTypes, EnumValueProvider } from '@common/value-provider';

export const createEnumValueProvider = (type: enumDataTypes): IValueProvider => {
  switch (type) {
    case DataType.ActivityDuration:
      return new EnumValueProvider(ActivityDuration, 'enum.activityDuration');
    case DataType.ActivityType:
      return new EnumValueProvider(ActivityType, 'enum.activityType');
    case DataType.MeetingType:
      return new EnumValueProvider(MeetingType, 'enum.meetingType');
    case DataType.ProgramStatus:
      return new EnumValueProvider(ProgramStatus, 'enum.programStatus');
    case DataType.ProjectType:
      return new EnumValueProvider(ProjectType, 'enum.projectType');
    case DataType.Role:
      return new EnumValueProvider(Role, 'enum.role');
    case DataType.Skill:
      return new EnumValueProvider(Skill, 'enum.skill');
    case DataType.SkillDimension:
      return new EnumValueProvider(SkillDimension, 'enum.skillDimension');
    case DataType.SubmissionType:
      return new EnumValueProvider(SubmissionType, 'enum.submissionType');
    case DataType.SubmissionStatus:
      return new EnumValueProvider(SubmissionStatus, 'enum.submissionStatus');
    case DataType.University:
      return new EnumValueProvider(University, 'enum.university');
    case DataType.UniversitySemester:
      return new EnumValueProvider(UniversitySemester, 'enum.universitySemester');
    case DataType.Language:
      return new EnumValueProvider(Language, 'enum.language');
    case DataType.Province:
      return new EnumValueProvider(Province, 'enum.province');
  }
};
