import { IAuthAccount, IAuthAccountId } from '@common/models/auth-account';

export interface IUser {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  accountIds?: IAuthAccountId[];
  enabled: boolean;
}

const getUserBaseValidationSchema = (Yup) =>
  Yup.object({
    // personal
    firstName: Yup.string().max(150).required(),
    lastName: Yup.string().max(150).required(),
    email: Yup.string().email().required(),
    enabled: Yup.boolean().required(),
  });

export const getUserValidationSchema = (Yup) => getUserBaseValidationSchema(Yup);

export type User = Partial<IUser> & IAuthAccount;
