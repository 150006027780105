export enum SubmissionStatus {
  NewSubmission = 'new-submission',
  SubmissionInProcessing = 'submission-in-processing',
  SubmissionWaitingForDocuments = 'submission-waiting-for-docs',
  SubmissionAccepted = 'submission-accepted',
  SubmissionRefused = 'submission-refused',
  SubmissionCancelled = 'submission-cancelled',
  SubmissionNotRealised = 'submission-not-realised',
  ClaimMade = 'claim-made',
  ClaimWaitingForDocuments = 'claim-waiting-for-documents',
  ClaimAccepted = 'claim-accepted',
  ClaimRefused = 'claim-refused',
  ClaimPaid = 'claim-paid',
}

export const submissionStatuses = [
  SubmissionStatus.SubmissionInProcessing,
  SubmissionStatus.SubmissionAccepted,
  SubmissionStatus.SubmissionRefused,
  SubmissionStatus.SubmissionCancelled,
  SubmissionStatus.SubmissionNotRealised,
];

export const claimStatuses = [
  SubmissionStatus.ClaimMade,
  SubmissionStatus.ClaimWaitingForDocuments,
  SubmissionStatus.ClaimAccepted,
  SubmissionStatus.ClaimRefused,
];
