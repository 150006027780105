export enum DataType {
  Text = 'text',
  Number = 'number',
  Boolean = 'boolean',
  Amount = 'amount',
  PhoneNumber = 'phone-number',
  Date = 'date',
  Association = 'association',
  LocalSection = 'local-section',
  ServiceCenter = 'service-center',
  ActivityType = 'activity-type',
  MeetingType = 'meeting-type',
  ProjectType = 'project-type',
  ProgramStatus = 'program-status',
  Role = 'role',
  LockedState = 'locked-state',
  Skill = 'skill',
  SkillDimension = 'skill-dimension',
  SubmissionType = 'submission-type',
  SubmissionPeriod = 'submission-period',
  SubmissionStatus = 'submission-status',
  University = 'university',
  UniversitySemester = 'university-semester',
  Language = 'language',
  Province = 'province',
  ActivityDuration = 'duration',
}
