import * as Yup from 'yup';

import { PaymentMethod } from '@common/constants/payment-method';
import { IClaim } from '@common/models/claim';

export interface IPayment {
  id?: string;
  method?: PaymentMethod;
  amount: number;
  confirmationNo: string;
  transactionDate: Date;
}

export const getPaymentValidationSchema = (Yup, claim: IClaim): Yup.ObjectSchema<any> => {
  return Yup.object()
    .shape({
      amount: Yup.number().positive().max(claim.amountApproved).required(),
      confirmationNo: Yup.string().max(30).required(),
      transactionDate: Yup.date().required(),
    })
    .nullable();
};
