import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  FormAmountField,
  FormDataContext,
  FormFields,
  FormNumberField,
} from '@novo-electronique/react-forms';

import { SubmissionType } from '@common/constants/submission-type';
import { IActivitySubmission, ICoursesSubmission, ISubmission } from '@common/models/submission';
import { getInitialActivityClaimApprovedFees } from '@common/utils/claim';
import { formatCurrency } from '@common/utils/currency';
import { selectPeriodAmounts } from '@modules/settings/redux/selectors';
import { getCurrentAmount } from '@modules/settings/service';
import ActivityTableFees from './ActivityTableFees/ActivityTableFees';

function RevisionSection() {
  const { t } = useTranslation();
  const submission = useContext(FormDataContext).values as ISubmission;
  const periodAmounts = useSelector(selectPeriodAmounts);

  const type = submission.type;
  const paymentIsCreated = Boolean(submission.claim?.payment?.id);

  const renderCuIfSubmission = (submission: ICoursesSubmission) => {
    const amountPerCredit = getCurrentAmount(periodAmounts, submission.periodId);
    const hasAmountDefined = !paymentIsCreated || submission.claim.creditsCountApproved !== null;

    if (!paymentIsCreated) {
      submission.claim.amountApproved = submission.claim.creditsCountApproved * amountPerCredit;

      if (submission.claim.creditsCountApproved === null) {
        submission.claim.creditsCountApproved = submission.claim.creditsCount;
      }
    }

    return (
      <FormFields title={t('claim.actualEligibleCost')}>
        <FormNumberField
          name="claim.creditsCount"
          label={t('submission.creditsCountRequested')}
          width={hasAmountDefined ? 33 : 50}
          disabled
        />
        {hasAmountDefined && (
          <FormNumberField
            name="claim.creditsCountApproved"
            label={t('submission.creditsCountApproved')}
            width={33}
            min={0}
            max={submission.claim.creditsCount}
            disabled={paymentIsCreated}
          />
        )}
        <FormAmountField
          name="claim.amountApproved"
          label={t(
            'submission.amountApproved',
            !paymentIsCreated && { context: type, amount: amountPerCredit },
          )}
          width={hasAmountDefined ? 33 : 50}
          disabled
        />
      </FormFields>
    );
  };

  const renderPcaPpSubmission = (submission: IActivitySubmission) => {
    submission.claim = getInitialActivityClaimApprovedFees(submission.claim);
    console.log(submission.amountRequested);
    return (
      <>
        <p className="cpdRevisionClaimAmountApproved">
          {t('claim.amountApproved', { context: 'short' })}:{' '}
          <strong>{formatCurrency(submission.amountRequested)}</strong>
        </p>
        <FormFields title={t('claim.actualEligibleCost')}>
          <ActivityTableFees approvedMode paymentIsCreated={paymentIsCreated} />
        </FormFields>
      </>
    );
  };

  return (
    <>
      {(type === SubmissionType.Cu || type === SubmissionType.If) &&
        renderCuIfSubmission(submission as ICoursesSubmission)}
      {(type === SubmissionType.Pca || type === SubmissionType.Pp) &&
        renderPcaPpSubmission(submission as IActivitySubmission)}
    </>
  );
}

export default RevisionSection;
